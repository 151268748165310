import * as bootstrap from 'bootstrap'
import { cloneNode, cn1, hide, show, uuid } from '../utils'
import { TagCondition, TagGroup} from './ConditionObject'

const TAG_SELECTOR_MODAL_ID = 'select-tag-modal'

/**
 * タグ選択オプション
 */
export interface TagSelectOptions {
    tags: TagGroup
    confirmed?: (tag: TagGroup) => void
    cancelled?: () => void
}

/**
 * タグ条件選択ダイアログ
 */
export class TagSelector {
    private _element: HTMLElement
    private _modal: bootstrap.Modal
    private _body: HTMLElement

    private _rules: TagRule[] = []
    private _options: TagSelectOptions

    private _confirmed: boolean = false

    private _onAddNewRuleBind
    private _onConfirmBind
    private _onModalClosedBind

    constructor(options: TagSelectOptions) {
        this._element = this.createDialogElement()
        this._options = options

        // 現在の選択をダイアログに反映
        if (!options.tags.conditions || options.tags.conditions.length == 0) {
            options.tags.conditions = [{
                match: "and",
                tags: [],
            }]
        }
        this.apply(options.tags)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(this._element)
    }

    show(): void {
        this._modal.show()
    }

    /**
     * タグ設定をダイアログに反映
     */
    private apply(tag: TagGroup): void {
        // 一旦クリア
        this.clear()

        for (const c of tag.conditions) {
            this.applyTag(c)
        }

        // 番号振り直し
        this.renumber()
    }

    private applyTag(tag: TagCondition): void {
        const rule = new TagRule(tag)
        rule.setOnRuleRemoved(() => this.onRuleRemoved(rule))

        this._rules.push(rule)
        this._body.appendChild(rule.getPanel())
    }

    private addNewRule(): void {
        this.applyTag({match: "and", tags: []})
        this.renumber()
    }

    private renumber(): void {
        const removable = this._rules.length > 1
        this._rules.forEach((t, index) => {
            t.setNumber((index + 1).toString())
            t.setRemovable(removable)
        })
    }

    /**
     * ダイアログに反映しているタグ等をクリア
     */
    private clear(): void {
        this._rules = []
        this._body.innerHTML = ''
    }

    private onRuleRemoved(r: TagRule): void {
        this._rules = this._rules.filter(t => t !== r)
        this._body.removeChild(r.getPanel())

        this.renumber()
    }

    /**
     * タグが選択されているかチェック
     * 
     * @returns タグが正しく選択されていればtrue
     */
    private checkTagsSelected(): boolean {
        let checked = true
        for (const t of this._rules) {
            if (!t.checkTagsSelected()) {
                checked = false
            }
        }
        return checked
    }

    private buildTagCondition(): TagGroup {
        return {
            conditions: this._rules.map(t => t.buildConditionObject())
        }
    }

    private onModalConfirm(): void {
        if (!this.checkTagsSelected()) {
            return
        }
        // タグ設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    private onModalClosed(): void {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                // タグも選択されていたので、タグ情報を生成
                const t = this.buildTagCondition()
                this._options.confirmed(t)
            }
        } else {
            // キャンセルしたのでキャンセル時のハンドラを実行
            if (this._options.cancelled) {
                this._options.cancelled()
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    private createDialogElement(): HTMLElement {
        const p = cloneNode(TAG_SELECTOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find tag selector modal'
        }

        // 本体DOM取得
        const body = p.getElementsByClassName('rules-list')
        if (!body || body.length == 0) {
            throw 'Cannot find tag condition body.'
        }
        this._body = body[0] as HTMLElement

        // 条件を追加ボタン
        const addRuleButton = cn1(p, 'add-tag-rule')
        if (!addRuleButton) {
            throw 'Cannot find add tag rule button.'
        }
        this._onAddNewRuleBind = this.addNewRule.bind(this)
        addRuleButton.addEventListener('click', this._onAddNewRuleBind)

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(p, 'btn-tag-select-confirm')?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private dispose() {
        cn1(this._element, 'btn-tag-select-confirm')?.removeEventListener('click', this._onConfirmBind)
        cn1(this._element, 'add-tag-rule')?.removeEventListener('click', this._onAddNewRuleBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}

/**
 * タグ条件1つ
 */
class TagRule {
    private _panel: HTMLElement
    private _id: string

    private _onRuleRemoved: () => void = this.defaultOnRuleRemoved

    constructor(condition: TagCondition) {
        this._id = uuid()
        this._panel = this.createElement(this._id, condition)
    }

    /**
     * 条件の番号を設定(表示用)
     */
    setNumber(n: string): void {
        const s = this._panel.getElementsByClassName('number')
        if (s && s.length > 0) {
            (s[0] as HTMLElement).innerText = n
        }
    }

    /**
     * 削除ボタンの表示を設定
     */
    setRemovable(b: boolean): void {
        const s = this._panel.getElementsByClassName('delete')
        if (s && s.length > 0) {
            if (b) {
                s[0].classList.remove('d-none')
                s[0].removeAttribute('disabled')
            } else {
                s[0].classList.add('d-none')
                s[0].setAttribute('disabled', 'disabled')
            }
        }
    }

    /**
     * タグ条件が削除されたときの処理
     */
    setOnRuleRemoved(f: () => void) {
        this._onRuleRemoved = f
    }

    /**
     * 条件パネルDOMを取得
     */
    getPanel(): HTMLElement {
        return this._panel
    }

    /**
     * 何らかのタグが選択されているかチェック
     */
    checkTagsSelected(): boolean {
        const form = this._panel.getElementsByTagName('form')[0]
        const checks = form.querySelectorAll('div.tag input[type="checkbox"]')
        let checked = false
        for (const c of checks) {
            if ((c as HTMLInputElement).checked) {
                checked = true
                break
            }
        }

        const m = cn1(this._panel, 'alert')
        if (checked) {
            if (m) { hide(m) }
        } else {
            if (m) { show(m) }
        }

        return checked
    }

    /**
     * タグ条件情報生成
     */
    buildConditionObject(): TagCondition {
        // フォームDOM取得
        const form = this._panel.getElementsByTagName('form')[0]
        const id = form.id

        // チェックされたタグIDを保持しておく配列
        const checkedTags: string[] = []
        // チェックボックス取得
        const checks = form.querySelectorAll('div.tag input[type="checkbox"]')
        for (const c of checks) {
            if (c instanceof HTMLInputElement && c.checked) {
                // チェックONなのでIDを保持
                checkedTags.push(c.value)
            }
        }

        // オブジェクトにして返却
        return {
            match: form[`match-${id}`].value,
            tags: checkedTags,
        }
    }

    private onRuleRemove(): void {
        if (confirm('タグ条件を削除しますか？')) {
            this._onRuleRemoved()
        }
    }

    private createElement(id: string, c: TagCondition): HTMLElement {
        const p = cloneNode('tag-rule-template')
        if (!p) {
            throw 'Cannot create tag rule container'
        }

        const form = p.getElementsByTagName('form')[0]
        form.id = id

        // 合致条件のDOM属性設定
        const matchSelect = form.getElementsByTagName('select')[0]
        matchSelect.name = `match-${id}`
        matchSelect.value = c.match

        // タグのDOM属性設定
        const tagsCheck = form.getElementsByClassName('tag')
        for (const r of tagsCheck) {
            const check = r.getElementsByTagName('input')[0]
            const label = r.getElementsByTagName('label')[0]
            check.id = `tag-${id}-${check.value}`
            check.name = `tag-${id}`
            label.htmlFor = check.id
            if (c.tags.find(t => t == check.value)) {
                check.checked = true
            }
        }

        const delBtn = p.getElementsByClassName('delete')
        if (delBtn && delBtn.length > 0) {
            delBtn[0].addEventListener('click', () => this.onRuleRemove())
        }

        show(p)
        return p
    }

    private defaultOnRuleRemoved(): void {
    }
}