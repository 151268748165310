/**
 * メッセージオブジェクト
 */
export interface MessageObject {
    /** ALTテキスト */
    altText: string

    /** バブルコンテナのリスト */
    bubbles: BubbleItem[]
}

/**
 * 吹き出し横並び表示のやつの1つを定義
 */
export interface BubbleItem {
    /** ブロックのリスト */
    blocks: BlockItem[]
}

/**
 * ボタンアクションの種別
 */
export const ACTION_TYPES = {
    NONE: 'NONE',
    LINK: 'LINK',
    FLEX_LINK: 'FLEX_LINK',
    BUILTIN_LINK: 'BUILTIN_LINK',
    POSTBACK: 'POSTBACK',
    PROFILE: 'PROFILE',
    KEYWORD: 'KEYWORD',
    FORM: 'FORM',
} as const

/**
 * ボタンアクションの種別
 */
export type ActionType = typeof ACTION_TYPES[keyof typeof ACTION_TYPES]

/**
 * 文字列からボタンアクションを取得
 */
export function actionTypeOf(s: string): ActionType {
    const x = Object.values(ACTION_TYPES).filter(t => t == s)
    if (x) {
        return x[0]
    } else {
        return ACTION_TYPES.NONE
    }
}

/**
 * 吹き出し内の1ブロック
 */
export interface BlockItem {
    /** ブロックのタイプ */
    type: string

    /** テキストSPANリスト */
    span?: SpanItem[]

    /** ボタンのラベル */
    label?: string

    /** ボタンタップ時のアクション種別 */
    actionType?: ActionType

    /** ボタンのリンクURL */
    linkUrl?: string

    /** LINEリンクの種別 */
    builtinLinkType?: string

    /** 登録済みリンクID */
    flexLinkId?: string

    /** 発言キーワード */
    keyword?: string

    /** アンケートID */
    formId?: string

    /** ポストバックアクションID */
    postbackId?: string

    /** ボタンの背景色 */
    color?: string

    /** 画像のURL */
    imageUrl?: string
}

/**
 * テキストSPAN
 */
export interface SpanItem {
    /** テキスト */
    text: string | null

    /** ID */
    id?: string

    /** テキストサイズ */
    size: string

    /** テキスト文字色(#rrggbb) */
    color: string
}
