import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { RouteCondition } from "./ConditionObject";
import { RouteSelector } from "./RouteSelector";

/**
 * 経路条件設定パネル
 */
export class RouteConditionPanel extends TypedBaseCondition<RouteCondition> {
    private _element: HTMLElement

    private _condition: RouteCondition

    constructor() {
        super()

        this._element = this.createConditionElement()
    }

    /**
     * 経路条件情報を画面に反映
     *
     * @param route 経路条件
     */
    apply(route: RouteCondition): void {
        const x = this._element

        // 経路
        this.applySelectedRoute(route)

        this._body.appendChild(x)
        this._condition = route

        show(x)
    }

    /**
     * 配信グループの経路条件データ作成
     */
    buildConditionJson(): RouteCondition {
        return this._condition
    }

    getName(): string {
        return '登録経路'
    }

    onEdit(): void {
        new RouteSelector(this._condition).show(route => {
            this.apply(route)
            this.raiseOnEditConfirmed()
        })
    }

    /**
     * 登録経路条件設定パネル生成
     */
    private createConditionElement(): HTMLElement {
        const x = cloneNode('condition-route')
        if (!x) {
            throw 'Cannot create route condition element.'
        }

        return x
    }

    /**
     * 選択した経路を反映
     */
    private applySelectedRoute(route: RouteCondition): void {
        const c = cn1(this._element, 'data-route-list')
        if (!c) {
            return
        }

        // 一旦中身をクリア
        c.innerHTML = ''

        for (const t of route.routes) {
            // ダイアログに経路のスタイル等あるので、それをパクる
            const sp = document.querySelector(`span[data-route-id="${t}"]`)?.cloneNode(true) as HTMLElement
            if (!sp) {
                continue
            }

            // 取得できた経路情報を利用
            sp.classList.add('mx-1')
            c.appendChild(sp)
        }
    }
}