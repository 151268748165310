import { Gradient } from "qr-code-styling"
import { BaseQrOption } from "./BaseQrOption"

interface GradientOptionType {
    color?: string
    gradient?: Gradient
}

/**
 * グラデーションオプション
 */
export class ShapeGradient extends BaseQrOption {
    private _style: GradientOptionType

    private _optionPanel: HTMLElement
    private _secondColorInput: HTMLInputElement
    private _previousSelected: HTMLButtonElement

    constructor(panel: HTMLElement, style: GradientOptionType) {
        super()
        this._style = style

        this.setup(panel)
        this._optionPanel = panel
    }

    private applyConfig(btn: HTMLButtonElement): void {
        const v = btn.getAttribute('data-value')
        if (!v) {
            return
        }

        let g: Gradient | undefined
        if (v === 'none') {
            // グラデーションなし
            this.hideGradientColor()
            g = undefined
        } else if (v === 'linear') {
            // 横のグラデーション
            this.showGradientColor()
            g = this._style.gradient ?? this.createGradient()
            g.type = 'linear'
            g.rotation = undefined
        } else if (v === 'linear45') {
            // 斜めなグラデーション
            this.showGradientColor()
            g = this._style.gradient ?? this.createGradient()
            g.type = 'linear'
            g.rotation = Math.PI / 4.0
        } else if (v === 'linear90') {
            // 縦のグラデーション
            this.showGradientColor()
            g = this._style.gradient ?? this.createGradient()
            g.type = 'linear'
            g.rotation = Math.PI / 2.0
        } else if (v === 'round') {
            // 放射状グラデーション
            this.showGradientColor()
            g = this._style.gradient ?? this.createGradient()
            g.type = 'radial'
            g.rotation = undefined
        }
        this._style.gradient = g

        this.raiseOptionUpdated()

        this._previousSelected.classList.remove('selected')
        btn.classList.add('selected')
        this._previousSelected = btn
    }

    /**
     * グラデーション色を適用
     */
    private applyGradientColor(): void {
        if (!this._style.gradient) {
            return
        }

        this._style.gradient.colorStops[1].color = this._secondColorInput.value
        this.raiseOptionUpdated()
    }

    /**
     * グラデーション色の欄を非表示
     */
    private hideGradientColor(): void {
        this._optionPanel.classList.remove('qr-gradient-enable')
    }

    /**
     * グラデーション色の欄を表示
     */
    private showGradientColor(): void {
        this._optionPanel.classList.add('qr-gradient-enable')
    }

    /**
     * デフォルトなグラデーションデータを作成
     */
    private createGradient(): Gradient {
        return {
            type: 'linear',
            colorStops: [{
                offset: 0,
                color: this._style.color ?? '#000'
            }, {
                offset: 1,
                color: this._secondColorInput.value
            }]
        }
    }

    private setup(dialog: HTMLElement): void {
        const buttons = dialog.querySelectorAll(`button.qr-gradient`)
        let firstButton: HTMLButtonElement | null = null
        for (let n = 0; n < buttons.length; n++) {
            const b = buttons[n] as HTMLButtonElement
            if (!b) {
                continue
            }
            if (!firstButton) {
                firstButton = b
            }
            b.addEventListener('click', () => this.applyConfig(b))
        }

        if (firstButton) {
            this._previousSelected = firstButton
            firstButton.classList.add('selected')
        }

        // グラデーション色選択用のINPUT
        this._secondColorInput = dialog.querySelector('input.qr-gradient-color') as HTMLInputElement
        this._secondColorInput?.addEventListener('change', () => this.applyGradientColor())
    }
}