import QRCodeStyling, { Options } from "qr-code-styling";

function loadDataUrlAsync(qr: Blob, resolve: (value: string) => void): void {
    // FileReaderでDataURLへ変換する
    const reader = new FileReader()
    reader.addEventListener('load', (ev: ProgressEvent<FileReader>) => {
        if (ev.target) {
            resolve(ev.target.result?.toString() ?? '')
        }
    })
    reader.readAsDataURL(qr)
}

export function createDefaultQrOptions(): Options {
    return {
        width: 300,
        height: 300,
        dotsOptions: {
            type: 'square',
            color: '#000000',
            roundSize: true,
        },
        qrOptions: {
            errorCorrectionLevel: "M",
        },
        imageOptions: {
        },
        backgroundOptions: {
            color: '#ffffff',
        },
        cornersSquareOptions: {
            type: undefined,
            color: '#000000',
        },
        cornersDotOptions: {
            type: undefined,
            color: '#000000',
        },
        margin: 10,
    }
}

/**
 * QRコード画像生成
 * 
 * @param data QRコードに反映するデータ
 * @param option QRコードスタイルオプション
 * @returns QRコード画像DataURL
 */
export async function generateQr(data: string, option?: Options): Promise<string> {
    // オプション反映
    const optionSource = option ?? createDefaultQrOptions()
    const opt = {...optionSource}
    opt.data = data
    // QRコードデータ生成
    const qr = await new QRCodeStyling(opt).getRawData("png")

    return new Promise<string>((resolve, rej) => {
        if (qr instanceof Blob) {
            // データURLへ変換
            loadDataUrlAsync(qr, resolve)
        } else {
            // 未対応形式として空欄を送る
            resolve('')
        }
    })
}

/**
 * IMGタグに設定されている'data-url'属性からQRコード画像を生成
 */
export function generateQrFromImg(): void {
    document.querySelectorAll('img.generate-qr[data-url]').forEach(async elem => {
        const data = elem.getAttribute('data-url')
        if (data && data.length > 0 && elem instanceof HTMLImageElement) {
            elem.src = await generateQr(data)
        }
    })
}