import { DateInput } from "./date"
import { TimeInput } from "./time"

function startTimeOfDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0)
}

function endTimeOfDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59)
}

function endOfMonth(date: Date): Date {
    const m = new Date(date)
    m.setDate(1)
    m.setMonth(m.getMonth() + 1)
    m.setDate(m.getDate() - 1)
    return m
}

class DateTimeFromToForm {
    private _dateFrom: DateInput
    private _timeFrom?: TimeInput
    private _dateTo: DateInput
    private _timeTo?: TimeInput


    constructor(container: HTMLElement) {
        this.setup(container)
    }

    private onDaysLinkClicked(a: HTMLElement): void {
        const daysType = a.getAttribute('data-days')
        const now = new Date()
        if (daysType === 'today') {
            this.setFromTo(startTimeOfDate(now), endTimeOfDate(now))
        } else if (daysType === 'yesterday') {
            now.setDate(now.getDate() - 1)
            this.setFromTo(startTimeOfDate(now), endTimeOfDate(now))
        } else if (daysType === 'thismonth') {
            now.setDate(1)
            this.setFromTo(startTimeOfDate(now), endTimeOfDate(endOfMonth(now)))
        } else if (daysType === 'lastmonth') {
            now.setDate(1)
            now.setMonth(now.getMonth() - 1)
            this.setFromTo(startTimeOfDate(now), endTimeOfDate(endOfMonth(now)))
        } else if (daysType === 'last10') {
            const before = new Date(now)
            before.setDate(before.getDate() - 10)
            this.setFromTo(startTimeOfDate(before), endTimeOfDate(now))
        } else if (daysType === 'last30') {
            const before = new Date(now)
            before.setDate(before.getDate() - 30)
            this.setFromTo(startTimeOfDate(before), endTimeOfDate(now))
        } else if (daysType === 'last90') {
            const before = new Date(now)
            before.setDate(before.getDate() - 90)
            this.setFromTo(startTimeOfDate(before), endTimeOfDate(now))
        } else if (daysType === 'last180') {
            const before = new Date(now)
            before.setDate(before.getDate() - 180)
            this.setFromTo(startTimeOfDate(before), endTimeOfDate(now))
        } else if (daysType === 'clear') {
            this.clear()
        }
    }

    private setFromTo(from: Date, to: Date): void {
        this._dateFrom.setDate(from)
        this._dateTo.setDate(to)
    }

    private clear(): void {
        this._dateFrom.clear()
        this._timeFrom?.clear()
        this._dateTo.clear()
        this._timeTo?.clear()
    }

    private setup(container: HTMLElement): void {
        if (!this.setupElements(container)) {
            return
        }

        this.setupLinks(container)
    }

    private setupElements(container: HTMLElement): boolean {
        const dateFrom = container.querySelector('input.date-from') as HTMLInputElement
        if (!dateFrom) {
            return false
        }
        const dateTo = container.querySelector('input.date-to') as HTMLInputElement
        if (!dateTo) {
            return false
        }

        const timeFrom = container.querySelector('input.time-from') as HTMLInputElement
        const timeTo = container.querySelector('input.time-to') as HTMLInputElement

        this._dateFrom = new DateInput(dateFrom)
        this._dateTo = new DateInput(dateTo)
        if (timeFrom) {
            this._timeFrom = new TimeInput(timeFrom)
        }
        if (timeTo) {
            this._timeTo = new TimeInput(timeTo)
        }

        return true
    }

    private setupLinks(container: HTMLElement): void {
        container.querySelectorAll('a.days-link').forEach(b => {
            if (b instanceof HTMLElement) {
                b.addEventListener('click', () => this.onDaysLinkClicked(b))
            }
        })
    }
}

export function setupDateTimeFromToForm(): void {
    document.querySelectorAll('.date-time-from-to').forEach(t => {
        if (t instanceof HTMLElement) {
            new DateTimeFromToForm(t)
        }
    })
}