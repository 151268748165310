import * as bootstrap from 'bootstrap'
import { cn1, ge } from '../utils'
import { TagItem } from '../postbackActionEditor/postbackObjects'

/** タグ選択ダイアログのID */
const TAG_SELECTOR_MODAL_ID = 'assignTagsModal'

/**
 * タグ選択ダイアログ
 */
export class TagSelector {
    private _modal: bootstrap.Modal

    /**
     * タグ選択ダイアログインスタンス生成
     * 
     * @param tags 選択済みのタグ情報
     * @param message ダイアログに表示するメッセージ
     */
    constructor(tags: string[], message?: string) {
        if (!TagSelector.editorElementInitialized) {
            TagSelector.editorElementInitialized = true
            TagSelector.setupTagSelector()
        }

        // 現在の選択をダイアログに反映
        this.applySelectedTags(tags)

        if (message) {
            // メッセージが指定されていれば、メッセージも表示
            this.showMessage(message)
        } else {
            // メッセージは消しておく
            this.hideMessage()
        }

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(TagSelector.editorElement)

        // ダイアログが閉じられたら破棄するように
        TagSelector.editorClosedHandler = () => {
            this._modal.dispose()
        }
    }

    /**
     * タグ選択ダイアログを表示
     * 
     * @param confirmed 画像選択を確定したときの処理
     */
    show(confirmed: (selectedTags: TagItem[]) => void): void {
        // ダイアログを決定した際の処理を登録しておく
        TagSelector.editorConfirmedHandler = () => {
            // 選択したタグ情報を集める
            const tags = this.collectSelectedTags()
            // ダイアログは閉じておく
            this._modal.hide()
            // 決定イベントハンドラをコール
            confirmed(tags)
        }
        // ダイアログ表示
        this._modal.show()
    }

    /**
     * 画面に選択済みタグを反映
     * 
     * @param tags 設定するタグIDのリスト
     */
    private applySelectedTags(tags: string[]): void {
        // 選択を一旦クリア
        this.clearSelectedTags()

        tags.forEach(t => {
            const e = document.getElementById(t)
            if (e instanceof HTMLInputElement) {
                e.checked = true
            }
        })
    }

    /**
     * 選択済みタグをクリア
     */
    private clearSelectedTags(): void {
        const checks = TagSelector.editorElement.getElementsByClassName('form-check-input')
        for (const c of checks) {
            if (c instanceof HTMLInputElement) {
                c.checked = false
            }
        }
    }

    /**
     * メッセージ表示
     * 
     * @param message メッセージ
     */
    private showMessage(message: string): void {
        const span = cn1(TagSelector.editorElement, 'tags-warning-message')
        if (span) {
            span.innerText = message
            span.parentElement?.classList.remove('d-none')
        }
    }

    /**
     * メッセージパネルを非表示に
     */
    private hideMessage(): void {
        const span = cn1(TagSelector.editorElement, 'tags-warning-message')
        if (span) {
            span.innerText = ''
            span.parentElement?.classList.add('d-none')
        }
    }

    /**
     * 選択したタグの情報を収集
     */
    private collectSelectedTags(): TagItem[] {
        const selectedTags: TagItem[] = []
        const checks = TagSelector.editorElement.getElementsByClassName('form-check-input')
        for (const c of checks) {
            if ((c instanceof HTMLInputElement) && c.checked) {
                const span = ge('span-' + c.id)
                const tagInfo: TagItem = {
                    id: c.value,
                    name: span?.innerText ?? "'",
                    style: span?.getAttribute('style') ?? ''
                }
                selectedTags.push(tagInfo)
            }
        }
        return selectedTags
    }

    /**
     * タグ選択ダイアログ初期化
     */
    private static setupTagSelector(): void {
        // 決定ボタンが押されたときの処理
        cn1(this.editorElement, 'btn-assign-tags-confirm')?.addEventListener('click', () => {
            if (this.editorConfirmedHandler) {
                this.editorConfirmedHandler()
                this.editorConfirmedHandler = undefined
            }
        })
        // ダイアログが閉じられたときの処理
        this.editorElement.addEventListener('hidden.bs.modal', () => {
            if (this.editorClosedHandler) {
                this.editorClosedHandler()
                this.editorClosedHandler = undefined
            }
        })
    }

    private static editorConfirmedHandler?: () => void

    private static editorClosedHandler?: () => void

    /** タグ編集ダイアログDOM */
    private static editorElement: HTMLElement = document.getElementById(TAG_SELECTOR_MODAL_ID)!!

    /** タグ選択ダイアログ初期化済みか */
    private static editorElementInitialized: boolean = false
}