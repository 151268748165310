import { Modal } from 'bootstrap'
import { cn1 } from '../utils'
import { gpropInput } from './misc'
import { ColorPatternInfo, ColorSelector } from './colors'

/**
 * 文字列SPAN編集時の編集データ
 */
export interface TextEditorInfo {
    /** テキスト */
    text: string
    /** 文字サイズ */
    size: string
    /** 文字色 */
    color: string
    /** SPAN要素のID */
    spanId: string
}

/**
 * テキスト編集時オプション
 */
export interface TextEditOptions {
    /** 文字列編集データ */
    editorInfo: TextEditorInfo
    /** 文字色選択肢リスト */
    colorPatterns?: ColorPatternInfo[]
    /** 確定時イベントハンドラ */
    confirmed?: (info: TextEditorInfo) => void
    /** キャンセル時イベントハンドラ */
    cancelled?: () => void
}

/** テキスト編集ダイアログのID */
const TEXT_EDITOR_MODAL_ID = 'text-edit-modal'

/** 色選択のデフォルト */
const DEFAULT_COLOR_PATTERNS = '[{"color":"#000000","text":"黒"},{"color":"#0000ff","text":"青"},{"color":"#ff0000","text":"赤"},{"color":"#008000","text":"緑"},{"color":"#ffff00","text":"黄"},{"color":"","text":"","isManual":true}]'

/**
 * テキスト編集ダイアログ
 */
export class TextEditor {
    private _element: HTMLElement
    private _modal: Modal
    private _colorSelector: ColorSelector

    private _options: TextEditOptions

    private _confirmed: boolean = false

    private _onConfirmBind
    private _onModalClosedBind

    constructor(options: TextEditOptions) {
        this._element = this.createDialogElement()
        this._options = options

        // 色選択パネルの色リスを設定
        const colorSelectorContainer = cn1(this._element, 'color-selector-container')
        if (colorSelectorContainer) {
            this._colorSelector = new ColorSelector(colorSelectorContainer)
            if (options.colorPatterns && options.colorPatterns.length > 0) {
                // 色の選択肢が定義されているので、それに従って反映
                this._colorSelector.apply(options.colorPatterns)
            } else {
                // 指定なし、デフォルトを設定する
                this._colorSelector.apply(JSON.parse(DEFAULT_COLOR_PATTERNS))
            }
        }

        // 現在の選択をダイアログに反映
        this.apply(options.editorInfo)

        // bootstrapモーダル生成
        this._modal = new Modal(this._element)
    }

    /**
     * テキスト編集ダイアログを表示
     */
    show(): void {
        this._modal.show()
    }

    /**
     * テキスト設定内容をダイアログに反映
     */
    private apply(info: TextEditorInfo): void {
        gpropInput(this._element, 'editTextSpan').value = info.spanId
        gpropInput(this._element, 'text').value = info.text
        this._colorSelector.select(info.color)
        gpropInput(this._element, 'size').value = info.size
    }

    /**
     * ダイアログで設定した内容をオブジェクトにして生成
     */
    private buildTextInfo(): TextEditorInfo {
        return {
            spanId: gpropInput(this._element, 'editTextSpan').value,
            text: gpropInput(this._element, 'text').value,
            color: this._colorSelector.getColor(),
            size: gpropInput(this._element, 'size').value,
        }
    }

    private onModalConfirm(): void {
        // 登録日条件設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    private onModalClosed(): void {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                // テキストデータを作成
                const info = this.buildTextInfo()
                this._options.confirmed(info)
            }
        } else {
            // キャンセルしたのでキャンセル時のハンドラを実行
            if (this._options.cancelled) {
                this._options.cancelled()
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    private createDialogElement(): HTMLElement {
        const p = document.getElementById(TEXT_EDITOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find message text editor modal'
        }

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(p, 'confirm-edit-text')?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private dispose() {
        cn1(this._element, 'confirm-edit-text')?.removeEventListener('click', this._onConfirmBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}