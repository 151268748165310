import { cloneNode, cn1, ge, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { GenderCondition, GenderGroup } from "./ConditionObject";
import { GenderSelectOptions, GenderSelector } from "./GenderSelector";

const PANEL_GENDER_TEMPLATE_ID = 'gender-condition-template'
const PANEL_GENDER_ITEM_TEMPLATE_ID = 'gender-item-template'

/**
 * 性別条件表示パネル
 */
export class GenderConditionPanel extends TypedBaseCondition<GenderGroup> {
    private _gendersList: HTMLElement

    constructor(readOnly: boolean) {
        super(readOnly)

        const p = this.createConditionsPanel()
        this._body.appendChild(p)

        const gendersList = cn1(p, 'condition-genders')
        if (gendersList) {
            this._gendersList = gendersList
        }

        show(p)
    }

    /**
     * 性別条件を画面に反映
     */
    apply(condition: GenderGroup): void {
        this._condition = condition

        // 一旦クリア
        this._gendersList.innerHTML = ''

        // 各条件を反映
        condition.conditions.forEach(g => this.applyGender(g))
    }

    /**
     * 条件タイプ
     */
    getType(): string {
        return "gender"
    }

    /**
     * 名称取得
     */
    getName(): string {
        return "性別"
    }

    /**
     * 設定ダイアログ表示
     */
    onEdit(): void {
        const opts: GenderSelectOptions = {
            genders: this._condition,
            confirmed: (r) => {
                this.apply(r)
                this.raiseOnEditConfirmed()
            },
            cancelled: () => {}
        }
        new GenderSelector(opts).show()
    }

    private applyGender(condition: GenderCondition): void {
        const p = this.createConditionItemPanel()

        const selectedGenders: string[] = []
        for (const g of condition.genders) {
            const span = document.getElementById(g)
            if (span) {
                selectedGenders.push(span.innerText)
            }
        }

        this._gendersList.innerText = selectedGenders.join(", ")

        show(p)
    }

    private createConditionsPanel(): HTMLElement {
        const p = cloneNode(PANEL_GENDER_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find genders viewer panel.'
        }

        return p
    }

    private createConditionItemPanel(): HTMLElement {
        const p = cloneNode(PANEL_GENDER_ITEM_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find gender condition viewer panel.'
        }

        return p
    }
}