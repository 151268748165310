import { cn1 } from "../utils";
import { BaseAction } from "./baseAction";
import { PostbackObject } from "./postbackObjects";

/** リッチメニュー切り替えパネルのクラス名 */
const INPUT_SWITCH_MENU_CLASSNAME = 'postback-action-switch-menu'

export class SwitchRichMenu extends BaseAction {
    private _selector: HTMLSelectElement

    constructor(parent: HTMLElement) {
        super(parent, INPUT_SWITCH_MENU_CLASSNAME, 'SWITCH_RICH_MENU')

        const s = cn1(this._container, 'postback-menu-select') as HTMLSelectElement
        if (!s) {
            throw 'Cannot find RichMenu selector.'
        }
        this._selector = s
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: PostbackObject): void {
        this._selector.value = action.menuId ?? ''
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): PostbackObject | null {
        const menuId = this._selector.value
        if (!menuId) {
            this._selector.classList.add('is-invalid')
            return null
        }

        this._selector.classList.remove('is-invalid')

        return {
            type: this._type,
            menuId: menuId,
        }
    }
}