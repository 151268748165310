import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { TagCondition, TagGroup } from "./ConditionObject";
import { TagSelectOptions, TagSelector } from "./TagSelector";

/** タグ条件表示パネルのテンプレートのID */
const PANEL_TAGS_TEMPLATE_ID = 'tags-condition-template'
/** タグ条件を表示するパネルのテンプレートID */
const PANEL_TAG_COND_TEMPLATE_ID = 'tag-item-template'

/**
 * タグ条件パネル
 */
export class TagConditionPanel extends TypedBaseCondition<TagGroup> {
    private _listGroup: HTMLUListElement

    constructor(readOnly: boolean) {
        super(readOnly)

        const p = this.createTagsPanel()
        this._body.appendChild(p)

        this._listGroup = p.getElementsByTagName('ul')[0]

        show(p)
    }

    /**
     * タグ条件の内容を画面に反映
     * 
     * @param tag タグ条件
     */
    apply(tag: TagGroup): void {
        this._condition = tag

        // 一旦クリア
        this._listGroup.innerHTML = ''

        // 各条件を反映
        tag.conditions.forEach(t => this.applyTag(t))
    }

    /**
     * 条件タイプ
     */
    getType(): string {
        return "tag"
    }

    /**
     * 名称取得
     */
    getName(): string {
        return "タグ"
    }

    onEdit(): void {
        const opts: TagSelectOptions = {
            tags: this._condition,
            confirmed: (tag) => {
                this.apply(tag)
                this.raiseOnEditConfirmed()
            },
            cancelled: () => {}
        }
        new TagSelector(opts).show()
    }

    private applyTag(c: TagCondition): void {
        const li = this.createTagItemPanel()

        const matchDiv = cn1(li, 'condition-text')
        if (matchDiv) {
            if (c.match == "and") {
                matchDiv.innerText = '次のすべてがタグ付け'
            } else if (c.match == "or") {
                matchDiv.innerText = '次のいずれかがタグ付け'
            } else if (c.match == "nand") {
                matchDiv.innerText = '次のすべてに合致したら除外'
            } else if (c.match == "nor") {
                matchDiv.innerText = '次のいずれかに合致したら除外'
            }
            li.appendChild(matchDiv)
        }

        const tagsDiv = cn1(li, 'condition-tags')
        if (tagsDiv) {
            for (const t of c.tags) {
                const div = document.createElement('div')
                div.classList.add('w-auto')
                const tagSpan = cloneNode(t)
                if (tagSpan) {
                    div.appendChild(tagSpan)
                }
                tagsDiv.appendChild(div)
            }
            li.appendChild(tagsDiv)
        }

        show(li)
        this._listGroup.appendChild(li)
    }

    private createTagsPanel(): HTMLElement {
        const p = cloneNode(PANEL_TAGS_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find tags viewer panel.'
        }

        return p
    }

    private createTagItemPanel(): HTMLElement {
        const p = cloneNode(PANEL_TAG_COND_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find tag condition viewer panel.'
        }

        return p
    }
}