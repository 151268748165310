import { show, hide, cn1 } from '../utils'
import { PostbackActionType, PostbackObject } from './postbackObjects'

/**
 * ポストバックアクション - アクション設定ベース
 */
export abstract class BaseAction {
    protected _container: HTMLElement
    protected _type: PostbackActionType

    /**
     * コンストラクタ
     * 
     * @param parent アクション定義DOMを格納する親要素のDOM
     * @param panelClassName 設定パネルのクラス名
     * @param type アクションタイプ文字列
     * @returns 
     */
    constructor(parent: HTMLElement, panelClassName: string, type: PostbackActionType) {
        // アクション設定パネル
        const c = cn1(parent, panelClassName)
        if (!c) {
            return
        }

        this._type = type
        this._container = c
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    abstract apply(action: PostbackObject): void

    /**
     * アクション定義オブジェクトを作成
     */
    abstract buildActionJson(): PostbackObject | null

    /**
     * クラスのアクション種別を引数のアクション種別を比較して、同じなら表示、違ったら非表示に
     * 
     * @param checkType 選択した種別
     */
    showIfTypeMatched(checkType: string): void { 
        if (this._type == checkType) {
            show(this._container)
        } else {
            hide(this._container)
        }
    }
}