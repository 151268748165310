import * as utils from './utils'
import { ConditionEditor } from './conditionEditor'
import { FormEditor } from './formEditor'
import { ImageSelector } from './imageSelector'
import { MessageEditor } from './messageEditor'
import { PostbackActionEditor } from './postbackActionEditor'
import { RichMenuEditor } from './richMenuEditor'
import { Login } from './login'

import bootstrap from 'bootstrap'

export const windowOpen = utils.windowOpen
export const addOnLoad = utils.addOnLoad
export const ge = utils.ge
export const clipboardCopy = utils.clipboardCopy
export const loadingButton = utils.loadingButton
export const showProgressRing = utils.showProgressRing
export const hideProgressRing = utils.hideProgressRing

export {
    ConditionEditor,
    FormEditor,
    ImageSelector,
    MessageEditor,
    PostbackActionEditor,
    RichMenuEditor,
    Login,
}

function enablePopover() {
    const p = document.querySelectorAll('[data-bs-toggle="popover"]')
    if (p) {
        [...p].map(el => new bootstrap.Popover(el))
    }
}

function setupLoadingButton() {
    document.querySelectorAll('.loading').forEach(p => {
        if (p instanceof HTMLButtonElement) {
            p.addEventListener('click', () => loadingButton(p))
        }
    })
}

function setup() {
    enablePopover()
    setupLoadingButton()
    utils.disableFormEnter()
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => setup())
} else {
    setup()
}
