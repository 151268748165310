import { Options } from "qr-code-styling"
import { BaseQrOption } from "./BaseQrOption"

/**
 * アイコンオプション
 */
export class IconOptions extends BaseQrOption {
    private _style: Options

    private _previousIconSelected: HTMLButtonElement

    constructor(style: Options) {
        super()
        this._style = style

        const panel = document.getElementById('qr-option-icon') as HTMLElement
        this.setup(panel)

        // デフォルト選択を反映しておく
        const v = this._previousIconSelected.getAttribute('data-value')
        if (v && v.length > 0) {
            if (v === 'none') {
                this._style.image = undefined
            } else {
                this._style.image = v
            }
        }
    }


    private applyIcon(btn: HTMLButtonElement): void {
        const v = btn.getAttribute('data-value')
        if (!v) {
            return
        }

        if (v === 'none') {
            this._style.image = undefined
        } else {
            this._style.image = v
        }

        this.raiseOptionUpdated()

        this._previousIconSelected.classList.remove('selected')
        btn.classList.add('selected')
        this._previousIconSelected = btn
    }

    private setup(panel: HTMLElement): void {
        const buttons = panel.querySelectorAll('button.qr-icon')
        for (const b of buttons) {
            if (!(b instanceof HTMLButtonElement)) {
                continue
            }

            if (b.classList.contains('account-icon')) {
                // LINE公式アカウントのアイコンの場合は、それをデフォルト選択にする
                this._previousIconSelected = b
            }
            b.addEventListener('click', () => this.applyIcon(b))
        }

        if (!this._previousIconSelected) {
            // LINE公式アカウントアイコンが無かったので、ひとまず選択肢の一番最初を選んでおく
            this._previousIconSelected = buttons[0] as HTMLButtonElement
        }
        this._previousIconSelected.classList.add('selected')
    }
}