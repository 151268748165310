import { cloneNode, show } from "../utils";
import { ButtonEditor, ButtonEditorInfo } from "./ButtonEditor";
import { ACTION_TYPES, BlockItem } from "./messageObject";

/** ボタンDOMテンプレートのID */
const BUTTON_TEMPLATE_ID = 'button-template'

const defaultButtonInfo: ButtonEditorInfo = {
    label: '確認',
    actionType: ACTION_TYPES.NONE,
    color: '#00b73f',
    builtinLinkType: 'NONE',
    flexLinkId: '',
    formId: '',
    keyword: '',
    linkUrl: '',
    postbackId: '',
}

/**
 * ボタン
 */
export class Button {
    private _parentElement: HTMLElement

    private _buttonElement?: HTMLButtonElement

    private _onButtonChanged: () => void

    private _buttonInfo: ButtonEditorInfo

    constructor(parentBlockElement: HTMLElement) {
        // ボタン格納用の親ブロックDOMを保持
        this._parentElement = parentBlockElement

        // 初期用ボタンデータを生成
        this._buttonInfo = {
            label: defaultButtonInfo.label,
            actionType: defaultButtonInfo.actionType,
            color: defaultButtonInfo.color,
            builtinLinkType: defaultButtonInfo.builtinLinkType,
            flexLinkId: defaultButtonInfo.flexLinkId,
            formId: defaultButtonInfo.formId,
            keyword: defaultButtonInfo.keyword,
            linkUrl: defaultButtonInfo.linkUrl,
            postbackId: defaultButtonInfo.postbackId,
        }
    }

    /**
     * ボタンとしてブロックデータを設定
     * 
     * @param data ブロックデータ
     */
    apply(data: BlockItem): void {
        // ボタンデータを作成
        this._buttonInfo = {
            label: data.label ?? defaultButtonInfo.label,
            actionType: data.actionType ?? defaultButtonInfo.actionType,
            color: data.color ?? defaultButtonInfo.color,
            builtinLinkType: data.builtinLinkType ?? defaultButtonInfo.builtinLinkType,
            flexLinkId: data.flexLinkId ?? defaultButtonInfo.flexLinkId,
            formId: data.formId ?? defaultButtonInfo.formId,
            keyword: data.keyword ?? defaultButtonInfo.keyword,
            linkUrl: data.linkUrl ?? defaultButtonInfo.linkUrl,
            postbackId: data.postbackId ?? defaultButtonInfo.postbackId,
        }
        // 画面に反映
        this.applyButtonInfo(this._buttonInfo)
    }

    /**
     * 新しいボタンを追加
     */
    addNewButton(): void {
        this.showButtonEditorInternal(this._buttonInfo)
    }

    /**
     * ボタンが変更されたときの処理を登録
     */
    setOnButtonChanged(f: () => void): void {
        this._onButtonChanged = f
    }

    /**
     * ボタンデータをJSONデータに変換
     */
    buildFlexObject(): BlockItem {
        const obj: BlockItem = {
            type: 'button',
            label: this._buttonInfo.label,
            color: this._buttonInfo.color,
            actionType: this._buttonInfo.actionType,
        }
        if (this._buttonInfo.actionType === ACTION_TYPES.LINK) {
            obj.linkUrl = this._buttonInfo.linkUrl
        } else if (this._buttonInfo.actionType === ACTION_TYPES.BUILTIN_LINK) {
            obj.builtinLinkType = this._buttonInfo.builtinLinkType
        } else if (this._buttonInfo.actionType === ACTION_TYPES.FLEX_LINK) {
            obj.flexLinkId = this._buttonInfo.flexLinkId
        } else if (this._buttonInfo.actionType === ACTION_TYPES.POSTBACK) {
            obj.postbackId = this._buttonInfo.postbackId
        } else if (this._buttonInfo.actionType === ACTION_TYPES.KEYWORD) {
            obj.keyword = this._buttonInfo.keyword
        } else if (this._buttonInfo.actionType === ACTION_TYPES.FORM) {
            obj.formId = this._buttonInfo.formId
        }
        return obj
    }

    /**
     * ボタン編集ダイアログを表示
     * 
     * @param info ボタン編集情報
     */
    private showButtonEditorInternal(info: ButtonEditorInfo): void {
        const editor = new ButtonEditor(info)
        editor.show(confirmedInfo => this.onButtonEditorConfirmed(confirmedInfo))
    }

    /**
     * ボタン編集ダイアログで決定されたときの処理
     */
    private onButtonEditorConfirmed(info: ButtonEditorInfo): void {
        // 編集内容を保持
        this._buttonInfo = info

        // 編集内容をボタンに反映
        this.applyButtonInfo(info)

        // ボタンの変更を通知
        this._onButtonChanged()
    }

    /**
     * ボタンデータを画面に反映
     */
    private applyButtonInfo(info: ButtonEditorInfo): void {
        if (!this._buttonElement) {
            // ボタンがまだ未作成なので、作成する
            this.createButtonElement()
        }

        const btn = this._buttonElement
        if (!btn) {
            return
        }

        // 編集内容をボタンに反映
        btn.innerText = info.label
        btn.style.backgroundColor = info.color
    }

    /**
     * ボタンDOMを作成
     */
    private createButtonElement(): void {
        const t = cloneNode(BUTTON_TEMPLATE_ID)
        if (!t) {
            throw 'Cannot create button element.'
        }

        // ボタンがクリックされたときの処理を登録
        t.addEventListener('click', () => this.showButtonEditorInternal(this._buttonInfo))

        // ボタン要素を保持
        this._buttonElement = t as HTMLButtonElement
        // 親ブロックに追加
        this._parentElement.appendChild(this._buttonElement)

        // ボタン表示
        show(this._buttonElement)
    }
}