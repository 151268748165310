import liff from '@line/liff'
import { ProfileAnswer, ProfileDataItem } from './ProfileObject'
import { hide, loadingButton, show } from '../utils'
import * as pp from '../privacyPolicy'

export class InputProfile {
    /**
     * プロフィール入力画面LIFFアプリメイン
     */
    static liffApp(): void {
        const meta = document.querySelector('meta[name="liff"]') as HTMLMetaElement
        if (!meta) {
            return
        }
        const t = this
        liff.init({liffId: meta.content})
            .then(() => {
                if (liff.isLoggedIn()) {
                    t.init()
                } else {
                    liff.login()
                }
            })
    }

    /**
     * 入力画面メイン(アカウント管理者が入力する画面)
     */
    static app(profiles: ProfileAnswer[], form: HTMLFormElement): void {
        this.apply(profiles, form)
    }

    /**
     * LIFFアプリ初期化
     */
    private static init(): void {
        // プロフィールフォーム作成用DOMコンテナ取得
        const form = document.getElementById('profile-app') as HTMLFormElement
        if (!form) {
            return
        }
        // プロフィールデータ取得用URL取得
        const getProfileData = document.querySelector('meta[name="getUserProfile"]') as HTMLMetaElement
        if (!getProfileData) {
            return
        }
        const url = getProfileData.content
        const fd = new FormData()
        const t = this
        if (liff.id) {
            fd.append('liffId', liff.id)
        }
        const token = liff.getIDToken()
        if (token) {
            fd.append('token', token)
        }
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: fd,
        })
            .then(r => r.ok ? r.json() : null)
            .then(r => t.apply(r, form))
            .catch(ex => t.initFailed(ex))
    }

    /**
     * LIFFアプリ初期化失敗
     */
    private static initFailed(ex: Error): void {
        const f = document.getElementById('profile-form')
        if (f) {
            f.classList.add('d-none')
        }

        console.dir(ex)
        this.showError('アンケートデータの取得に失敗しました。')
    }

    /**
     * エラーメッセージ表示
     * 
     * @param msg メッセージ
     */
    private static showError(msg: string): void {
        const e = document.getElementById('profile-err')
        if (e) {
            e.innerText = msg
            e.parentElement?.classList.remove('d-none')
        }
    }

    /**
     * エラーメッセージを消去
     */
    private static hideError(): void {
        const e = document.getElementById('form-err')
        if (e) {
            e.innerText = ''
        }
        e?.parentElement?.classList.add('d-none')
    }

    /**
     * 取得したアンケートデータを画面に反映
     * 
     * @param profiles プロフィールデータ
     * @param form 反映先のフォームDOM
     */
    private static apply(profiles: ProfileAnswer[], form: HTMLFormElement): void {
        // プロフィールデータを反映
        this.applyProfileData(profiles, form)

        // プライバシーポリシー処理
        pp.setupPrivacyPolicy({
            inputFormId: 'profile-form',
            backToFormId: 'back-to-profile-form'
        })

        // 登録ボタン
        const btn = document.querySelector('button[data-submit="profile-edit"]') as HTMLButtonElement
        if (btn) {
            btn.addEventListener('click', () => this.beforeSubmit(btn))
        }

        // プロフィールを表示
        form.parentElement?.classList.remove('d-none')
    }

    /**
     * アンケートデータを画面に反映
     * 
     * @param profiles プロフィールデータ
     * @param form 反映先のフォームDOM
     */
    private static applyProfileData(profiles: ProfileAnswer[], form: HTMLFormElement): void {
        profiles.forEach(p => {
            const input = this.createInput(p)
            if (input) {
                form.appendChild(input)
            }
        })
    }

    /**
     * プライバシーポリシー処理のセットアップ
     */
    private static setupPrivacyPolicy(): void {
        // 「個人情報の取り扱いについて」リンクを取得
        const showPolicyLink = document.getElementById('show-privacy-policy')
        if (!showPolicyLink) {
            return
        }
        showPolicyLink.addEventListener('click', evt => {
            evt.preventDefault()
            hide(document.getElementById('profile-form'))
            show(document.getElementById('privacy-policy'))
        })

        // プロフィール登録へ戻るリンクを取得
        const backProfileLink = document.getElementById('back-to-profile-form')
        if (backProfileLink) {
            backProfileLink.addEventListener('click', evt =>{
                evt.preventDefault()
                hide(document.getElementById('privacy-policy'))
                show(document.getElementById('profile-form'))
            })
        }

        // プライバシーポリシーのリンクを取得
        const jumpAlphaPPLink = document.getElementById('jump-to-pp-link')
        if (jumpAlphaPPLink) {
            jumpAlphaPPLink.addEventListener('click', evt => {
                evt.preventDefault()
                const a = evt.target
                if (a instanceof HTMLAnchorElement) {
                    liff.openWindow({ url: a.href })
                }
            })
        }
    }

    /**
     * プロフィール入力用DOMを生成
     */
    private static createInput(answer: ProfileAnswer): HTMLElement | null {
        const input = this.createInputInternal(answer)
        if (!input) {
            return null
        }

        // INPUTを格納するコンテナを生成
        const inputContainer = document.createElement('div')
        inputContainer.classList.add('col-12')
        inputContainer.appendChild(input)

        // ラベル、上記INPUTコンテナを格納
        const container = this.createContainer(answer)
        container.appendChild(this.createLabel(answer))
        container.appendChild(inputContainer)

        if (answer.required) {
            container.setAttribute('data-profile-required', 'true')
        }

        return container
    }

    /**
     * 設問欄の生成
     */
    private static createInputInternal(answer: ProfileAnswer): HTMLElement | null {
        if (answer.fieldType === 'TEXT') {
            return this.createTextInput(answer)
        } else if (answer.fieldType === 'MAIL') {
            return this.createMailInput(answer)
        } else if (answer.fieldType === 'TEL') {
            return this.createTelInput(answer)
        } else if (answer.fieldType === 'ZIP') {
            return this.createZipInput(answer)
        } else if (answer.fieldType === 'DATE') {
            return this.createDateInput(answer)
        } else if (answer.fieldType === 'DROPDOWN') {
            return this.createDropDownInput(answer)
        } else if (answer.fieldType === 'RADIO') {
            return this.createRadioInput(answer)
        } else {
            return null
        }
    }

    /**
     * 設問タイプ：テキストの設問欄を生成
     */
    private static createTextInput(answer: ProfileAnswer): HTMLInputElement {
        return this.createInputCommon('text', answer)
    }

    /**
     * 設問タイプ：メールの設問欄を生成
     */
    private static createMailInput(answer: ProfileAnswer): HTMLInputElement {
        return this.createInputCommon('email', answer)
    }

    /**
     * 設問タイプ：電話番号の設問欄を生成
     */
    private static createTelInput(answer: ProfileAnswer): HTMLInputElement {
        return this.createInputCommon('tel', answer)
    }

    /**
     * 設問タイプ：郵便番号の設問欄を生成
     */
    private static createZipInput(answer: ProfileAnswer): HTMLInputElement {
        const c = this.createInputCommon('text', answer)
        c.inputMode = 'numeric'
        c.maxLength = 7
        c.placeholder = '000000 (ハイフンなし)'
        return c
    }

    /**
     * 設問タイプ：郵便番号の設問欄を生成
     */
    private static createDateInput(answer: ProfileAnswer): HTMLInputElement {
        return this.createInputCommon('date', answer)
    }

    /**
     * 設問タイプ：ドロップダウンの設問欄を生成
     */
    private static createDropDownInput(answer: ProfileAnswer): HTMLElement {
        const select = document.createElement('select')
        select.classList.add('form-select')
        select.name = answer.questionId

        if (answer.required) {
            select.required = true
        }

        for (const opt of answer.options ?? []) {
            const option = document.createElement('option')
            option.value = opt.value
            option.innerText = opt.text
            if (opt.value === answer.value) {
                option.selected = true
            }
            select.appendChild(option)
        }

        return select
    }

    /**
     * 設問タイプ：ラジオボタンの設問欄を生成
     */
    private static createRadioInput(answer: ProfileAnswer): HTMLElement {
        const div = document.createElement('div')
        div.classList.add('w-100')

        for (const opt of answer.options ?? []) {
            const inputId = `${answer.questionId}-${opt.value}`

            // ラジオボックスを生成
            const input = document.createElement('input')
            input.type = 'radio'
            input.value = opt.value
            input.name = answer.questionId
            input.id = inputId
            input.classList.add('form-check-input')

            // ラベルを生成
            const label = document.createElement('label')
            label.innerText = opt.text
            label.htmlFor = inputId
            label.classList.add('form-check-label')

            // ラベル、ラジオボックスを入れるコンテナ
            const t = document.createElement('div')
            t.classList.add('form-check')
            t.classList.add('form-check-inline')
            t.appendChild(input)
            t.appendChild(label)

            if (opt.value === answer.value) {
                input.checked = true
            }

            div.appendChild(t)
        }

        return div
    }

    /**
     * 回答欄inputの共通部を生成
     */
    private static createInputCommon(type: string, answer: ProfileAnswer): HTMLInputElement {
        const input = document.createElement('input')
        input.autocomplete = 'off'
        input.type = type
        input.id = `q-${answer.questionId}`
        input.classList.add('form-control')
        input.name = answer.questionId
        if (answer.value) {
            input.value = answer.value
        }
        return input
    }

    /**
     * 設問の回答欄inputを入れるコンテナDOMを生成
     */
    private static createContainer(answer: ProfileAnswer): HTMLElement {
        const div = document.createElement('div')
        div.classList.add('col-12')
        div.classList.add('form-item')
        div.classList.add('profile-item')
        div.classList.add('question-item')
        div.setAttribute('data-profile-id', answer.questionId)
        div.setAttribute('data-question-type', answer.fieldType)
        return div
    }

    /**
     * ラベルDOM生成
     */
    private static createLabel(answer: ProfileAnswer): HTMLLabelElement {
        const le = document.createElement('label')
        le.classList.add('col-12')
        le.classList.add('col-form-label')
        le.innerText = answer.name

        if (answer.required) {
            const rq = document.createElement('span')
            rq.classList.add('badge')
            rq.classList.add('bg-danger')
            rq.innerText = '必須'
            le.appendChild(rq)
        }

        return le
    }

    /**
     * テキスト入力項目の回答データを生成
     */
    private static buildTextItem(container: HTMLElement, key: string, value: string, required: boolean): ProfileDataItem | null {
        if (required && value.length == 0) {
            // 必須なのに未入力
            container.classList.add('invalid')
            return null
        }
        return {
            questionId: key,
            value: value
        }
    }

    private static buildProfileItemData(id: string, formValue: FormDataEntryValue | null): ProfileDataItem | null {
        const container = document.querySelector(`div[data-profile-id="${id}"]`)
        if (!container) {
            return null
        }
        const requiredText = container.getAttribute('data-profile-required') ?? 'false'
        const required = new Boolean(requiredText)
        if (required && formValue == null) {
            // 必須なのに入力されてない
            container.classList.add('invalid')
            return null
        }
        if (formValue instanceof File) {
            // ファイルは未対応
            container.classList.add('invalid')
            return null
        }

        const value = formValue?.toString() ?? ''
        return {
            questionId: id,
            value: value
        }
    }

    /**
     * プロフィールデータ生成
     */
    private static buildProfileData(): ProfileDataItem[] | null {
        // エラーの赤枠をいったん削除しておく
        document.querySelectorAll('#profile-form .invalid').forEach(elem => elem.classList.remove('invalid'))
        // エラーメッセージ非表示
        this.hideError()

        const results: ProfileDataItem[] = []
        let failed = false

        const form = document.getElementById('profile-app') as HTMLFormElement
        const fd = new FormData(form)
        for (const key of fd.keys()) {
            const obj = this.buildProfileItemData(key, fd.get(key))
            if (obj) {
                results.push(obj)
            } else {
                failed = true
            }
        }

        if (failed) {
            return null
        } else {
            return results
        }
    }

    /**
     * 個人情報保護方針に同意しているかチェック
     */
    private static checkPolicyAgreement(): boolean {
        // 同意のチェックボックスを取得
        const check = document.getElementById('privacy-policy-agree') as HTMLInputElement
        if (!check) {
            // チェックボックスが無いので、ひとまずOK
            return true
        }

        // チェック状態で返す
        return check.checked
    }

    /**
     * 「回答を送信」ボタンを押したときの処理
     */
    private static beforeSubmit(button: HTMLButtonElement): void {
        // 個人情報保護方針同意
        if (!this.checkPolicyAgreement()) {
            // 同意されてないので、ダメ
            this.showError('個人情報保護方針に同意が必要です。')
            return
        }

        // プロフィールデータを生成
        const r = this.buildProfileData()
        if (!r) {
            this.showError('入力内容を確認してください。')
            return
        }

        const formInput = document.getElementById('profileData') as HTMLInputElement
        if (formInput) {
            formInput.value = JSON.stringify(r)
        }
        loadingButton(button)
        button.form?.submit()
    }
}