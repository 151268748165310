import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { PrefectureCondition, PrefectureGroup } from "./ConditionObject";
import { PrefectureSelectOptions, PrefectureSelector } from "./PrefectureSelector";

const PANEL_PREFECTURES_TEMPLATE_ID = 'prefecture-condition-template'
const PANEL_PREFECTURE_ITEM_TEMPLATE_ID = 'prefecture-item-template'

/**
 * 居住地条件表示パネル
 */
export class PrefectureConditionPanel extends TypedBaseCondition<PrefectureGroup> {
    private _prefecturesList: HTMLElement

    constructor(readOnly: boolean) {
        super(readOnly)

        const p = this.createPrefecturesPanel()
        this._body.appendChild(p)

        const prefecturesList = cn1(p, 'condition-prefectures')
        if (prefecturesList) {
            this._prefecturesList = prefecturesList
        }

        show(p)
    }

    /**
     * 居住地条件を画面に反映
     */
    apply(condition: PrefectureGroup): void {
        this._condition = condition

        // 一旦クリア
        this._prefecturesList.innerHTML = ''

        // 各条件を反映
        condition.conditions.forEach(p => this.applyPrefecture(p))
    }

    /**
     * 条件タイプ
     */
    getType(): string {
        return "prefecture"
    }

    /**
     * 名称取得
     */
    getName(): string {
        return "居住地"
    }

    /**
     * 設定ダイアログ表示
     */
    onEdit(): void {
        const opts: PrefectureSelectOptions = {
            prefectures: this._condition,
            confirmed: (r) => {
                this.apply(r)
                this.raiseOnEditConfirmed()
            },
            cancelled: () => {}
        }
        new PrefectureSelector(opts).show()
    }

    private applyPrefecture(condition: PrefectureCondition): void {
        const p = this.createPrefectureConditionPanel()

        const selectedPrefectures: string[] = []
        for (const s of condition.prefectures) {
            const span = document.getElementById(s)
            if (span) {
                selectedPrefectures.push(span.innerText)
            }
        }

        this._prefecturesList.innerText = selectedPrefectures.join(", ")

        show(p)
    }

    private createPrefecturesPanel(): HTMLElement {
        const p = cloneNode(PANEL_PREFECTURES_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find prefectures viewer panel.'
        }

        return p
    }

    private createPrefectureConditionPanel(): HTMLElement {
        const p = cloneNode(PANEL_PREFECTURE_ITEM_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find prefecture condition viewer panel.'
        }

        return p
    }
}