import { hide, show } from "../utils"
import liff from '@line/liff'

/**
 * プライバシーポリシー処理のオプション
 */
export type PrivacyPolicyOption = {
    /** 入力画面のID */
    inputFormId: string

    /** 入力画面へ戻るボタンのID */
    backToFormId: string
}

export function setupPrivacyPolicy(option: PrivacyPolicyOption): void {
    // 「個人情報の取り扱いについて」リンクを取得
    const showPolicyLink = document.getElementById('show-privacy-policy')
    if (!showPolicyLink) {
        return
    }
    showPolicyLink.addEventListener('click', evt => {
        evt.preventDefault()
        hide(document.getElementById(option.inputFormId))
        show(document.getElementById('privacy-policy'))
    })
    // 各入力画面登録へ戻るリンクを取得
    const backProfileLink = document.getElementById(option.backToFormId)
    if (backProfileLink) {
        backProfileLink.addEventListener('click', evt =>{
            evt.preventDefault()
            hide(document.getElementById('privacy-policy'))
            show(document.getElementById(option.inputFormId))
        })
    }

    // プライバシーポリシーのリンクを取得
    const jumpAlphaPPLink = document.getElementById('jump-to-pp-link')
    if (jumpAlphaPPLink) {
        jumpAlphaPPLink.addEventListener('click', evt => {
            evt.preventDefault()
            const a = evt.target
            if (a instanceof HTMLAnchorElement) {
                liff.openWindow({ url: a.href })
            }
        })
    }
}