import { Gradient, Options } from "qr-code-styling"
import { BaseQrOption } from "./BaseQrOption"
import { ShapeStyle } from "./ShapeStyle"
import { ShapeColor } from "./ShapeColor"
import { ShapeGradient } from "./ShapeGradient"

/**
 * 角の四角のオプション
 */
export class CornerDotOptions extends BaseQrOption {
    constructor(style: Options) {
        super()

        const panel = document.getElementById('qr-option-corner-dot') as HTMLElement

        let opts: {
            type?: string
            color?: string
            gradient?: Gradient
        }
        if (style.cornersDotOptions) {
            opts = style.cornersDotOptions
        } else {
            style.cornersDotOptions = {}
            opts = style.cornersDotOptions
        }

        new ShapeStyle(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeColor(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeGradient(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
    }
}