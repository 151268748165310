/**
 * getElementsByClassName()で取得した要素リストのうち、最初の1件を取得
 */
export function cn1(parent: HTMLElement | string, className: string): HTMLElement | null {
    if (typeof(parent) === 'string') {
        return document.getElementsByClassName(parent)[0] as HTMLElement
    } else {
        return parent.getElementsByClassName(className)[0] as HTMLElement
    }
}
