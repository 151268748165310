import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { TagCondition } from "./ConditionObject";
import { TagSelector } from "./TagSelector";

/**
 * タグ条件設定パネル
 */
export class TagConditionPanel extends TypedBaseCondition<TagCondition> {
    private _element: HTMLElement

    private _condition: TagCondition

    constructor() {
        super()

        this._element = this.createConditionElement()
    }

    apply(tag: TagCondition): void {
        const x = this._element

        // 抽出条件
        this.applyMatch(tag)

        // タグ
        this.applySelectedTag(tag)

        this._body.appendChild(x)
        this._condition = tag

        show(x)
    }

    /**
     * 配信グループのタグ条件データ作成
     */
    buildConditionJson(): TagCondition {
        return this._condition
    }

    getName(): string {
        return 'タグ'
    }

    onEdit(): void {
        new TagSelector(this._condition).show(tag => {
            this.apply(tag)
            this.raiseOnEditConfirmed()
        })
    }

    /**
     * タグ条件設定パネル生成
     */
    private createConditionElement(): HTMLElement {
        const x = cloneNode('condition-tags')
        if (!x) {
            throw 'Cannot create tag condition element.'
        }

        return x
    }


    /**
     * 条件を反映   
     */
    private applyMatch(tag: TagCondition): void {
        cn1(this._element, 'data-match')?.setAttribute('data-match', tag.match)
    }

    /**
     * 選択したタグを反映
     */
    private applySelectedTag(tag: TagCondition): void {
        const c = cn1(this._element, 'data-tag-list')
        if (!c) {
            return
        }

        // 一旦中身をクリア
        c.innerHTML = ''

        for (const t of tag.tags) {
            // ダイアログにタグのスタイル等あるので、それをパクる
            const sp = document.querySelector(`span[data-tag-id="${t}"]`)?.cloneNode(true) as HTMLElement
            if (!sp) {
                continue
            }

            // 取得できたタグ情報を利用
            sp.classList.add('mx-1')
            c.appendChild(sp)
        }
    }
}