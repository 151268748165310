import { BaseComponent } from "../common/BaseComponent"
import { cloneNode, cn1, hide, show } from "../utils"
import { SelectEntry } from "./formObject"

/** 選択肢情報DOMのテンプレートID */
const SELECT_ITEM_TEMPLATE_ID = 'select-item-template'

/**
 * ラジオ、チェックボックスの選択肢1つ
 */
export class SelectItem extends BaseComponent {
    private _onRemoved:() => void = this.defaultOnRemove

    private _textInput: HTMLInputElement

    constructor() {
        super()

        const panel = this.createPanel()

        this.setElement(panel)
    }

    apply(item: SelectEntry): void {
        // 選択肢テキスト
        this._textInput.value = item.text
    }

    setOnRemove(f: () => void): void {
        this._onRemoved = f
    }

    setRemovable(b: boolean): void {
        const btn = cn1(this.getElement(), 'remove')
        if (b) {
            show(btn)
        } else {
            hide(btn)
        }
    }

    /**
     * 入力データの検証
     */
    validate(): string | null{
        if (this._textInput.value.length == 0) {
            this._textInput.classList.add('is-invalid')
            return '選択肢のテキストを入力してください。'
        }

        this._textInput.classList.remove('is-invalid')

        return null
    }

    /**
     * 選択肢データ生成
     */
    buildFormJson(): SelectEntry {
        // 選択肢テキスト
        const text = this._textInput.value

        return {
            text: text
        }
    }

    private createPanel(): HTMLElement {
        const p = cloneNode(SELECT_ITEM_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find form select item panel.'
        }

        const input = cn1(p, 'select-item') as HTMLInputElement
        if (!input) {
            throw 'Cannot find form select item input.'
        }
        this._textInput = input

        // 削除ボタン
        const delBtn = cn1(p, 'remove')
        if (delBtn) {
            delBtn.addEventListener('click', () => this._onRemoved())
        }

        show(p)

        return p
    }

    private defaultOnRemove(): void {
    }
}