import { BaseComponent } from "../common/BaseComponent";
import instances from "../common/instances";
import { cloneNode, cn1, hide, show } from "../utils";
import { Action } from "./action";
import { PostbackObject } from "./postbackObjects";

/** ポストバックアクションエディタテンプレートDOMのID */
const POSTBACK_TEMPLATE_ID = 'postback-action-editor-template'
/** アクションリストコンテナのクラス名 */
const PANEL_ACTION_LIST_CLASSNAME = 'action-list-panel'
/** アクション未作成メッセージパネルのクラス名 */
const PANEL_ACTION_EMPTY_CLASSNAME = 'action-empty-panel'

/**
 * ポストバックアクションエディタクラス
 */
export class PostbackActionEditor extends BaseComponent {
    private _container: HTMLElement
    private _actionListPanel: HTMLElement
    private _actionEmptyPanel: HTMLElement

    private _actions: Action[] = []

    constructor(container: HTMLElement) {
        super()

        this._container = container
    }

    /**
     * ポストバックアクションエディタの初期化
     * 
     * @param postbackJson ポストバックアクションJSONデータ
     */
    init(postbackJson: string): void {
        // エディタDOMをクローン
        const editor = cloneNode(POSTBACK_TEMPLATE_ID)
        if (!editor) {
            return
        }
        // アクションリストパネルを取得
        const actionListPanel = cn1(editor, PANEL_ACTION_LIST_CLASSNAME)
        if (!actionListPanel) {
            return
        }
        // アクション未作成パネルを取得
        const actionEmptyPanel = cn1(editor, PANEL_ACTION_EMPTY_CLASSNAME)
        if (!actionEmptyPanel) {
            return
        }

        this._actionListPanel = actionListPanel
        this._actionEmptyPanel = actionEmptyPanel

        // アクション追加ボタンクリック時の処理
        const addBtn = cn1(editor, 'add-action-button')
        if (addBtn) {
            addBtn.addEventListener('click', () => {
                // 新規アクション追加
                this.addAction({
                    type: 'ADD_TAG',
                    tags: []
                })
                // アクションリストパネル表示切替
                this.updateActionListVisible()
            })
        }

        // ポストバックアクションJSONが指定されていれば画面に反映
        if (postbackJson) {
            this.applyPostbackJson(JSON.parse(postbackJson))
        }

        // アクションリストパネル表示切替
        this.updateActionListVisible()

        // エディタ用コンテナに追加
        this._container.appendChild(editor)
        // エディタ表示
        show(editor)
    }

    /**
     * ポストバックアクション定義JSON文字列を取得
     */
    buildPostbackJson(): string {
        const actions: PostbackObject[] = []
        for (let n = 0; n < this._actions.length; n++) {
            // 各アクションの定義オブジェクト生成
            const b = this._actions[n].buildActionJson()
            if (!b) {
                // 生成してない場合はどこかにエラーがある
                return ''
            }
            // 表示順を設定
            b.order = n
            // 結果用配列に追加
            actions.push(b)
        }
        return JSON.stringify(actions)
    }

    /**
     * ポストバックアクションエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     */
    static getOrCreateInstance(containerElement: HTMLElement): PostbackActionEditor {
        let obj: BaseComponent | null  = instances.get(containerElement)
        if (!obj) {
            obj = new PostbackActionEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as PostbackActionEditor
    }


    /**
     * ポストバックアクション定義をエディタに反映
     * 
     * @param messages ポストバックアクション定義
     */
    private applyPostbackJson(actions: PostbackObject[]): void {
        // アクションデータを作成していく
        actions.forEach(v => this.addAction(v))
    }

    /**
     * アクション定義を追加
     * 
     * @param action アクション定義
     */
    private addAction(action: PostbackObject): void {
        // アクションデータを作成
        const a = new Action(action)

        // アクション削除処理
        a.setOnRemovedHandler(() => this.onActionRemoved(a))

        // 内部配列に追加
        this._actions.push(a)
        // アクションDOMもエディタに追加
        this._actionListPanel.appendChild(a.getElement())
    }

    /**
     * アクションが削除されたときの処理
     * 
     * @param action 削除されたアクション
     */
    private onActionRemoved(action: Action): void {
        // アクションリストから削除されたアクションも削除
        this._actions = this._actions.filter(t => t != action)
        // DOM要素からも削除
        this._actionListPanel.removeChild(action.getElement())
    }

    /**
     * アクションリストパネル表示切替
     */
    private updateActionListVisible(): void {
        if (this._actions.length > 0) {
            // アクションが未作成メッセージパネルは非表示
            hide(this._actionEmptyPanel)
            // アクションリストを表示
            show(this._actionListPanel)
        } else {
            // アクションリストは非表示
            hide(this._actionListPanel)
            // アクションが未作成メッセージパネルを表示
            show(this._actionEmptyPanel)
        }
    }
}