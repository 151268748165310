import { Gradient, Options } from "qr-code-styling";
import { BaseQrOption } from "./BaseQrOption"

interface ColorOptionType {
    color?: string
    gradient?: Gradient
}

/**
 * 色オプション
 */
export class ShapeColor extends BaseQrOption {
    protected _style: ColorOptionType

    constructor(panel: HTMLElement, style: ColorOptionType) {
        super()
        this._style = style

        this.setup(panel)
    }

    private applyConfig(color: string): void {
        if (this._style.gradient) {
            // グラデーションが設定されている場合は、1番目の色にも反映
            this._style.gradient.colorStops[0].color = color
        }
        this._style.color = color
        this.raiseOptionUpdated()
    }

    private setup(dialog: HTMLElement): void {
        const input = dialog.querySelector(`input.qr-color`) as HTMLInputElement
        if (!input) {
            return
        }

        input.addEventListener('change', () => this.applyConfig(input.value))

        if (this._style.color) {
            input.value = this._style.color
        }
    }
}