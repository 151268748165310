import * as bootstrap from 'bootstrap'
import { GenderCondition, GenderGroup } from "./ConditionObject"
import { cloneNode, cn1, hide, show, uuid } from '../utils'

/** 選別選択モーダルのID */
const GENDER_SELECTOR_MODAL_ID = 'select-gender-modal'

export interface GenderSelectOptions {
    genders: GenderGroup
    confirmed?: (cond: GenderGroup) => void
    cancelled?: () => void
}

/**
 * 性別条件選択ダイアログ
 */
export class GenderSelector {
    private _element: HTMLElement
    private _body: HTMLElement
    private _modal: bootstrap.Modal

    private _options: GenderSelectOptions
    private _rules: GenderRule[] = []

    private _confirmed: boolean = false

    private _onConfirmBind
    private _onModalClosedBind

    constructor(options: GenderSelectOptions) {
        this._element = this.createDialogElement()
        this._options = options

        const body = this._element.getElementsByClassName('genders-list')
        if (!body || body.length == 0) {
            throw 'Cannot find gender condition body.'
        }
        this._body = body[0] as HTMLElement

        // 現在の選択をダイアログに反映
        if (!options.genders.conditions || options.genders.conditions.length == 0) {
            options.genders.conditions = [{genders: []}]
        }
        this.apply(options.genders)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(this._element)
    }

    /**
     * ダイアログ表示
     */
    show(): void {
        this._modal.show()
    }

    /**
     * 設定内容を画面に反映
     */
    private apply(genders: GenderGroup): void {
        // 一旦クリア
        this.clear()

        for (const r of genders.conditions) {
            this.applyGender(r)
        }
    }

    /**
     * 選択している性別を画面に反映
     */
    private applyGender(g: GenderCondition): void {
        const rule = new GenderRule(g)

        this._rules.push(rule)
        this._body.appendChild(rule.getPanel())
    }

    /**
     * 設定内容クリア
     */
    private clear(): void {
        this._rules = []
        this._body.innerHTML = ''
    }

    /**
     * 性別条件データを作成
     */
    private buildGenderCondition(): GenderGroup {
        return {
            conditions: this._rules.map(t => t.buildConditionObject())
        }
    }

    /**
     * 条件の入力をチェック
     */
    private checkDateInputs(): boolean {
        let checked = true
        for (const t of this._rules) {
            if (!t.checkDataInputs()) {
                checked = false
            }
        }
        return checked
    }

    /**
     * 確定ボタンを押したときの処理
     */
    private onModalConfirm(): void {
        if (!this.checkDateInputs()) {
            return
        }
        // 登録日条件設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    /**
     * ダイアログが閉じられるときの処理
     */
    private onModalClosed(): void {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                // 誕生日条件情報を作成
                const t = this.buildGenderCondition()
                this._options.confirmed(t)
            }
        } else {
            // キャンセルしたのでキャンセル時のハンドラを実行
            if (this._options.cancelled) {
                this._options.cancelled()
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    private createDialogElement(): HTMLElement {
        const p = document.getElementById(GENDER_SELECTOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find gender selector modal'
        }

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(p, 'btn-gender-select-confirm')?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private dispose() {
        cn1(this._element, 'btn-gender-select-confirm')?.removeEventListener('click', this._onConfirmBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}

class GenderRule {
    private _panel: HTMLElement

    constructor(gender: GenderCondition) {
        this._panel = this.createPanel()

        this.apply(gender)
    }

    getPanel(): HTMLElement {
        return this._panel
    }

    /**
     * 何らかの性別が選択されているかチェック
     */
    checkDataInputs(): boolean {
        const checks = this._panel.querySelectorAll('div.gender-item input[type="checkbox"]')
        let checked = false
        for (const c of checks) {
            if ((c as HTMLInputElement).checked) {
                checked = true
                break
            }
        }

        const m = cn1(this._panel, 'alert')
        if (checked) {
            if (m) { hide(m) }
        } else {
            if (m) { show(m) }
        }

        return checked
    }

    /**
     * 性別条件情報生成
     */
    buildConditionObject(): GenderCondition {
        // チェックされた性別を保持しておく配列
        const checkedGenders: string[] = []
        // チェックボックス取得
        const checks = this._panel.querySelectorAll('div.gender-item input[type="checkbox"]')
        for (const c of checks) {
            if (c instanceof HTMLInputElement && c.checked) {
                // チェックONなので性別を保持
                checkedGenders.push(c.value)
            }
        }

        return {
            genders: checkedGenders
        }
    }

    private apply(c: GenderCondition): void {
        c.genders.forEach(g => {
            const e = this._panel.querySelector(`input[value="${g}"]`) as HTMLInputElement
            if (e) {
                e.checked = true
            }
        })
    }

    private createPanel(): HTMLElement {
        const p = cloneNode('gender-rule-template')
        if (!p) {
            throw 'Cannot create gender rule container'
        }

        const id = uuid()
        const genderChecks = p.getElementsByClassName('gender-item')
        for (const m of genderChecks) {
            const input = m.getElementsByTagName('input')[0]
            const label = m.getElementsByTagName('label')[0]
            input.id = `prefecture-${id}-${input.value}`
            label.htmlFor = input.id
        }

        show(p)

        return p
    }
}