import { Gradient, Options } from "qr-code-styling";
import { BaseQrOption } from "./BaseQrOption";
import { ShapeStyle } from "./ShapeStyle";
import { ShapeColor } from "./ShapeColor";
import { ShapeGradient } from "./ShapeGradient";

/**
 * 角の枠線のオプション
 */
export class CornerSquareOptions extends BaseQrOption {
    constructor(style: Options) {
        super()

        const panel = document.getElementById('qr-option-corner-square') as HTMLElement
        
        let opts: {
            type?: string
            color?: string
            gradient?: Gradient
        }
        if (style.cornersSquareOptions) {
            opts = style.cornersSquareOptions
        } else {
            style.cornersSquareOptions = {}
            opts = style.cornersSquareOptions
        }

        new ShapeStyle(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeColor(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeGradient(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
    }
}