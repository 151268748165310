import { cn1 } from "../utils"

/**
 * パスワード欄の表示非表示を切り替える
 */
export class VisibleSwitcher {
    private _input: HTMLInputElement
    private _button: HTMLElement

    private _visible: boolean = false

    private constructor(input: HTMLInputElement, button: HTMLElement) {
        this._input = input
        this._button = button

        // ボタンをクリックしたときに切り替わるように
        this._button.addEventListener('click', () => this.toggle())
        // まずは非表示状態
        this.hide()
    }

    /**
     * 表示・非表示を切り替える
     */
    private toggle() {
        if (this._visible) {
            this.hide()
        } else {
            this.show()
        }
    }

    /**
     * 表示状態に設定
     */
    private show() {
        // inputをtextに設定することで表示にする
        this._input.type = 'text'
        // 表示にボタンを非表示に
        this.hideX(this._button.getElementsByClassName('login-form-visible'))
        // 非表示にボタンを表示に
        this.showX(this._button.getElementsByClassName('login-form-invisible'))
        // 表示状態
        this._visible = true
    }

    /**
     * 非表示状態に設定
     */
    private hide() {
        // inputをpasswordに設定することで表示にする
        this._input.type = 'password'
        // 非表示にボタンを非表示に
        this.hideX(this._button.getElementsByClassName('login-form-invisible'))
        // 表示にボタンを表示に
        this.showX(this._button.getElementsByClassName('login-form-visible'))
        // 非表示状態
        this._visible = false
    }

    private showX(elems: HTMLCollection) {
        for (let x of elems) {
            x.classList.remove('d-none')
        }
    }

    private hideX(elems: HTMLCollection) {
        for (let x of elems) {
            x.classList.add('d-none')
        }
    }

    /**
     * 表示切り替え機を作成
     * 
     * @param container 表示切り替え機を含むコンテナDOM(login-formクラスを持ってるやつ)
     */
    static create(container: HTMLElement): VisibleSwitcher | null {
        // コンテナ内のINPUTを取得
        const input = cn1(container, 'login-form-input')
        if (!input || !(input instanceof HTMLInputElement)) {
            return null
        }

        // 表示非表示を切りかえるボタンを取得
        const button = cn1(container, 'login-form-button')
        if (!button) {
            return null
        }

        // 両方取得できたので、切り替え器のインスタンスを作成
        return new VisibleSwitcher(input, button)
    }
}