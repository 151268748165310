import { Gradient, Options } from "qr-code-styling"
import { BaseQrOption } from "./BaseQrOption"
import { ShapeColor } from "./ShapeColor"
import { ShapeGradient } from "./ShapeGradient"

/**
 * 背景色のオプション
 */
export class BackgroundOptions extends BaseQrOption {
    constructor(style: Options) {
        super()

        const panel = document.getElementById('qr-option-background') as HTMLElement

        let opts: {
            color?: string
            gradient?: Gradient
        }
        if (style.backgroundOptions) {
            opts = style.backgroundOptions
        } else {
            style.backgroundOptions = {}
            opts = style.backgroundOptions
        }

        new ShapeColor(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeGradient(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
    }
}