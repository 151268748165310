import { cloneNode, cn1, show } from "../utils"

/** 条件表示パネルのテンプレートのID */
const PANEL_CONDITION_TEMPLATE_ID = "condition-panel-template"

/**
 * 配信グループ条件ベース
 */
export abstract class BaseCondition {
    private _container: HTMLElement
    protected _body: HTMLElement

    private _onRemoveHandler = this.defaultOnRemove
    private _onEditConfirmed: () => void = this.defaultOnEditConfirmed

    constructor(readOnly: boolean) {
        this._container = this.createPanelElement(readOnly)

        const b = cn1(this._container, 'card-body')
        if (!b) {
            throw 'Cannot find condition body'
        }

        this._body = b
        show(this._container)
    }

    /**
     * 条件タイプ
     */
    abstract getType(): string

    /**
     * 条件名称
     */
    abstract getName(): string

    /**
     * 編集
     */
    abstract onEdit(): void


    /**
     * 条件設定パネルのDOMを取得
     */
    getConditionPanel(): HTMLElement {
        return this._container
    }

    /**
     * 条件編集確定時のハンドラを登録
     * 
     * @param f 編集OKのときに実行する処理
     */
    setOnEditConfirmed(f: () => void): void {
        this._onEditConfirmed = f
    }

    /**
     * 条件削除時のハンドラを登録
     * 
     * @param f 削除されたときに実行する処理
     */
    setOnRemoved(f: () => void): void {
        this._onRemoveHandler = f
    }

    protected raiseOnEditConfirmed() {
        this._onEditConfirmed()
    }

    private onRemove(): void {
        if (confirm(`条件[${this.getName()}]を削除しますか？`)) {
            this._onRemoveHandler()
        }
    }

    private createPanelElement(readOnly: boolean): HTMLElement {
        const p = cloneNode(PANEL_CONDITION_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot create condition container.'
        }

        // ヘッダのテキスト
        const h = cn1(p, 'condition-name')
        if (h) {
            h.innerText = this.getName()
        }

        if (readOnly) {
            this.deleteButtons(p)
        } else {
            this.setupButtons(p)
        }

        return p
    }

    /**
     * 各ボタンのイベントハンドラを登録
     */
    private setupButtons(panel: HTMLElement): void {
        // 編集ボタン
        const editBtn = cn1(panel, 'edit')
        if (editBtn) {
            editBtn.addEventListener('click', () => this.onEdit())
        }

        // 削除ボタン
        const deleteBtn = cn1(panel, 'delete')
        if (deleteBtn) {
            deleteBtn.addEventListener('click', () => this.onRemove())
        }
    }

    /**
     * 読み取りモードなので各ボタン削除
     */
    private deleteButtons(panel: HTMLElement): void {
        // 編集ボタン
        const editBtn = cn1(panel, 'edit')
        if (editBtn) {
            editBtn.parentElement?.removeChild(editBtn)
        }

        // 削除ボタン
        const deleteBtn = cn1(panel, 'delete')
        if (deleteBtn) {
            deleteBtn.parentElement?.removeChild(deleteBtn)
        }
    }

    private defaultOnRemove(): void {
    }

    private defaultOnEditConfirmed(): void {
    }
}

export abstract class TypedBaseCondition<T> extends BaseCondition {
    protected _condition: T

    abstract apply(condition: T): void

    buildConditionJson(): T {
        return this._condition
    }
}