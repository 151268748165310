import { BaseComponent } from "../common/BaseComponent";
import instances from "../common/instances";
import { cloneNode, cn1, hide, show } from "../utils";
import { GroupCondition } from "./GroupCondition";
import { ConditionGroupObject, ConditionObject } from "./ConditionObject";

/** 配信グループエディタテンプレートDOMのID */
const CONDITION_EDITOR_TEMPLATE_ID = 'condition-editor-template'
/** グループ条件リストのクラス名 */
const PANEL_GROUPS_CLASS_NAME = "condition-groups-panel"
/** グループ未作成メッセージのクラス名 */
const PANEL_EMPTY_CLASS_NAME = "empty-panel"

export class ConditionEditor extends BaseComponent {
    /** 配信グループエディタ格納用のコンテナDOM */
    private _editor: HTMLElement
    /** グループ条件リストを格納するコンテナDOM */
    private _groups: HTMLElement

    /** 読み取り専用モード */
    private _readOnly: boolean = false

    /** グループ条件のリスト */
    private _conditionGroups: GroupCondition[] = []

    constructor(container: HTMLElement) {
        super()

        const editor = this.createEditorElement()

        const groupsPanel = cn1(editor, PANEL_GROUPS_CLASS_NAME)
        if (!groupsPanel) {
            return
        }

        this._editor = editor
        this._groups = groupsPanel

        if (editor.classList.contains('readonly')) {
            // エディターにreadonlyクラスが設定されていれば読み取り専用モード
            this._readOnly = true
        }

        container.appendChild(editor)
        show(editor)
    }

    /**
     * 配信グループエディタ初期化
     * 
     * @param conditionJson 配信グループ定義JSON
     */
    init(conditionJson: string): void {
        if (conditionJson) {
            this.applyConditionObject(JSON.parse(conditionJson))
        }

        this.updateGroupVisible()
    }

    /**
     * 条件データ生成
     */
    buildConditionsJson(): ConditionGroupObject {
        return {
            groups: this._conditionGroups.map(t => t.buildConditionJson())
        }
    }

    /**
     * メッセージエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     * @returns 
     */
    static getOrCreateInstance(containerElement: HTMLElement): ConditionEditor {
        let obj: BaseComponent | null  = instances.get(containerElement)
        if (!obj) {
            obj = new ConditionEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as ConditionEditor
    }

    /**
     * 配信グループ条件データを画面に反映
     */
    private applyConditionObject(objs: ConditionGroupObject): void {

        for (const o of objs.groups) {
            this.addNewGroup(o)
        }
    }

    /**
     * 新しいグループ条件を追加
     */
    private addNewGroup(cond: ConditionObject): void {
        const g = new GroupCondition(this._readOnly)
        g.setNumberLabel(this._conditionGroups.length + 1)
        g.apply(cond)

        g.setOnGroupRemoved(() => this.onGroupRemoved(g))

        // 内部に保持
        this._conditionGroups.push(g)
        this._groups.appendChild(g.getElement())
    }

    /**
     * グループ条件が削除された
     * 
     * @param group 削除されたグループ条件
     */
    private onGroupRemoved(group: GroupCondition): void {
        this._conditionGroups = this._conditionGroups.filter(t => t !== group)
        this._groups.removeChild(group.getElement())

        // グループ条件の番号を振り直し
        for (let x = 0; x < this._conditionGroups.length; x++) {
            this._conditionGroups[x].setNumberLabel(x + 1)
        }

        this.updateGroupVisible()
    }

    /**
     * グループ条件リスト等の表示切替
     */
    private updateGroupVisible(): void {
        if (this._conditionGroups.length > 0) {
            // グループ条件があるので条件リストを表示
            hide(cn1(this._editor, PANEL_EMPTY_CLASS_NAME))
            show(cn1(this._editor, PANEL_GROUPS_CLASS_NAME))
        } else {
            // グループ条件が作成されていない
            hide(cn1(this._editor, PANEL_GROUPS_CLASS_NAME))
            show(cn1(this._editor, PANEL_EMPTY_CLASS_NAME))
        }
    }

    /**
     * 配信グループエディタDOM生成
     */
    private createEditorElement(): HTMLElement {
        // 配信グループエディタDOM生成
        const editor = cloneNode(CONDITION_EDITOR_TEMPLATE_ID)
        if (!editor) {
            throw 'Cannot create condition editor element.'
        }

        // グループ条件追加ボタン選択
        const button = cn1(editor, 'add-group-condition')
        if (button) {
            button.addEventListener('click', () => {
                this.addNewGroup({})
                this.updateGroupVisible()
            })
        }

        return editor
    }
}