/**
 * ページ読み込み時に実行する処理を登録
 *
 * @param f ページ読み込み時に実行する処理
 */
export function addOnLoad(f: () => void): void {
    if (document.readyState === 'loading') {
        // まだ読み込み中なので、読み込み完了イベントハンドラに登録しておく
        document.addEventListener('DOMContentLoaded', f)
    } else {
        // 既に読み込みが完了しているので、処理を実行
        f()
    }
}
