import bootstrap from 'bootstrap'

/**
 * クリップボードにdata-copy-text属性に設定されているテキストをコピー
 * 
 * @param element data-copy-text属性が設定されている要素
 */
export function clipboardCopy(element: HTMLElement): void {
    const f = element.getAttribute('data-copy-text') ?? ''
    if (!f) {
        return
    }
    navigator.clipboard.writeText(f).then(() => {
        const p = bootstrap.Popover.getOrCreateInstance(element, { content: 'コピーしました。' })
        p.show()
        setTimeout(() => p.hide(), 2000)
    })
}