import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { RouteCondition, RouteGroup } from "./ConditionObject";
import { RouteSelectOptions, RouteSelector } from "./RouteSelector";

/** 登録経路条件表示パネルのテンプレートのID */
const PANEL_ROUTES_TEMPLATE_ID = 'routes-condition-template'
/** 登録経路条件を表示するパネルのテンプレートID */
const PANEL_ROUTE_COND_TEMPLATE_ID = 'route-item-template'

/**
 * 登録経路条件パネル
 */
export class RouteConditionPanel extends TypedBaseCondition<RouteGroup> {
    private _panel: HTMLElement
    private _routesList: HTMLElement

    constructor(readOnly: boolean) {
        super(readOnly)

        const p = this.createRoutesPanel()
        this._body.appendChild(p)

        this._panel = p

        const routeList = cn1(p, 'condition-routes')
        if (routeList) {
            this._routesList = routeList
        }

        show(p)
    }

    /**
     * 登録経路条件の内容を画面に反映
     * 
     * @param routes 登録経路条件
     */
    apply(routes: RouteGroup): void {
        this._condition = routes

        // 一旦クリア
        this._routesList.innerHTML = ''

        // 各条件を反映
        routes.conditions.forEach(t => this.applyRoute(t))
    }

    /**
     * 条件タイプ
     */
    getType(): string {
        return "route"
    }

    /**
     * 名称取得
     */
    getName(): string {
        return "登録経路"
    }

    onEdit(): void {
        const opts: RouteSelectOptions = {
            routes: this._condition,
            confirmed: (route) => {
                this.apply(route)
                this.raiseOnEditConfirmed()
            },
            cancelled: () => {}
        }
        new RouteSelector(opts).show()
    }

    private applyRoute(route: RouteCondition): void {
        const p = this.createRouteItemPanel()

        const matchDiv = cn1(p, 'condition-match')
        if (matchDiv) {
            matchDiv.innerText = '次のいずれかの登録経路'
        }

        const routesDiv = cn1(p, 'condition-routes')
        if (routesDiv) {
            for (const t of route.routes) {
                const div = document.createElement('div')
                div.classList.add('w-auto')
                const rs = cloneNode(t)
                if (rs) {
                    div.appendChild(rs)
                }
                routesDiv.appendChild(div)
            }
        }

        this._routesList.appendChild(p)

        show(p)
    }

    private createRoutesPanel(): HTMLElement {
        const p = cloneNode(PANEL_ROUTES_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find routes viewer panel.'
        }

        return p
    }

    private createRouteItemPanel(): HTMLElement {
        const p = cloneNode(PANEL_ROUTE_COND_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find route condition viewer panel.'
        }

        return p
    }
}