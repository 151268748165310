import { Gradient, Options } from "qr-code-styling"
import { BaseQrOption } from "./BaseQrOption"
import { ShapeStyle } from "./ShapeStyle"
import { ShapeColor } from "./ShapeColor"
import { ShapeGradient } from "./ShapeGradient"

/**
 * セルオプション
 */
export class CellOptions extends BaseQrOption {
    constructor(style: Options) {
        super()

        const panel = document.getElementById('qr-option-cell') as HTMLElement
        
        let opts: {
            type?: string
            color?: string
            gradient?: Gradient
        }
        if (style.dotsOptions) {
            opts = style.dotsOptions
        } else {
            style.dotsOptions = {}
            opts = style.dotsOptions
        }

        new ShapeStyle(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeColor(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
        new ShapeGradient(panel, opts).setOnOptionUpdated(() => this.raiseOptionUpdated())
    }
}