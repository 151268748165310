import { BaseTagAction } from "./baseTagAction";
import { POSTBACK_ACTION_TYPES } from "./postbackObjects";

/** 追加タグ選択パネルのクラス名 */
const INPUT_ADD_TAGS_CLASSNAME = 'postback-action-add-tag'

/**
 * ポストバックアクション - タグ追加クラス
 */
export class AddTag extends BaseTagAction {
    constructor(parent: HTMLElement) {
        super(parent, INPUT_ADD_TAGS_CLASSNAME, POSTBACK_ACTION_TYPES.ADD_TAG)
    }
}