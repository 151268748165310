import { BlockItem, SpanItem } from "./messageObject";
import { Span } from "./span";
import { TextEditOptions, TextEditor, TextEditorInfo } from "./TextEditor";

/**
 * テキスト
 */
export class Text {
    private _parentElement: HTMLElement

    private _spans: Span[] = []

    private _onTextChanged: () => void = () => {}

    constructor(parentBlockElement: HTMLElement) {
        this._parentElement = parentBlockElement
    }

    /**
     * テキストブロックデータ反映
     * 
     * @param data テキストブロックデータ
     */
    apply(data: BlockItem): void {
        // 文字列SPANデータ作成
        const spans = data.span ?? []
        this._spans = spans.map(s => this.createSpan(s))

        // 要素DOMを生成して、テキストコンテナ要素に入れる
        this._spans.forEach(s => this.setupSpanElement(s))
    }

    /**
     * 新しい文字列SPANを追加
     */
    addNewText(): void {
        const newSpanEntry: TextEditorInfo = {
            text: '',
            size: 'sm',
            color: '#000000',
            spanId: '',
        }
        this.showTextEditorInternal(newSpanEntry)
    }

    /**
     * テキストが空欄かどうか
     */
    isEmpty(): boolean {
        return this._spans.length == 0
    }

    /**
     * 内部のテキストデータが変更された場合の処理を登録
     */
    setOnTextChanged(f: () => void) {
        this._onTextChanged = f
    }

    /**
     * 保存用JSONデータ作成
     */
    buildFlexObject(): BlockItem {
        return {
            type: 'text',
            span: this._spans.map(c => c.buildFlexObject())
        }
    }

    /**
     * 文字列SPANデータ作成
     */
    private createSpan(data: SpanItem): Span {
        // SPANデータ作成
        const span = new Span()

        // SPANデータを反映
        if (data.text) {
            span.applySpan(data)
        }

        return span
    }

    /**
     * SPANデータ初期設定
     */
    private setupSpanElement(s: Span): void {
        // SPAN要素DOM取得
        const elem = s.getElement()
        // SPAN要素クリック時に編集ダイアログを表示させる
        elem.addEventListener('click', () => this.showTextEditor(s))
        // コンテナDOMに追加
        this._parentElement.appendChild(elem)
    }

    /**
     * SPANデータを編集するダイアログを表示
     * 
     * @param span 編集するSPANデータ
     */
    private showTextEditor(span: Span): void {
        const entry: TextEditorInfo = {
            text: span.getText(),
            size: span.getSize(),
            color: span.getColor(),
            spanId: span.getId(),
        }
        this.showTextEditorInternal(entry)
    }

    /**
     * テキスト編集ダイアログ表示
     * 
     * @param info テキスト編集用の情報
     */
    private showTextEditorInternal(info: TextEditorInfo): void {
        const options: TextEditOptions = {
            editorInfo: info,
            confirmed: d => this.onSpanEditConfirmed(d)
        }
        new TextEditor(options).show()
    }

    /**
     * テキスト編集が完了した際の処理
     * 
     * @param info 編集後のテキストデータ
     */
    private onSpanEditConfirmed(info: TextEditorInfo): void {
        if (info.text) {
            // テキストが設定されているので、編集内容を反映
            this.applySpan(info)
        } else {
            // テキストが空欄になってるので削除する
            if (info.spanId) {
                // 作成済みのSPANを削除
                this.removeSpan(info)
            }
        }

        // テキストの変更を通知
        this._onTextChanged()
    }

    /**
     * 編集したテキストデータをSPANデータに反映
     * 
     * @param info 編集後のテキストデータ
     */
    private applySpan(info: TextEditorInfo): void {
        // 画面反映用のデータを作成
        const item: SpanItem = {
            text: info.text,
            size: info.size,
            color: info.color,
        }

        if (info.spanId) {
            // SPAN IDが設定されているので、既存のSPANを編集した
            const targetSpan = this.getSpan(info.spanId)
            if (!targetSpan) {
                return
            }
            // 画面に反映
            targetSpan.applySpan(item)
        } else {
            // SPAN IDが未設定なので、テキストを新規に追加した
            const targetSpan = this.createSpan(item)
            this._spans.push(targetSpan)
            this.setupSpanElement(targetSpan)
        }
    }

    /**
     * SPANデータ削除処理
     */
    private removeSpan(info: TextEditorInfo): void {
        const exists = this.getSpan(info.spanId)
        if (!exists) {
            return
        }
        this._spans = this._spans.filter(t => t != exists)
        this._parentElement.removeChild(exists.getElement())
    }

    /**
     * 指定SPAN IDのデータを取得
     * 
     * @param id SPAN ID
     * @returns SPAN IDが存在していれば、該当のSPANデータ。なければnull
     */
    private getSpan(id: string): Span | null {
        const exists = this._spans.find(t => t.getId() == id)
        if (exists) {
            return exists
        } else {
            return null
        }
    }
}
