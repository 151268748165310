/**
 * 新規ウィンドウを開いてページを表示
 * 開いたウィンドウはメニューバーやステータスバー等は非表示
 *
 * @param url 表示するURL
 * @param width 新規ウィンドウの幅
 * @param height 新規ウィンドウの高さ
 */
export function windowOpen(url: string, width: number, height: number): void {
    const size: string = `width=${width},height=${height}`
    window.open(url, '', `${size},scrollbars=yes,resizable=no,menuba=no,toolbar=no,location=no,directories=no,status=no`)
}
