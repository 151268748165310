/**
 * 要素表示
 * 
 * @param element 表示設定するDOM要素
 */
export function show(element: HTMLElement | null): void {
    if (element) {
        element.classList.remove('d-none')
        element.removeAttribute('aria-hidden')
    }
}
