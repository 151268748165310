import instances from '../common/instances'
import { BaseComponent  } from '../common/BaseComponent'
import { cloneNode, cn1, hide, show } from '../utils'
import { MessageObject } from './messageObject'
import { Balloon } from './balloon'

/** メッセージエディタテンプレートのID */
const MESSAGE_EDITOR_TEMPLATE_ID = 'message-editor-template'

/** メッセージバルーンリストのコンテナクラス名 */
const MESSAGES_CONTAINER_CLASSNAME = 'messages-container'
/** 吹き出しが空欄メッセージのクラス名 */
const EMPTY_MESSAGES_CLASSNAME = 'empty-message'
/** 吹き出し追加ボタンのクラス名 */
const BUTTON_ADD_BALLOON_CLASSNAME = 'add-balloon'
/** 吹き出し追加ボタンを格納してるコンテナDOMのクラス名 */
const ADD_BALLOON_CONTAINER_CLASSNAME = 'add-balloon-container'

/**
 * メッセージエディタークラス
 */
export class MessageEditor extends BaseComponent {
    /** メッセージエディタ格納用のコンテナDOM */
    private _container: HTMLElement
    /** メッセージエディタ本体DOM */
    private _editor: HTMLElement
    /** メッセージオブジェクトDOMを格納するコンテナ要素 */
    private _messagesContainer: HTMLElement

    /** 読み取り専用モード */
    private _readOnly: boolean = false

    private _balloons: Balloon[] = []

    constructor(container: HTMLElement) {
        super()

        this._container = container
    }

    /**
     * メッセージエディタの初期化
     * 
     * @param messageJson メッセージJSONデータ
     */
    init(messageJson: string): void {
        // エディタDOMをクローン
        const editor = cloneNode(MESSAGE_EDITOR_TEMPLATE_ID)
        if (!editor) {
            return
        }
        // メッセージオブジェクトコンテナDOM取得
        const c = cn1(editor, MESSAGES_CONTAINER_CLASSNAME)
        if (!c) {
            return
        }
        // 吹き出し追加ボタンを取得
        const btn = cn1(editor, BUTTON_ADD_BALLOON_CLASSNAME)
        if (!btn) {
            return
        }

        if (editor.classList.contains('readonly')) {
            // エディターにreadonlyクラスが設定されていれば読み取り専用モード
            this._readOnly = true
        }

        this._editor = editor
        this._messagesContainer = c

        // 吹き出し追加ボタンを押した際の処理
        btn.addEventListener('click', () => {
            // メッセージオブジェクト追加
            this.addBalloon({altText: '', bubbles: [{blocks: [{type: ''}]}]})
            // 吹き出し追加ボタン表示更新
            this.updateBalloonButtons()
        })

        // メッセージJSONが指定されていれば、エディタに反映する
        if (messageJson) {
            this.applyFlexJson(JSON.parse(messageJson), editor)
        }

        // エディタ用コンテナに追加
        this._container.appendChild(editor)
        // エディタ表示
        show(editor)
    }

    /**
     * メッセージが1つでも作成されているかどうか
     * 
     * @returns メッセージが定義されていればtrue
     */
    hasBalloons(): boolean {
        // メッセージリスト用コンテナを取得
        const c = cn1(this._editor, MESSAGES_CONTAINER_CLASSNAME)
        if (!c) return false

        // コンテナ内の子要素が1以上の場合はOK
        return c.children.length > 0
    }

    /**
     * メッセージ定義のバリデーション
     * 
     * @returns エラーが無い場合は空文字、エラーがある場合はそのメッセージ
     */
    validateMessages(): string {
        if (this._balloons.length == 0) {
            return "吹き出しが作成されていません。"
        }
        for (const b of this._balloons) {
            const v = b.validateMessage()
            if (v) {
                return v
            }
        }
        return ""
    }

    /**
     * 作成したメッセージデータをJSON文字列に変換して取得
     * 
     * @returns 生成したメッセージデータのJSON文字列
     */
    buildFlexJson(): string {
        const messageObjects: MessageObject[] = []
        this._balloons.forEach(b => {
            messageObjects.push(b.buildFlexObject())
        })
        return JSON.stringify(messageObjects)
    }

    /**
     * メッセージエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     * @returns 
     */
    static getOrCreateInstance(containerElement: HTMLElement): MessageEditor {
        let obj: BaseComponent | null  = instances.get(containerElement)
        if (!obj) {
            obj = new MessageEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as MessageEditor
    }

    /**
     * メッセージ定義をエディタに反映
     * 
     * @param messages メッセージ定義
     */
    private applyFlexJson(messages: MessageObject[], editor: HTMLElement): void {
        // メッセージオブジェクトでループしてバルーンデータを作成
        messages.forEach(m => this.addBalloon(m))
        // 吹き出し追加ボタンの表示更新
        this.updateBalloonButtons()
        // コンテナ表示
        show(this._messagesContainer)
    }

    /**
     * メッセージバルーンを作成して追加
     * 
     * @param msg メッセージオブジェクトデータ
     */
    private addBalloon(msg: MessageObject): void {
        // バルーンデータ作成
        const balloon = new Balloon(msg.altText, this._readOnly)
        // バルーンの中に入れるバブルコンテナを作成
        const bubbles = msg.bubbles ?? []
        bubbles.forEach(c => balloon.addBubbleItem(c))

        // バルーンが上に移動するときの処理を登録
        balloon.setOnMoveUp(b => this.moveToUp(b))
        // バルーンが下に移動するときの処理を登録
        balloon.setOnMoveDown(b => this.moveToDown(b))
        // バルーンが削除されたときの処理を登録
        balloon.setOnRemoved(() => this.onBalloonRemoved(balloon))

        // 内部配列に保持
        this._balloons.push(balloon)
        // 画面に配置
        this._messagesContainer.appendChild(balloon.getElement())
    }

    /**
     * バルーンを1個上に移動
     * 
     * @param balloon 移動するバルーン
     */
    private moveToUp(balloon: Balloon) {
        // 現在のインデックスを取得
        const index = this._balloons.findIndex(t => t === balloon)
        // 移動先のインデックスを設定(一番上の場合はこのメソッドは実行されない想定)
        const movedIndex = index - 1

        // 移動先のバルーン情報を退避
        const movedBalloon = this._balloons[movedIndex]
        // 移動先インデックスに対象バルーンを設定
        this._balloons[movedIndex] = balloon
        // 移動元インデックスに、退避していたバルーン情報を設定
        this._balloons[index] = movedBalloon

        // DOM要素入れ替え
        this._messagesContainer.insertBefore(balloon.getElement(), movedBalloon.getElement())

        // ボタン表示更新
        this.updateBalloonButtons()
    }

    /**
     * バルーンを1個下に移動
     * 
     * @param balloon 移動するバルーン
     */
    private moveToDown(balloon: Balloon) {
        // 現在のインデックスを取得
        const index = this._balloons.findIndex(t => t === balloon)
        // 移動先のインデックスを設定(一番下の場合はこのメソッドは実行されない想定)
        const movedIndex = index + 1

        // 移動先のバルーン情報を退避
        const movedBalloon = this._balloons[movedIndex]
        // 移動先インデックスに対象バルーンを設定
        this._balloons[movedIndex] = balloon
        // 移動元インデックスに、退避していたバルーン情報を設定
        this._balloons[index] = movedBalloon

        // DOM要素入れ替え
        this._messagesContainer.removeChild(balloon.getElement())
        if (this._messagesContainer.children.length === (index + 1)) {
            // 下に移動しようとした要素が、もう最後に移動することになるのでappendChildで追加
            this._messagesContainer.appendChild(balloon.getElement())
        } else {
            // まだ下がある状態なのでinsertBefore()でいい感じに入れる
            // 追加しようとしてる位置の要素を取得
            const e = this._messagesContainer.children[index + 1]
            this._messagesContainer.insertBefore(balloon.getElement(), e)
        }

        // ボタン表示更新
        this.updateBalloonButtons()
    }

    /**
     * バルーンが削除された
     * 
     * @param balloon 削除されたバルーン
     */
    private onBalloonRemoved(balloon: Balloon) {
        // 内部配列から削除
        this._balloons = this._balloons.filter(t => t != balloon)
        // DOM要素からも取り除く
        this._messagesContainer.removeChild(balloon.getElement())

        // ボタン表示更新
        this.updateBalloonButtons()
    }

    /**
     * 吹き出し追加ボタン、各バルーンのボタン表示更新
     */
    private updateBalloonButtons(): void {
        const t = cn1(this._editor, ADD_BALLOON_CONTAINER_CLASSNAME)
        if (t) {
            if (this._balloons.length < 5) {
                // メッセージバルーン数が5個未満の場合は追加ボタン表示
                show(t)
            } else {
                // メッセージバルーン数が5個以上の場合はもう追加できないので非表示
                hide(t)
            }
        }

        if (this._balloons.length > 0) {
            // バルーンがあるので、空ですメッセージは非表示
            cn1(this._editor, EMPTY_MESSAGES_CLASSNAME)?.classList.add('d-none')
            if (this._balloons.length == 1) {
                // バルーン数が1個なので、上下無効
                this._balloons[0].setUpDownEnabled(false, false)
            } else {
                // バルーンが複数ある場合は、最初の1つは上ボタンは無効
                this._balloons[0].setUpDownEnabled(false, true)
                // 間のボタンは上下有効
                for (let x = 1; x < this._balloons.length - 1; x++) {
                    this._balloons[x].setUpDownEnabled(true, true)
                }
                // 最後の1つは上ボタンは有効、下ボタンは無効
                this._balloons.slice(-1)[0].setUpDownEnabled(true, false)
            }
        } else {
            // バルーンが無くなったので、空なんですメッセージ表示
            cn1(this._editor, EMPTY_MESSAGES_CLASSNAME)?.classList.remove('d-none')
        }
    }
}