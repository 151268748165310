import bootstrap from 'bootstrap'
import { cn1, ge, hide, show } from '../utils'
import { gpropInput } from './misc'
import { ACTION_TYPES, ActionType, actionTypeOf } from './messageObject'

/**
 * ボタン編集時の編集データ
 */
export interface ButtonEditorInfo {
    /** ボタンラベル */
    label: string
    /** タップ時アクションの種別 */
    actionType: ActionType
    /** ボタンの背景色 */
    color: string
    /** リンクURL */
    linkUrl: string
    /** LINEリンク */
    builtinLinkType: string
    /** 登録済みリンクのID */
    flexLinkId: string
    /** ポストバックアクションID */
    postbackId: string
    /** 発言キーワード */
    keyword: string
    /** アンケートID */
    formId: string
}

/** ボタン編集ダイアログのID */
const BUTTON_EDITOR_MODAL_ID = 'button-edit-modal'

/**
 * ボタン編集ダイアログ
 */
export class ButtonEditor {
    private _modal: bootstrap.Modal

    constructor(data: ButtonEditorInfo) {
        if (!ButtonEditor.editorElementInitialized) {
            ButtonEditor.editorElementInitialized = true
            ButtonEditor.setupButtonEditor()
        }

        this.clearErrorMsg()

        // ダイアログにボタンのデータを反映
        this.applyButtonInfo(data)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(ButtonEditor.editorElement)

        // ダイアログが閉じられたら破棄するように
        ButtonEditor.editorClosedHandler = () => {
            this._modal.dispose()
        }
    }

    /**
     * ボタン編集ダイアログを表示
     * 
     * @param confirmed 編集確定したときの処理
     */
    show(confirmed: (info: ButtonEditorInfo) => void): void {
        const it = this

        // ダイアログを決定した際の処理を登録しておく
        ButtonEditor.editorConfirmedHandler = () => {
            // 編集済みボタンデータ作成
            const info: ButtonEditorInfo = {
                label: this._gp('label').value,
                actionType: actionTypeOf(this._gp('action-type').value),
                color: this._gp('color').value,
                linkUrl: this._gp('link-url').value,
                builtinLinkType: this._gp('builtin-link').value,
                flexLinkId: this._gp('flex-link').value,
                postbackId: this._gp('postback').value,
                formId: this._gp('form').value,
                keyword: this._gp('keyword').value,
            }
            // 入力データの検証
            if (!it.validate(info)) {
                // 入力エラー
                return false
            }
            // ダイアログは閉じておく
            this._modal.hide()
            // 決定イベントハンドラをコール
            confirmed(info)
            // 反映OK
            return true
        }
        // ダイアログ表示
        this._modal.show()
    }

    private applyButtonInfo(info: ButtonEditorInfo) {
        // 画面の各項目に反映
        this._gp('label').value = info.label
        this._gp('action-type').value = info.actionType
        this._gp('link-url').value = info.linkUrl
        this._gp('builtin-link').value = info.builtinLinkType
        this._gp('flex-link').value = info.flexLinkId
        this._gp('postback').value = info.postbackId
        this._gp('form').value = info.formId
        this._gp('keyword').value = info.keyword
        this._gp('color').value = info.color
        // 選択に応じてアクションの表示を変更
        ButtonEditor.onActionTypeChanged(info.actionType)
    }

    /**
     * 入力値の検証
     */
    private validate(info: ButtonEditorInfo): boolean {
        if (info.actionType == ACTION_TYPES.LINK) {
            return this.validateLink(info)
        } else if (info.actionType == ACTION_TYPES.BUILTIN_LINK) {
            return true
        } else if (info.actionType == ACTION_TYPES.FLEX_LINK) {
            return this.validateFlexLink(info)
        } else if (info.actionType == ACTION_TYPES.POSTBACK) {
            return this.validatePostback(info)
        } else if (info.actionType == ACTION_TYPES.KEYWORD) {
            return this.validateKeyword(info)
        } else if (info.actionType == ACTION_TYPES.PROFILE) {
            return true
        } else if (info.actionType == ACTION_TYPES.FORM) {
            return this.validateForm(info)
        } else if (info.actionType == ACTION_TYPES.NONE) {
            return true
        } else {
            // 未定義アクション？？？
            return false
        }
    }

    private validateLink(info: ButtonEditorInfo): boolean {
        // リンクが入力済みであること
        if (info.linkUrl) {
            this._gp('link-url').classList.remove('is-invalid')
            return true
        } else {
            this._gp('link-url').classList.add('is-invalid')
            this.showErrorMsg('リンクURLを入力してください。')
            return false
        }
    }

    private validateFlexLink(info: ButtonEditorInfo): boolean {
        // 登録済みリンクが選択されていること
        if (info.flexLinkId) {
            this._gp('flex-link').classList.remove('is-invalid')
            return true
        } else {
            this._gp('flex-link').classList.add('is-invalid')
            this.showErrorMsg('登録済みリンクを選択してください。')
            return false
        }
    }

    private validatePostback(info: ButtonEditorInfo): boolean {
        // ポストバックアクションが選択されていること
        if (info.postbackId) {
            this._gp('postback').classList.remove('is-invalid')
            return true
        } else {
            this._gp('postback').classList.add('is-invalid')
            this.showErrorMsg('ポストバックアクションを選択してください。')
            return false
        }
    }

    private validateKeyword(info: ButtonEditorInfo): boolean {
        if (info.keyword) {
            this._gp('keyword').classList.remove('is-invalid')
            return true
        } else {
            this._gp('keyword').classList.add('is-invalid')
            this.showErrorMsg('発言してもらうキーワードを入力してください。')
            return false
        }
    }

    private validateForm(info: ButtonEditorInfo): boolean {
        // アンケートが選択されていること
        if (info.formId) {
            this._gp('form').classList.remove('is-invalid')
            return true
        } else {
            this._gp('form').classList.add('is-invalid')
            this.showErrorMsg('アンケートを選択してください。')
            return false
        }
    }

    private showErrorMsg(msg: string): void {
        const span = document.getElementById('edit-button-error-message')
        if (span) {
            span.innerText = msg
        }
        show(document.getElementById('edit-button-error-panel'))
    }

    private clearErrorMsg(): void {
        const span = document.getElementById('edit-button-error-message')
        if (span) {
            span.innerText = ''
        }
        hide(document.getElementById('edit-button-error-panel'))
    }

    /**
     * ボタン編集ダイアログ初期化
     */
    private static setupButtonEditor(): void {
        // 確定ボタンクリック時の処理
        cn1(ButtonEditor.editorElement, 'confirm-edit-button')?.addEventListener('click', () => {
            if (ButtonEditor.editorConfirmedHandler) {
                if (ButtonEditor.editorConfirmedHandler()) {
                    ButtonEditor.editorConfirmedHandler = undefined
                }
            }
        })
        // ダイアログが閉じられたときの処理
        ButtonEditor.editorElement.addEventListener('hidden.bs.modal', () => {
            if (ButtonEditor.editorClosedHandler) {
                ButtonEditor.editorClosedHandler()
                ButtonEditor.editorClosedHandler = undefined
            }
        })

        // アクションリストの選択が変更されたときの処理
        ge('edit-button-action-type')?.addEventListener('change', evt => this.onActionTypeChanged((evt.target as HTMLSelectElement).value))
    }

    private static onActionTypeChanged(type: string) {
        if (type === ACTION_TYPES.LINK) {
            this._hide(cn1(this.editorElement, 'action-type-builtin'))
            this._hide(cn1(this.editorElement, 'action-type-flex'))
            this._hide(cn1(this.editorElement, 'action-type-postback'))
            this._hide(cn1(this.editorElement, 'action-type-keyword'))
            this._hide(cn1(this.editorElement, 'action-type-form'))
            this._show(cn1(this.editorElement, 'action-type-link'))
        } else if (type === ACTION_TYPES.BUILTIN_LINK) {
            this._hide(cn1(this.editorElement, 'action-type-link'))
            this._hide(cn1(this.editorElement, 'action-type-flex'))
            this._hide(cn1(this.editorElement, 'action-type-postback'))
            this._hide(cn1(this.editorElement, 'action-type-keyword'))
            this._hide(cn1(this.editorElement, 'action-type-form'))
            this._show(cn1(this.editorElement, 'action-type-builtin'))
        } else if (type === ACTION_TYPES.FLEX_LINK) {
            this._hide(cn1(this.editorElement, 'action-type-link'))
            this._hide(cn1(this.editorElement, 'action-type-builtin'))
            this._hide(cn1(this.editorElement, 'action-type-postback'))
            this._hide(cn1(this.editorElement, 'action-type-keyword'))
            this._hide(cn1(this.editorElement, 'action-type-form'))
            this._show(cn1(this.editorElement, 'action-type-flex'))
        } else if (type === ACTION_TYPES.POSTBACK) {
            this._hide(cn1(this.editorElement, 'action-type-link'))
            this._hide(cn1(this.editorElement, 'action-type-flex'))
            this._hide(cn1(this.editorElement, 'action-type-builtin'))
            this._hide(cn1(this.editorElement, 'action-type-keyword'))
            this._hide(cn1(this.editorElement, 'action-type-form'))
            this._show(cn1(this.editorElement, 'action-type-postback'))
        } else if (type === ACTION_TYPES.KEYWORD) {
            this._hide(cn1(this.editorElement, 'action-type-link'))
            this._hide(cn1(this.editorElement, 'action-type-flex'))
            this._hide(cn1(this.editorElement, 'action-type-builtin'))
            this._hide(cn1(this.editorElement, 'action-type-postback'))
            this._hide(cn1(this.editorElement, 'action-type-form'))
            this._show(cn1(this.editorElement, 'action-type-keyword'))
        } else if (type === ACTION_TYPES.FORM) {
            this._hide(cn1(this.editorElement, 'action-type-link'))
            this._hide(cn1(this.editorElement, 'action-type-flex'))
            this._hide(cn1(this.editorElement, 'action-type-builtin'))
            this._hide(cn1(this.editorElement, 'action-type-postback'))
            this._hide(cn1(this.editorElement, 'action-type-keyword'))
            this._show(cn1(this.editorElement, 'action-type-form'))
        } else {
            // 上記以外は特に表示するものはない
            this._hide(cn1(this.editorElement, 'action-type-link'))
            this._hide(cn1(this.editorElement, 'action-type-flex'))
            this._hide(cn1(this.editorElement, 'action-type-builtin'))
            this._hide(cn1(this.editorElement, 'action-type-postback'))
            this._hide(cn1(this.editorElement, 'action-type-keyword'))
            this._hide(cn1(this.editorElement, 'action-type-form'))
        }
    }

    private _gp(name: string): HTMLInputElement {
        return gpropInput(ButtonEditor.editorElement, name)
    }

    private static _show(e: HTMLElement | null): void {
        if (e) {
            show(e)
        }
    }

    private static _hide(e: HTMLElement | null): void {
        if (e) {
            hide(e)
        }
    }

    private static editorConfirmedHandler?: () => boolean

    private static editorClosedHandler?: () => void

    /** ボタン編集ダイアログDOM */
    private static editorElement: HTMLElement = document.getElementById(BUTTON_EDITOR_MODAL_ID)!!

    /** ボタン編集ダイアログ初期化済みか */
    private static editorElementInitialized: boolean = false
}
