import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { BirthdaySelectOptions, BirthdaySelector } from "./BirthdaySelector";
import { BirthdayCondition, BirthdayGroup } from "./ConditionObject";

/** 誕生日条件表示パネルのテンプレートのID */
const PANEL_BIRTHDAY_TEMPLATE_ID = 'birthday-condition-template'
const PANEL_BIRTHDAY_ITEM_TEMPLATE_ID = 'birthday-item-template'

/**
 * 誕生日条件パネル
 */
export class BirthdayConditionPanel extends TypedBaseCondition<BirthdayGroup> {
    private _birthdaysList: HTMLElement

    constructor(readOnly: boolean) {
        super(readOnly)

        const p = this.createBirthdaysPanel()
        this._body.appendChild(p)

        const birthdayList = cn1(p, 'condition-birthdays')
        if (birthdayList) {
            this._birthdaysList = birthdayList
        }

        show(p)
    }

    /**
     * 友だち誕生日条件の内容を画面に反映
     * 
     * @param condition 友だち誕生日条件
     */
    apply(condition: BirthdayGroup): void {
        this._condition = condition

        // 一旦クリア
        this._birthdaysList.innerHTML = ''

        // 各条件を反映
        condition.conditions.forEach(b => this.applyBirthday(b))
    }

    /**
     * 条件タイプ
     */
    getType(): string {
        return 'birthday'
    }

    /**
     * 名称取得
     */
    getName(): string {
        return '誕生日'
    }

    /**
     * 設定ダイアログ表示
     */
    onEdit(): void {
        const opts: BirthdaySelectOptions = {
            birthdays: this._condition,
            confirmed: (b) => {
                this.apply(b)
                this.raiseOnEditConfirmed()
            },
            cancelled: () => {}
        }
        new BirthdaySelector(opts).show()
    }

    private applyBirthday(birthday: BirthdayCondition): void {
        const p = this.createBirthdayConditionPanel()

        const text = birthday.months.map(b => `${b}月`).join(", ")
        p.innerText = text

        this._birthdaysList.appendChild(p)

        show(p)
    }

    private createBirthdaysPanel(): HTMLElement {
        const p = cloneNode(PANEL_BIRTHDAY_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find birthdays viewer panel.'
        }

        return p
    }

    private createBirthdayConditionPanel(): HTMLElement {
        const p = cloneNode(PANEL_BIRTHDAY_ITEM_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find birthday condition viewer panel.'
        }

        return p
    }
}