import * as bootstrap from 'bootstrap'
import { cloneNode, cn1, hide, show, uuid } from '../utils'
import { BirthdayCondition, BirthdayGroup } from './ConditionObject'

const BIRTHDAY_SELECTOR_MODAL_ID = 'select-birthday-modal'

export interface BirthdaySelectOptions {
    birthdays: BirthdayGroup
    confirmed?: (birthdays: BirthdayGroup) => void
    cancelled?: () => void
}

/**
 * 誕生日条件選択ダイアログ
 */
export class BirthdaySelector {
    private _element: HTMLElement
    private _body: HTMLElement
    private _modal: bootstrap.Modal

    private _options: BirthdaySelectOptions
    private _rules: BirthdayRule[] = []

    private _confirmed: boolean = false

    private _onConfirmBind
    private _onModalClosedBind

    constructor(options: BirthdaySelectOptions) {
        this._element = this.createDialogElement()
        this._options = options

        const body = this._element.getElementsByClassName('birthdays-list')
        if (!body || body.length == 0) {
            throw 'Cannot find birthday condition body.'
        }
        this._body = body[0] as HTMLElement

        // 現在の選択をダイアログに反映
        if (!options.birthdays.conditions || options.birthdays.conditions.length == 0) {
            options.birthdays.conditions = [{
                months: []
            }]
        }
        this.apply(options.birthdays)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(this._element)
    }

    show(): void {
        this._modal.show()
    }

    /**
     * 設定内容を画面に反映
     */
    private apply(birthdays: BirthdayGroup): void {
        // 一旦クリア
        this.clear()

        for (const r of birthdays.conditions) {
            this.applyBirthday(r)
        }
    }

    /**
     * 誕生日設定を画面に反映
     */
    private applyBirthday(bd: BirthdayCondition): void {
        const rule = new BirthdayRule(bd)

        this._rules.push(rule)
        this._body.appendChild(rule.getPanel())
    }

    /**
     * 設定内容クリア
     */
    private clear(): void {
        this._rules = []
        this._body.innerHTML = ''
    }

    /**
     * 誕生日条件データを作成
     */
    private buildBirthdayCondition(): BirthdayGroup {
        return {
            conditions: this._rules.map(t => t.buildConditionObject())
        }
    }

    /**
     * 確定ボタンを押したときの処理
     */
    private onModalConfirm(): void {
        if (!this.checkDateInputs()) {
            return
        }
        // 登録日条件設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    /**
     * 条件の入力をチェック
     */
    private checkDateInputs(): boolean {
        let checked = true
        for (const t of this._rules) {
            if (!t.checkDataInputs()) {
                checked = false
            }
        }
        return checked
    }

    /**
     * ダイアログが閉じられるときの処理
     */
    private onModalClosed(): void {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                // 誕生日条件情報を作成
                const t = this.buildBirthdayCondition()
                this._options.confirmed(t)
            }
        } else {
            // キャンセルしたのでキャンセル時のハンドラを実行
            if (this._options.cancelled) {
                this._options.cancelled()
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    private createDialogElement(): HTMLElement {
        const p = document.getElementById(BIRTHDAY_SELECTOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find birthday selector modal'
        }

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(p, 'btn-birthday-select-confirm')?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private dispose() {
        cn1(this._element, 'btn-birthday-select-confirm')?.removeEventListener('click', this._onConfirmBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}

class BirthdayRule {
    private _id: string
    private _panel: HTMLElement

    constructor(condition: BirthdayCondition) {
        this._id = uuid()
        this._panel = this.createPanel()

        this.apply(condition)
    }

    getPanel(): HTMLElement {
        return this._panel
    }

    /**
     * 何らかの誕生月が選択されているかチェック
     */
    checkDataInputs(): boolean {
        const checks = this._panel.querySelectorAll('div.birthdays input[type="checkbox"]')
        let checked = false
        for (const c of checks) {
            if ((c as HTMLInputElement).checked) {
                checked = true
                break
            }
        }

        const m = cn1(this._panel, 'alert')
        if (checked) {
            if (m) { hide(m) }
        } else {
            if (m) { show(m) }
        }

        return checked
    }

    /**
     * 誕生日条件情報生成
     */
    buildConditionObject(): BirthdayCondition {
        // チェックされた誕生月を保持しておく配列
        const checkedMonths: number[] = []
        // チェックボックス取得
        const checks = this._panel.querySelectorAll('div.birthdays input[type="checkbox"]')
        for (const c of checks) {
            if (c instanceof HTMLInputElement && c.checked) {
                // チェックONなので月を保持
                checkedMonths.push(Number(c.value))
            }
        }

        return {
            months: checkedMonths
        }
    }

    private apply(c: BirthdayCondition): void {
        c.months.forEach(m => {
            const e = this._panel.querySelector(`input[value="${m}"]`) as HTMLInputElement
            if (e) {
                e.checked = true
            }
        })
    }

    private createPanel(): HTMLElement {
        const p = cloneNode('birthday-rule-template')
        if (!p) {
            throw 'Cannot create birthday rule container'
        }

        const monthChecks = p.getElementsByClassName('birthday-item')
        for (const m of monthChecks) {
            const input = m.getElementsByTagName('input')[0]
            const label = m.getElementsByTagName('label')[0]
            input.id = `birthday-${this._id}-${input.value}`
            label.htmlFor = input.id
        }

        show(p)

        return p
    }
}