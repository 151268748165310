import { Modal } from "bootstrap"
import { cn1, convertDataUri2Blob, createDefaultQrOptions, generateQr, saveAs } from "../utils"
import { Options } from 'qr-code-styling'
import { CellOptions } from "./CellOptions"
import { CornerSquareOptions } from "./CornerSquareOptions"
import { CornerDotOptions } from "./CornerDotOptions"
import { BackgroundOptions } from "./BackgroundOptions"
import { IconOptions } from "./IconOptions"

/**
 * QRコードデコレーション
 */
export class QRDecorator {
    private _image: HTMLImageElement
    private _url: string
    private _style: Options
    private _modal: Modal

    private _onDownloadBind

    constructor(dialog: HTMLElement) {
        this.setup(dialog)

        // まずはデフォルトのオプションを生成しておく
        this._style = createDefaultQrOptions()

        // セルのオプション
        new CellOptions(this._style).setOnOptionUpdated(() => this.createQrImage())
        // 角の枠線のオプション
        new CornerSquareOptions(this._style).setOnOptionUpdated(() => this.createQrImage())
        // 角の資格のオプション
        new CornerDotOptions(this._style).setOnOptionUpdated(() => this.createQrImage())
        // 背景オプション
        new BackgroundOptions(this._style).setOnOptionUpdated(() => this.createQrImage())
        // ロゴ画像のぷしょん
        new IconOptions(this._style).setOnOptionUpdated(() => this.createQrImage())

        // Modal生成
        this._modal = Modal.getOrCreateInstance(dialog)

        // 最初の画像を生成
        this.createQrImage()
    }

    show(): void {
        this._modal.show()
    }

    /**
     * QRコード画像生成
     */
    private async createQrImage(): Promise<void> {
        this._image.src = await generateQr(this._url, this._style)
    }

    /**
     * ダウンロードボタンクリック時
     */
    private async onDownload(): Promise<void> {
        const blob = await convertDataUri2Blob(this._image.src)
        saveAs(blob, 'qr.png')
    }

    /**
     * セットアップ
     */
    private setup(dialog: HTMLElement): void {
        // ダウンロードボタン
        this._onDownloadBind = this.onDownload.bind(this)
        cn1(dialog, 'button-qr-download')?.addEventListener('click', this._onDownloadBind)

        // デコったQRコードを表示するIMG
        const img = dialog.querySelector('img.decorated-qr-code') as HTMLImageElement
        if (img) {
            this._image = img
            this._url = img.getAttribute('data-url') ?? ''
        }
    }
}

let instance: QRDecorator | null = null

/**
 * QRコードデコレーションモーダルを取得
 * 
 * @param id デコレーションダイアログDOMのID
 */
export function showQRCodeDecorator(id: string): void {
    const dialog = document.getElementById(id) as HTMLElement
    if (!dialog) {
        return
    }

    if (!instance) {
        instance = new QRDecorator(dialog)
    }
    instance?.show()
}