import { cloneNode, show } from "../utils"
import { ImageSelector, ImageSelectorInfo } from "./ImageSelector"
import { BlockItem } from "./messageObject"

/** 画像DOMテンプレートのID */
const IMAGE_TEMPLATE_ID = 'image-template'

/**
 * 画像
 */
export class Image {
    private _parentElement: HTMLElement
    private _imageElement: HTMLImageElement
    private _onImageChagned: () => void

    private _imageInfo: ImageSelectorInfo

    constructor(parentBlockElement: HTMLElement) {
        // 画像格納用の親ブロックDOMを保持
        this._parentElement = parentBlockElement
    }

    /**
     * 画像としてブロックデータを設定
     */
    apply(data: BlockItem): void {
        // 画像データを作成
        this._imageInfo = {
            url: data.imageUrl ?? ''
        }
        // 画面に反映
        this.applyImageInfo(this._imageInfo)
    }

    /**
     * 画像が設定・変更されたときの処理を登録
     */
    setOnImageChanged(f: () => void): void {
        this._onImageChagned = f
    }

    /**
     * 新しい画像を追加
     */
    addNewImage(): void {
        this.showEditorInternal({url: ''})
    }

    /**
     * 保存用JSONデータ作成
     */
    buildFlexObject(): BlockItem {
        return {
            type: 'image',
            imageUrl: this._imageInfo.url,
        }
    }

    private showEditorInternal(info: ImageSelectorInfo): void {
        const selector = new ImageSelector(info)
        selector.show(selected => this.onImageSelected(selected))
    }

    private onImageSelected(selected: ImageSelectorInfo): void {
        // 選択内容を保持
        this._imageInfo = selected

        // 編集内容を画面に反映
        this.applyImageInfo(selected)

        // 画像の変更を通知
        this._onImageChagned()
    }

    private applyImageInfo(info: ImageSelectorInfo): void {
        if (!this._imageElement) {
            // 画像用DOMがまだ未作成なので、作成する
            this.createImageElement()
        }

        const img = this._imageElement
        if (!img) {
            return
        }

        // 選択した画像を表示
        img.src = info.url
    }

    private createImageElement() {
        const t = cloneNode(IMAGE_TEMPLATE_ID)
        if (!t) {
            throw 'Cannot create image element.'
        }

        // 画像がクリックされたときの処理を登録
        t.addEventListener('click', () => this.showEditorInternal({url: this._imageInfo.url}))

        // 画像要素を保持
        this._imageElement = t as HTMLImageElement
        // 親ブロックに追加
        this._parentElement.appendChild(this._imageElement)

        // 画像を表示
        show(this._imageElement)
    }
}