/**
 * インスタンス管理
 */

import { BaseComponent } from "./BaseComponent"

const instances: Map<HTMLElement, BaseComponent> = new Map()

export default {
    /**
     * 指定コンテナに格納したAL-Baseコンポーネント取得
     * 
     * @param element コンテナ要素
     * @returns 
     */
    get(element: HTMLElement): BaseComponent | null {
        if (instances.has(element)) {
            const x = instances.get(element)
            if (x) {
                return x
            } else {
                return null
            }
        } else {
            return null
        }
    },

    /**
     * コンテナにコンポーネントを紐づけ
     * 
     * @param element コンテナ要素
     * @param obj 格納したコンポーネント
     */
    set(element: HTMLElement, obj: BaseComponent): void {
        instances.set(element, obj)
    }
}
