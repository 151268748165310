import { cloneNode, cn1, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { RegisteredCondition } from "./ConditionObject";
import { DateSelector } from "./DateSelector";

/**
 * 友だち登録日条件設定パネル
 */
export class RegisterDateConditionPanel extends TypedBaseCondition<RegisteredCondition> {
    private _element: HTMLElement

    private _condition: RegisteredCondition

    constructor() {
        super()

        this._element = this.createConditionElement()
    }

    /**
     * 友だち登録日条件情報を画面に反映
     */
    apply(date: RegisteredCondition): void {
        const x = this._element

        this.applyRegistered(date)

        this._body.appendChild(x)
        this._condition = date

        show(x)
    }

    /**
     * 配信グループの友だち登録日条件データ作成
     */
    buildConditionJson(): RegisteredCondition {
        return this._condition
    }

    getName(): string {
        return '友だち登録日'
    }

    onEdit(): void {
        new DateSelector(this._condition).show(date => {
            this.apply(date)
            this.raiseOnEditConfirmed()
        })
    }

    /**
     * 各条件日付を画面に設定
     */
    private applyRegistered(date: RegisteredCondition): void {
        const t = cn1(this._element, 'data-registered-date')
        if (!t) {
            return
        }

        let text = ''
        if (date.start) {
            text = date.start + ' ～ '
        } else {
            text = '(未指定) ～ '
        }
        if (date.end) {
            text = text + date.end
        } else {
            text = text + '(未指定)'
        }

        t.innerText = text
    }

    /**
     * 友だち登録日条件設定パネル生成
     */
    private createConditionElement(): HTMLElement {
        const x = cloneNode('condition-registered')
        if (!x) {
            throw 'Cannot create route condition element.'
        }

        return x
    }
}