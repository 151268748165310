import { BaseComponent } from "../common/BaseComponent"
import { cloneNode, show } from "../utils"
import { SpanItem } from "./messageObject"

const SPAN_TEMPLATE_ID = 'text-block-template'

/**
 * 文字列SPAN
 */
export class Span extends BaseComponent {
    private id: string

    private text: string
    private size: string
    private color: string

    constructor() {
        super()

        this.id = 'span-' + crypto.randomUUID()
        this.setElement(this.createSpan())

        show(this.getElement())
    }

    /**
     * SPANデータを画面に反映
     * 
     * @param data SPANデータ
     */
    applySpan(data: SpanItem) {
        // SPANデータを内部に保持
        this.text = data.text ?? ''
        this.size = data.size
        this.color = data.color

        // テキストが設定されているので、内容を反映
        this.applySpanElement()
    }

    /**
     * SPAN IDを取得
     */
    getId(): string {
        return this.id
    }

    /**
     * SPANのテキストを取得
     */
    getText(): string {
        return this.text;
    }

    /**
     * SPANの文字サイズを取得
     */
    getSize(): string {
        return this.size
    }

    /**
     * SPANの文字色を取得
     */
    getColor(): string {
        return this.color
    }

    /**
     * 保存用JSONデータ作成
     */
    buildFlexObject(): SpanItem {
        return {
            text: this.text,
            color: this.color,
            size: this.size
        }
    }

    /**
     * SPANデータのHTML要素を作成
     */
    private createSpan(): HTMLElement {
        // SPAN要素作成
        const t = cloneNode(SPAN_TEMPLATE_ID)
        if (!t) {
            // SPAN要素が作成できない場合は例外にする
            throw 'Cannot create SPAN element.'
        }

        return t
    }

    private applySpanElement() {
        const elem = this.getElement()
        elem.setAttribute('data-prop-size', this.size)
        elem.style.color = this.color
        elem.innerText = this.text
    }
}
