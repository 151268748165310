import * as bootstrap from 'bootstrap'
import { FollowDateCondition, FollowDateGroup } from "./ConditionObject";
import { cloneNode, cn1, hide, show, uuid } from '../utils';
import { DateInput } from '../forms/date';

const FOLLOW_SELECTOR_MODAL_ID = 'select-follow-date-modal'

export interface FollowDateSelectOptions {
    follows: FollowDateGroup
    confirmed?: (tag: FollowDateGroup) => void
    cancelled?: () => void
}

/**
 * 友だち登録日条件設定ダイアログ
 */
export class FollowDateSelector {
    private _element: HTMLElement
    private _modal: bootstrap.Modal
    private _datePanelList: HTMLElement
    private _rangePanelList: HTMLElement

    private _dateRules: FollowDateRule[] = []
    private _rangeRules: FollowRangeRule[] = []
    private _options: FollowDateSelectOptions

    private _confirmed: boolean = false

    private _onConfirmBind
    private _onModalClosedBind
    private _addNewDateBind
    private _addNewRangeBind

    constructor(options: FollowDateSelectOptions) {
        this._element = this.createDialogElement()
        this._options = options

        // 現在の選択をダイアログに反映
        if (!options.follows.conditions || options.follows.conditions.length == 0) {
            options.follows.mode = 'date'
            options.follows.conditions = [{}]
        }
        this.apply(options.follows)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(this._element)
    }

    /**
     * 登録日設定ダイアログを表示
     */
    show(): void {
        this._modal.show()
    }

    private apply(follows: FollowDateGroup): void {
        // 一旦クリア
        this.clear()

        if (follows.mode == 'date') {
            // 日にち条件での設定
            this.applyByDate(follows)
        } else if (follows.mode == 'range') {
            // 日数条件での設定
            this.applyByRange(follows)
        }
    }

    /**
     * 日にち条件を画面に反映
     */
    private applyByDate(follows: FollowDateGroup): void {
        // 日にち条件をアクティブに
        const b = this._element.querySelector('button[data-mode="date"]') as HTMLButtonElement
        b.click()

        // 各日にち条件リストを反映
        follows.conditions.forEach(t => this.applyDateRule(t))

        // 日数条件に切り替えたとき用に空の日数条件を作成しておく
        this.applyRangeRule({})

        // 各条件の削除ボタン表示更新
        this.updateDeleteButtons()
    }

    /**
     * 日にち条件を画面に反映
     */
    private applyDateRule(c: FollowDateCondition): void {
        const p = new FollowDateRule(c)

        p.setOnRemoved(() => {
            this._datePanelList.removeChild(p.getPanel())
            this._dateRules = this._dateRules.filter(t => t !== p)
            this.updateDeleteButtons()
        })

        this._datePanelList.appendChild(p.getPanel())
        this._dateRules.push(p)
    }

    /**
     * 日数条件を画面に反映
     */
    private applyByRange(follows: FollowDateGroup): void {
        // 日数条件をアクティブに
        const b = this._element.querySelector('button[data-mode="range"]') as HTMLButtonElement
        b.click()

        // 各日数条件リストを反映
        follows.conditions.forEach(t => this.applyRangeRule(t))

        // 日にち条件に切り替えたとき用に空の日にち条件を作成しておく
        this.applyDateRule({})

        // 各条件の削除ボタン表示更新
        this.updateDeleteButtons()
    }

    /**
     * 日数条件を画面に反映
     */
    private applyRangeRule(c: FollowDateCondition): void {
        const p = new FollowRangeRule(c)

        p.setOnRemoved(() => {
            this._rangePanelList.removeChild(p.getPanel())
            this._rangeRules = this._rangeRules.filter(t => t !== p)
            this.updateDeleteButtons()
        })

        this._rangePanelList.appendChild(p.getPanel())
        this._rangeRules.push(p)
    }

    private updateDeleteButtons() {
        // それぞれ1個よりも多ければ削除可能
        const canDateDelete = this._dateRules.length > 1
        const canRangeDelete = this._rangeRules.length > 1

        this._dateRules.forEach(t => t.setDeleteEnable(canDateDelete))
        this._rangeRules.forEach(t => t.setDeleteEnable(canRangeDelete))
    }

    /**
     * 設定内容クリア
     */
    private clear(): void {
        this._datePanelList.innerHTML = ''
        this._rangePanelList.innerHTML = ''
        this._dateRules = []
        this._rangeRules = []
        this.setMessage('')
    }

    /**
     * 新規日にち条件を追加
     */
    private onAddNewDate(): void {
        this.applyDateRule({})
        this.updateDeleteButtons()
    }

    /**
     * 新規日数条件を追加
     */
    private onAddNewRange(): void {
        this.applyRangeRule({})
        this.updateDeleteButtons()
    }

    /**
     * 確定ボタンを押したときの処理
     */
    private onModalConfirm(): void {
        if (!this.checkDateInputs()) {
            return
        }
        // 登録日条件設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    /**
     * 条件の入力をチェック
     */
    private checkDateInputs(): boolean {
        const mode = this.getSelectedMode()
        if (!mode) {
            return false
        }

        if (mode === 'date') {
            return this.checkFollowDateInputs()
        } else if (mode === 'range') {
            return this.checkFollowRangeInputs()
        } else {
            return false
        }
    }

    /**
     * 日にち条件の入力をチェック
     */
    private checkFollowDateInputs(): boolean {
        let message: string = ''

        if (this._dateRules.length == 0) {
            // 日付条件が作成されていない
            message = '日にち条件を作成してください。'
        } else {
            this._dateRules.forEach(r => {
                if (!r.checkDateInputs()) {
                    message = '入力内容を確認してください。'
                }
            })
        }

        // エラーメッセージ表示
        this.setMessage(message)

        // エラーメッセージが空欄ならOK
        return message.length == 0
    }

    /**
     * 日数条件の入力をチェック
     */
    private checkFollowRangeInputs(): boolean {
        let message: string = ''

        if (this._rangeRules.length == 0) {
            // 日数条件が作成されていない
            message = '日数条件を作成してください。'
        } else {
            this._rangeRules.forEach(r => {
                if (!r.checkRangeInputs()) {
                    message = '入力内容を確認してください。'
                }
            })
        }

        // エラーメッセージ表示
        this.setMessage(message)

        // エラーメッセージが空欄ならOK
        return message.length == 0
    }

    /**
     * エラーメッセージの表示 
     */
    private setMessage(msg: string): void {
        const alert = this._element.querySelector('div.alert')
        if (!(alert instanceof HTMLElement)) {
            return
        }

        const span = alert.querySelector('span.alert-message')
        if (span instanceof HTMLElement) {
            if (msg) {
                span.innerText = msg
                show(alert)
            } else {
                span.innerText = ''
                hide(alert)
            }
        }
    }

    /**
     * 友だち登録日条件データを作成
     */
    private buildFollowDateCondition(): FollowDateGroup {
        const mode = this.getSelectedMode()

        let conditions: FollowDateCondition[]
        if (mode === 'date') {
            conditions = this._dateRules.map(t => t.buildCondition())
        } else if (mode === 'range') {
            conditions = this._rangeRules.map(t => t.buildCondition())
        } else {
            throw 'Invalid follow date choice mode'
        }

        return {
            mode: mode,
            conditions: conditions
        }
    }

    /**
     * ダイアログが閉じられるときの処理
     */
    private onModalClosed(): void {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                // 登録日条件を生成
                const t = this.buildFollowDateCondition()
                this._options.confirmed(t)
            }
        } else {
            // キャンセルしたのでキャンセル時のハンドラを実行
            if (this._options.cancelled) {
                this._options.cancelled()
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    /**
     * 日にち条件、日数条件のどちらを選択しているか
     */
    private getSelectedMode(): string {
        const activeBtn = this._element.querySelector('button.nav-link.active')
        if (!activeBtn) {
            return ''
        }

        return activeBtn.getAttribute('data-mode') ?? ''
    }

    private createDialogElement(): HTMLElement {
        const p = document.getElementById(FOLLOW_SELECTOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find follow date selector modal'
        }

        // 日にち条件リストパネルの初期化
        this.setupDates(p)
        // 日数条件リストパネルの初期化
        this.setupRanges(p)

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(p, 'btn-follow-select-confirm')?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private setupDates(p: HTMLElement): void {
        // 条件リストパネルを取得
        const s = cn1(p, 'follow-date-list')
        if (!s) {
            throw 'Cannot find follow date list element.'
        }
        this._datePanelList = s

        // 条件を追加ボタン
        this._addNewDateBind = this.onAddNewDate.bind(this)
        const addBtn = cn1(p, 'add-date-rule')
        if (addBtn) {
            addBtn.addEventListener('click', this._addNewDateBind)
        }
    }

    private setupRanges(p: HTMLElement): void {
        // 条件リストパネルを取得
        const s = cn1(p, 'follow-range-list')
        if (!s) {
            throw 'Cannot find follow range list element.'
        }
        this._rangePanelList = s

        // 条件を追加ボタン
        this._addNewRangeBind = this.onAddNewRange.bind(this)
        const addBtn = cn1(p, 'add-range-rule')
        if (addBtn) {
            addBtn.addEventListener('click', this._addNewRangeBind)
        }
    }

    private dispose() {
        cn1(this._element, 'btn-follow-select-confirm')?.removeEventListener('click', this._onConfirmBind)
        cn1(this._element, 'add-date-rule')?.removeEventListener('click', this._addNewDateBind)
        cn1(this._element, 'add-range-rule')?.removeEventListener('click', this._addNewRangeBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}

/**
 * 日にち条件
 */
class FollowDateRule {
    private _panel: HTMLElement
    private _id: string

    private _startInput: DateInput
    private _endInput: DateInput

    private _onDateRemoved: () => void = this.defaultOnRemove

    constructor(condition: FollowDateCondition) {
        this._id = uuid()
        this._panel = this.createPanelElement()

        this.apply(condition)
    }

    getPanel(): HTMLElement {
        return this._panel
    }

    buildCondition(): FollowDateCondition {
        const form = this._panel.getElementsByTagName('form')[0]

        const startElem = form[`start-date-${form.id}`]
        const endElem = form[`end-date-${form.id}`]

        const r: FollowDateCondition = {}
        if (startElem.value.length > 0) {
            r.start = startElem.value
        }
        if (endElem.value.length > 0) {
            r.end = endElem.value
        }

        return r
    }

    checkDateInputs(): boolean {
        const form = this._panel.getElementsByTagName('form')[0]

        const startElem = form[`start-date-${form.id}`]
        const endElem = form[`end-date-${form.id}`]

        const start = startElem.value
        const end = endElem.value
        
        if (!start && !end) {
            // 両方入力されてないのはダメ
            startElem.classList.add('is-invalid')
            endElem.classList.add('is-invalid')
            return false
        }

        // 少なくともどちらかが入力されているのでOK
        startElem.classList.remove('is-invalid')
        endElem.classList.remove('is-invalid')
        return true
    }

    setDeleteEnable(b: boolean): void {
        const form = this._panel.getElementsByTagName('form')[0]
        const deleteBtn = form.getElementsByClassName('delete-date')[0]
        if (deleteBtn instanceof HTMLButtonElement) {
            if (b) {
                // 削除ボタン有効
                deleteBtn.classList.remove('d-none')
            } else {
                // 削除ボタン無効
                deleteBtn.classList.add('d-none')
            }
            deleteBtn.disabled = !b
        }
    }

    setOnRemoved(f: () => void): void {
        this._onDateRemoved = f
    }

    private apply(c: FollowDateCondition): void {
        this._startInput.setDate(c.start)
        this._endInput.setDate(c.end)
    }

    private deleteDate(): void {
        this._onDateRemoved()
    }

    private createPanelElement(): HTMLElement {
        const p = cloneNode('follow-date-template')
        if (!p) {
            throw 'Cannot find follow date condition element.'
        }

        const form = p.getElementsByTagName('form')[0]
        form.id = this._id

        // 開始日のname属性を更新
        const startInput = form.getElementsByClassName('start-date')[0] as HTMLInputElement
        if (startInput) {
            startInput.setAttribute('name', `start-date-${this._id}`)
            this._startInput = new DateInput(startInput)
        }
        // 終了日のname属性を更新
        const endInput = form.getElementsByClassName('end-date')[0] as HTMLInputElement
        if (endInput) {
            endInput.setAttribute('name', `end-date-${this._id}`)
            this._endInput = new DateInput(endInput)
        }

        // 日付削除ボタン
        const deleteBtn = form.getElementsByClassName('delete-date')[0]
        if (deleteBtn) {
            deleteBtn.addEventListener('click', () => this.deleteDate())
        }

        show(p)

        return p
    }

    private defaultOnRemove(): void {
    }
}

/**
 * 日数条件
 */
class FollowRangeRule {
    private _panel: HTMLElement
    private _id: string

    private _form: HTMLFormElement

    private _onDateRemoved: () => void = this.defaultOnRemove

    constructor(condition: FollowDateCondition) {
        this._id = uuid()
        this._panel = this.createPanelElement()
        this.apply(condition)
    }

    getPanel(): HTMLElement {
        return this._panel
    }

    buildCondition(): FollowDateCondition {
        const id = this._id

        const r: FollowDateCondition = {}

        const startDays = this._form[`start-days-${id}`].value
        const startType = this._form[`start-type-${id}`].value
        if (startType == 'days') {
            // 日数指定
            r.rangeStart = startDays
        } else if (startType == 'today') {
            // 当日は0で設定
            r.rangeStart = 0
        } else {
            // 未指定ってことでnullに更新
            r.rangeStart = null
        }
        
        const endDays = this._form[`end-days-${id}`].value
        const endType = this._form[`end-type-${id}`].value
        if (endType == 'days') {
            r.rangeEnd = endDays
        } else if (endType == 'today') {
            // 当日は0で設定
            r.rangeEnd = 0
        }

        return r
    }

    checkRangeInputs(): boolean {
        let result: boolean = true
        const id = this._id

        const startDaysInput = this._form[`start-days-${id}`]
        startDaysInput.classList.remove('is-invalid')

        const startType = this._form[`start-type-${id}`].value
        if (startType == 'days') {
            // n日前の場合は日数が入力されていること
            if (!startDaysInput.value || startDaysInput.value < 1) {
                startDaysInput.classList.add('is-invalid')
                result = false
            }
        }

        const endDaysInput = this._form[`end-days-${id}`]
        endDaysInput.classList.remove('is-invalid')

        const endType = this._form[`end-type-${id}`].value
        if (endType == 'days') {
            // n日前の場合は日数が入力されていること
            if (!endDaysInput.value || endDaysInput.value < 1) {
                endDaysInput.classList.add('is-invalid')
                result = false
            }
        }

        return result
    }

    setDeleteEnable(b: boolean): void {
        const form = this._panel.getElementsByTagName('form')[0]
        const deleteBtn = cn1(form, 'delete-range')
        if (deleteBtn instanceof HTMLButtonElement) {
            if (b) {
                // 削除ボタン有効
                deleteBtn.classList.remove('d-none')
            } else {
                // 削除ボタン無効
                deleteBtn.classList.add('d-none')
            }
            deleteBtn.disabled = !b
        }
    }

    setOnRemoved(f: () => void): void {
        this._onDateRemoved = f
    }

    private apply(c: FollowDateCondition): void {
        const id = this._id

        if (c.rangeStart === undefined || c.rangeStart === null) {
            // undefinedまたはnullの場合は未指定
            this._form[`start-type-${id}`].value = 'none'
        } else if (c.rangeStart == 0) {
            this._form[`start-type-${id}`].value = 'today'
        } else {
            this._form[`start-type-${id}`].value = 'days'
            this._form[`start-days-${id}`].value = c.rangeStart
        }
        this.onStartTypeChanged()

        if (c.rangeEnd === undefined || c.rangeEnd === null) {
            // 来ない
        } else if (c.rangeEnd == 0) {
            this._form[`end-type-${id}`].value = 'today'
        } else {
            this._form[`end-type-${id}`].value = 'days'
            this._form[`end-days-${id}`].value = c.rangeEnd
        }
        this.onEndTypeChanged()
    }

    private deleteRange(): void {
        this._onDateRemoved()
    }

    private onStartTypeChanged(): void {
        const value = this._form[`start-type-${this._id}`].value
        if (value == 'days') {
            this._form[`start-days-${this._id}`].parentElement.classList.remove('d-none')
        } else {
            this._form[`start-days-${this._id}`].parentElement.classList.add('d-none')
        }
    }

    private onEndTypeChanged(): void {
        const value = this._form[`end-type-${this._id}`].value
        if (value == 'days') {
            this._form[`end-days-${this._id}`].parentElement.classList.remove('d-none')
        } else {
            this._form[`end-days-${this._id}`].parentElement.classList.add('d-none')
        }
    }

    private createPanelElement(): HTMLElement {
        const p = cloneNode('follow-range-template')
        if (!p) {
            throw 'Cannot find follow range condition element.'
        }

        const form = p.getElementsByTagName('form')[0]
        form.id = this._id
        this._form = form

        // 〇日前～
        const startDaysInput = cn1(form, 'start-days')
        if (!startDaysInput) {
            throw 'Cannot find follow range start input.'
        }
        startDaysInput.setAttribute('name', `start-days-${form.id}`)

        const startTypeSelect = cn1(form, 'start-type')
        if (!startTypeSelect) {
            throw 'Cannot find follow range start select.'
        }
        startTypeSelect.setAttribute('name', `start-type-${form.id}`)
        startTypeSelect.addEventListener('change', () => this.onStartTypeChanged())

        // ～〇日
        const endDaysInput = cn1(form, 'end-days')
        if (!endDaysInput) {
            throw 'Cannot find follow range end input.'
        }
        endDaysInput.setAttribute('name', `end-days-${form.id}`)

        const endTypeSelect = cn1(form, 'end-type')
        if (!endTypeSelect) {
            throw 'Cannot find follow range end select.'
        }
        endTypeSelect.setAttribute('name', `end-type-${form.id}`)
        endTypeSelect.addEventListener('click', () => this.onEndTypeChanged())

        // 日数削除ボタン
        const deleteBtn = cn1(form, 'delete-range')
        if (deleteBtn) {
            deleteBtn.addEventListener('click', () => this.deleteRange())
        }

        show(p)

        return p
    }

    private defaultOnRemove(): void {
    }
}