import { BaseQrOption } from "./BaseQrOption";

interface ShapeStyleType {
    type?: string
}

/**
 * 形状スタイルオプション
 */
export class ShapeStyle extends BaseQrOption {
    private _style: ShapeStyleType

    private _previousSelected: HTMLButtonElement

    constructor(panel: HTMLElement, style: ShapeStyleType) {
        super()
        this._style = style

        this.setup(panel)
    }

    private applyConfig(btn: HTMLButtonElement): void {
        // data-value属性に値が設定されてるので取得
        const v = btn.getAttribute('data-value')
        if (!v) {
            return
        }
        if (v === 'none') {
            // noneの場合は未設定なのでundefinedでクリア
            this._style.type = undefined
        } else {
            this._style.type = v
        }
        this.raiseOptionUpdated()

        // 選択を更新
        this._previousSelected.classList.remove('selected')
        btn.classList.add('selected')
        this._previousSelected = btn
    }

    private setup(dialog: HTMLElement): void {
        const buttons = dialog.querySelectorAll('button.qr-style')
        let firstButton: HTMLButtonElement | null = null
        for (let n = 0; n < buttons.length; n++) {
            const b = buttons[n] as HTMLButtonElement
            if (!b) {
                continue
            }
            if (!firstButton) {
                firstButton = b
            }
            b.addEventListener('click', () => this.applyConfig(b))
        }

        // 選択肢の最初の項目を選択状態にしておく
        if (firstButton) {
            this._previousSelected = firstButton
            firstButton.classList.add('selected')
        }
    }
}