/**
 * QRデコレーションオプション基底
 */
export abstract class BaseQrOption {
    private _onOptionUpdated: () => void = this.defaultOnOptionUpdated

    setOnOptionUpdated(f: () => void): void {
        this._onOptionUpdated = f
    }

    protected raiseOptionUpdated(): void {
        this._onOptionUpdated()
    }

    private defaultOnOptionUpdated(): void {
    }
}