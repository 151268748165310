import { RICHMENU_ACTION_TYPES } from "../common/types";
import { BaseAction } from "./BaseAction";
import { RichMenuObject } from "./richMenuObject";

/**
 * リッチメニューアクション - 登録済みリンク
 */
export class Link extends BaseAction {
    private _url: HTMLInputElement

    constructor(parent: HTMLElement) {
        super(parent, 'richmenu-link-actions', RICHMENU_ACTION_TYPES.LINK)

        // リンクテキストボックス取得
        const input = this._container.getElementsByTagName('input')
        if (!input) {
            throw 'Cannot find url input element.'
        }
        this._url = input[0]
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: RichMenuObject): void {
        this._url.value = action.linkUrl ?? ''
    }

    /**
     * アクション固有のデータをアクション定義に反映
     */
    buildActionObject(obj: RichMenuObject): void {
        // リンクURLを反映
        obj.linkUrl = this._url.value
    }
}