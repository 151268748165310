import { RichMenuActionType } from "../common/types"
import { cn1, hide, show } from "../utils"
import { RichMenuObject } from "./richMenuObject"

/**
 * リッチメニューアクション - アクション設定ベース
 */
export abstract class BaseAction {
    protected _container: HTMLElement
    protected _type: RichMenuActionType

    constructor(parent: HTMLElement, panelClassName: string, type: RichMenuActionType) {
        // アクション設定パネル
        const c = cn1(parent, panelClassName)
        if (!c) {
            return
        }

        this._type = type
        this._container = c
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    abstract apply(action: RichMenuObject): void

    /**
     * アクション固有のデータをアクション定義に反映
     */
    abstract buildActionObject(obj: RichMenuObject): void

    /**
     * クラスのアクション種別を引数のアクション種別を比較して、同じなら表示、違ったら非表示に
     * 
     * @param checkType 選択した種別
     */
    showIfTypeMatched(checkType: string): void { 
        if (this._type == checkType) {
            show(this._container)
        } else {
            hide(this._container)
        }
    }
}