/**
 * アクションの種別
 */
export const POSTBACK_ACTION_TYPES = {
    NONE: 'NONE',
    ADD_TAG: 'ADD_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
    SEND_TEMPLATE_MESSAGE: 'SEND_TEMPLATE_MESSAGE',
    SWITCH_RICH_MENU: 'SWITCH_RICH_MENU',
} as const

/**
 * アクションの種別
 */
export type PostbackActionType = typeof POSTBACK_ACTION_TYPES[keyof typeof POSTBACK_ACTION_TYPES]

/**
 * 文字列からアクション種別を取得
 */
export function postbackActionTypeOf(s: string): PostbackActionType {
    const x = Object.values(POSTBACK_ACTION_TYPES).filter(t => t == s)
    if (x) {
        return x[0]
    } else {
        return POSTBACK_ACTION_TYPES.NONE
    }
}

/**
 * ポストバックアクション定義
 */
export interface PostbackObject {
    /** アクションID */
    id?: string

    /** アクションの種別 */
    type: PostbackActionType

    /** タグ追加・削除時のタグ情報 */
    tags?: TagItem[]

    /** 送信するメッセージテンプレートID */
    messageId?: string

    /** 切り替え先リッチメニューID */
    menuId?: string

    /** 表示順 */
    order?: number
}

/**
 * タグ情報
 */
export interface TagItem {
    /** タグID */
    id: string

    /** タグ名 */
    name?: string

    /** 表示スタイル */
    style?: string
}