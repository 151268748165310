import { BaseComponent } from "../common/BaseComponent";
import instances from "../common/instances";
import { cloneNode, cn1, hide, show } from "../utils";
import { BaseCondition, TypedBaseCondition } from "./BaseCondition";
import { ConditionObject, RegisteredCondition, RouteCondition, TagCondition } from "./ConditionObject";
import { RegisterDateConditionPanel } from "./RegisterDateConditionPanel";
import { RouteConditionPanel } from "./RouteConditionPanel";
import { TagConditionPanel } from "./TagConditionPanel";

/** 配信グループエディタテンプレートDOMのID */
const CONDITION_EDITOR_TEMPLATE_ID = 'condition-editor-template'

/**
 * 配信グループエディタ
 */
export class ConditionEditor extends BaseComponent {
    /** 配信グループエディタ格納用のコンテナDOM */
    private _editor: HTMLElement
    /** 各種条件を格納するコンテナDOM */
    private _conditionsPanel: HTMLElement
    /** 条件が作成されてないメッセージDOM */
    private _emptyPanel: HTMLElement

    private _conditions: BaseCondition[] = []

    constructor(container: HTMLElement) {
        super()

        const editor = this.createEditorElement()

        // 各種条件を入れるコンテナ取得
        const conditions = cn1(editor, 'conditions-container')
        if (!conditions) {
            return
        }

        // 条件なしメッセージDOM
        const empty = cn1(editor, 'empty-condition-container')
        if (!empty) {
            return
        }

        this._editor = editor
        this._conditionsPanel = conditions
        this._emptyPanel = empty

        container.appendChild(editor)
        show(editor)
    }

    /**
     * 配信グループエディタ初期化
     * 
     * @param conditionJson 配信グループ定義JSON
     */
    init(conditionJson: string): void {
        this._conditions = []

        if (conditionJson) {
            this.applyConditionObject(JSON.parse(conditionJson))
        }

        this.updateConditionsVisible()
        this.updateAddConditionEnable()
    }

    /**
     * 配信グループデータ作成
     */
    buildConditionsJson(): ConditionObject | null{
        const tags = this._conditions.filter(t => t instanceof TagConditionPanel)
            .map(t => t.buildConditionJson())
        const registered = this._conditions.filter(t => t instanceof RegisterDateConditionPanel)
            .map(t => t.buildConditionJson())
        const routes = this._conditions.filter(t => t instanceof RouteConditionPanel)
            .map(t => t.buildConditionJson())

        if (tags.length == 0 && routes.length == 0 && registered.length == 0) {
            return null
        }

        return {
            tags: tags,
            registered: registered,
            routes: routes,
        }
    }

    /**
     * メッセージエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     * @returns 
     */
    static getOrCreateInstance(containerElement: HTMLElement): ConditionEditor {
        let obj: BaseComponent | null  = instances.get(containerElement)
        if (!obj) {
            obj = new ConditionEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as ConditionEditor
    }

    /**
     * 配信グループ条件データを画面に反映
     */
    private applyConditionObject(obj: ConditionObject): void {
        if (obj.tags && obj.tags.length > 0) {
            const tag = obj.tags[0]
            this.applyCondition(new TagConditionPanel(), e => e.apply(tag))
        }
        if (obj.registered && obj.registered.length > 0) {
            const date = obj.registered[0]
            this.applyCondition(new RegisterDateConditionPanel(), e => e.apply(date))
        }
        if (obj.routes && obj.routes.length > 0) {
            const f = obj.routes[0]
            this.applyCondition(new RouteConditionPanel(), e => e.apply(f))
        }
    }

    /**
     * 条件データを画面に反映
     * 
     * @param editor 条件設定パネルDOM
     * @param apply 実際に反映する処理
     */
    private applyCondition<C>(editor: TypedBaseCondition<C>, apply: (editor: TypedBaseCondition<C>) => void): void {
        editor.setOnRemoved(() => this.onConditionRemoved(editor))
        editor.setOnEditConfirmed(() => this.onConditionEditConfirmed(editor))
        apply(editor)

        this._conditions.push(editor)
        this._conditionsPanel.appendChild(editor.getConditionPanel())
    }

    /**
     * 条件データ新規作成
     * 
     * @param editor 条件設定パネルDOM
     * @param apply 実際に反映する処理
     */
    private addNewCondition<C>(editor: TypedBaseCondition<C>, apply: (editor: TypedBaseCondition<C>) => void): void {
        editor.setOnRemoved(() => this.onConditionRemoved(editor))
        editor.setOnEditConfirmed(() => this.onConditionEditConfirmed(editor))
        apply(editor)

        editor.onEdit()
    }

    /**
     * 条件編集がOKの時の処理
     */
    private onConditionEditConfirmed<C>(c: TypedBaseCondition<C>): void {
        if (this._conditions.find(x => x == c)) {
            // すでに保持している条件情報なので何もしない
            return
        }

        // 内部配列に追加
        this._conditions.push(c)
        // 画面にも追加
        this._conditionsPanel.appendChild(c.getConditionPanel())

        // 各表示切替
        this.updateConditionsVisible()
        this.updateAddConditionEnable()
    }

    /**
     * 配信グループエディタDOM生成
     */
    private createEditorElement(): HTMLElement {
        // 配信グループエディタDOM生成
        const editor = cloneNode(CONDITION_EDITOR_TEMPLATE_ID)
        if (!editor) {
            throw 'Cannot create condition editor element.'
        }

        const menus = cn1(editor, 'add-condition-menus')
        if (menus) {
            // タグ条件追加
            cn1(menus, 'btn-condition-tag')?.addEventListener('click', () => {
                this.addNewCondition(new TagConditionPanel(), e => e.apply({ match: 'and', tags: [] }))
            })
            // 友だち登録日条件追加
            cn1(menus, 'btn-condition-registered')?.addEventListener('click', () => {
                this.addNewCondition(new RegisterDateConditionPanel(), e => e.apply({ start: '', end: '' }))
            })
            // 登録経路条件追加
            cn1(menus, 'btn-condition-route')?.addEventListener('click', () => {
                this.addNewCondition(new RouteConditionPanel(), e => e.apply({ routes: [] }))
            })
        }

        return editor
    }

    /**
     * 配信グループ条件が削除された時の処理
     * 
     * @param condition 削除された条件情報
     */
    private onConditionRemoved<C>(condition: TypedBaseCondition<C>): void {
        this._conditions = this._conditions.filter(t => t !== condition)
        this._conditionsPanel.removeChild(condition.getConditionPanel())

        this.updateConditionsVisible()
        this.updateAddConditionEnable()
    }

    /**
     * 条件・条件なしパネルの表示切替
     */
    private updateConditionsVisible(): void {
        if (this._conditions.length > 0) {
            hide(this._emptyPanel)
            show(this._conditionsPanel)
        } else {
            hide(this._conditionsPanel)
            show(this._emptyPanel)
        }
    }

    /**
     * 「条件を追加」ドロップダウンの各項目の有効・無効を切りかえ
     */
    private updateAddConditionEnable(): void {
        let tagEnable: boolean = true
        let registerDateEnable: boolean = true
        let routeEnable: boolean = true
        for (const c of this._conditions) {
            if (c instanceof TagConditionPanel) {
                tagEnable = false
            } else if (c instanceof RegisterDateConditionPanel) {
                registerDateEnable = false
            } else if (c instanceof RouteConditionPanel) {
                routeEnable = false
            }
        }

        const menus = cn1(this._editor, 'add-condition-menus')
        if (!menus) {
            return
        }

        this.updateDropdownEnable(cn1(menus, 'btn-condition-tag'), tagEnable)
        this.updateDropdownEnable(cn1(menus, 'btn-condition-registered'), registerDateEnable)
        this.updateDropdownEnable(cn1(menus, 'btn-condition-route'), routeEnable)
    }

    /**
     * 「条件を追加」ドロップダウンの各項目の有効・無効を切りかえ
     */
    private updateDropdownEnable(elem: HTMLElement | null, enable: boolean): void {
        if (!elem) {
            return
        }

        if (enable) {
            elem.classList.remove('disabled')
            elem.removeAttribute('aria-disabled')
        } else {
            elem.classList.add('disabled')
            elem.setAttribute('aria-disabled', 'true')
        }
    }
}