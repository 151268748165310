import { cloneNode, show, uuid } from "../utils"

/**
 * 色選択肢情報
 */
export interface ColorPatternInfo {
    color: string
    text: string
    isManual?: boolean
}

/**
 * 色選択パネル
 */
export class ColorSelector {
    private _container: HTMLElement

    private _colors: BaseColorPattern[] = []

    constructor(container: HTMLElement) {
        this._container = container
    }

    /**
     * 色の選択肢リストを画面に反映
     */
    apply(colors: ColorPatternInfo[]): void {
        this._colors = []
        this._container.innerHTML = ''

        colors.forEach(c => this.applyColor(c))
    }

    /**
     * 選択した色を画面選択に反映
     * 
     * @param color 選択した色
     */
    select(color: string): void {
        // 固定パターンから設定
        const patterns = this._colors.filter(p => (p instanceof ColorPattern) && p.getValue() == color)
        if (patterns.length > 0) {
            patterns[0].select()
            return
        }

        // 固定パターンにはない色なので、マニュアル選択へ反映
        const manuals = this._colors.filter(p => p instanceof ManualColor)
        if (manuals.length > 0) {
            manuals[0].apply(color)
            return
        }
    }

    /**
     * 画面で選択した色をHTML HEX(#rrggbb)で取得
     * 
     * @returns 色情報(#rrggbb)
     */
    getColor(): string {
        const selected = this._colors.filter(t => t.isSelected())
        if (selected.length > 0) {
            return selected[0].getValue()
        } else {
            return '#000000'
        }
    }

    private applyColor(color: ColorPatternInfo): void {
        let pattern: BaseColorPattern
        if (color.isManual) {
            pattern = new ManualColor()
        } else {
            pattern = new ColorPattern(color)
        }

        this._colors.push(pattern)
        this._container.appendChild(pattern.getPanel())
    }
}

/**
 * 色選択肢情報ベース
 */
abstract class BaseColorPattern {
    protected _panel: HTMLElement
    protected _selectedInput: HTMLInputElement

    constructor() {
        this._panel = this.createElementInternal()

        show(this._panel)
    }

    protected abstract createElement(): HTMLElement

    getValue(): string {
        return this._selectedInput.value
    }

    isSelected(): boolean {
        return this._selectedInput.checked
    }

    select(): void {
        this._selectedInput.checked = true
    }

    getPanel(): HTMLElement {
        return this._panel
    }

    private createElementInternal(): HTMLElement {
        const p = this.createElement()

        const input = p.querySelector('input[type="radio"]') as HTMLInputElement
        if (!input) {
            throw 'Cannot find text color select radio.'
        }
        this._selectedInput = input

        return p
    }
}


class ColorPattern extends BaseColorPattern {
    constructor(color: ColorPatternInfo) {
        super()

        this.setup(color)
    }

    protected createElement(): HTMLElement {
        const p = cloneNode('text-span-color-template')
        if (!p) {
            throw 'Cannot find text span color selector.'
        }

        return p
    }

    private setup(color: ColorPatternInfo): void {
        const id = uuid()
        const label = this._panel.querySelector('label') as HTMLLabelElement
        if (label) {
            label.innerText = color.text
            label.htmlFor = id
            label.style.color = color.color
        }

        this._selectedInput.id = id
        this._selectedInput.value = color.color
    }
}

class ManualColor extends BaseColorPattern {
    private _colorInput: HTMLInputElement

    constructor() {
        super()

        this.setup()
    }

    apply(color: string): void {
        this.select()
        this._colorInput.value = color
    }

    protected createElement(): HTMLElement {
        const p = cloneNode('text-span-color-manual-template')
        if (!p) {
            throw 'Cannot find text span color selector.'
        }

        return p
    }

    private setup(): void {
        const colorInput = this._panel.querySelector('input[type="color"]') as HTMLInputElement
        if (colorInput) {
            colorInput.addEventListener('change', () => {
                this._selectedInput.checked = true
                this._selectedInput.value = colorInput.value
            })
            this._colorInput = colorInput
        }
    }
}