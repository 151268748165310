import bootstrap from 'bootstrap'
import { cn1, hide, show } from '../utils'
import { RegisteredCondition } from './ConditionObject'
import { endianness } from 'os'

/** 友だち登録日選択ダイアログのID */
const REGISTERED_SELECTOR_MODAL_ID = 'select-registered-modal'

/**
 * 友だち登録日選択ダイアログ
 */
export class DateSelector {
    private _modal: bootstrap.Modal

    constructor(date: RegisteredCondition) {
        if (!DateSelector.editorElementInitialized) {
            DateSelector.editorElementInitialized = true
            DateSelector.setup()
        }

        // 現在の選択をダイアログに反映
        this.applyRegistered(date)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(DateSelector.editorElement)

        // ダイアログが閉じられたら破棄するように
        DateSelector.editorClosedHandler = () => {
            this._modal.dispose()
        }
    }

    /**
     * 友だち登録日選択ダイアログを表示
     * 
     * @param confirmed 友だち登録日選択を確定したときの処理
     */
    show(confirmed: (date: RegisteredCondition) => void): void {
        // ダイアログを決定した際の処理を登録しておく
        DateSelector.editorConfirmedHandler = () => {
            // 登録日条件情報生成
            const obj = this.buildRegisteredCondition()
            // ダイアログは閉じておく
            this._modal.hide()
            // 選択時の処理を実行
            confirmed(obj)
        }
        // ダイアログ表示
        this._modal.show()
    }

    /**
     * タグ設定をダイアログに反映
     */
    private applyRegistered(date: RegisteredCondition): void {
        const form = DateSelector.editorElement.getElementsByTagName('form')[0]

        const startInput: HTMLInputElement = form['start-date']
        startInput.value = date.start

        const endInput: HTMLInputElement = form['end-date']
        endInput.value = date.end
    }

    /**
     * ダイアログの設定内容から登録日情報を生成
     */
    private buildRegisteredCondition(): RegisteredCondition {
        const form = DateSelector.editorElement.getElementsByTagName('form')[0]
        return {
            start: form['start-date'].value,
            end: form['end-date'].value,
        }
    }

    /**
     * 日付の入力を確認
     */
    private static checkDate(dialog: HTMLElement): boolean {
        const form = dialog.getElementsByTagName('form')[0]
        const checked = form['start-date'].value || form['end-date'].value

        const m = cn1(dialog, 'date-input-message')
        if (checked) {
            if (m) { hide(m) }
        } else {
            if (m) { show(m) }
        }

        return checked
    }

    /**
     * 友だち登録日選択ダイアログ初期化
     */
    private static setup(): void {
        // 確定ボタンが押された時の処理
        cn1(this.editorElement, 'btn-registered-select-confirm')?.addEventListener('click', () => {
            if (!this.checkDate(this.editorElement)){
                return
            }
            if (this.editorConfirmedHandler) {
                this.editorConfirmedHandler()
                this.editorConfirmedHandler = undefined
            }
        })
        // ダイアログが閉じられたときの処理
        this.editorElement.addEventListener('hidden.bs.modal', () => {
            if (this.editorClosedHandler) {
                this.editorClosedHandler()
                this.editorClosedHandler = undefined
            }
        })
    }

    private static editorConfirmedHandler?: () => void

    private static editorClosedHandler?: () => void

    /** ボタン編集ダイアログDOM */
    private static editorElement: HTMLElement = document.getElementById(REGISTERED_SELECTOR_MODAL_ID)!!

    /** ボタン編集ダイアログ初期化済みか */
    private static editorElementInitialized: boolean = false
}