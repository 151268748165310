import { VisibleSwitcher } from "./visibleSwitcher"

/**
 * ログインフォームセットアップ情報
 */
export interface LoginSetupOptions {
    pid: string
}

const visibleSwitchers: VisibleSwitcher[] = []

/**
 * ログイン画面処理
 */
export class Login {
    static setup(options?: LoginSetupOptions) {
        this.setupPasswordForm(options)
    }

    private static setupPasswordForm(options?: LoginSetupOptions) {
        const loginFormElements = document.getElementsByClassName('login-form')
        for (let target of loginFormElements) {
            const v = VisibleSwitcher.create(target as HTMLElement)
            if (v) {
                visibleSwitchers.push(v)
            }
        }
    }
}