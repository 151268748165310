import { BaseComponent } from "../common/BaseComponent"
import { cloneNode, cn1, show } from "../utils"
import { Bubble } from "./bubble"
import { BubbleItem, MessageObject } from "./messageObject"
import { gpropInput } from "./misc"

/** バルーンテンプレートDOMのID */
const BALLOON_TEMPLATE_ID = 'message-balloon-template'

/** バブルリストを格納するコンテナDOMのクラス名 */
const BUBBLE_CONTAINER_CLASSNAME = 'line-carousel'

/** 1個上に移動ボタンのクラス名 */
const BUTTON_MOVE_UP_CLASSNAME = 'message-move-up'
/** 1個下に移動ボタンのクラス名 */
const BUTTON_MOVE_DOWN_CLASSNAME = 'message-move-down'
/** バルーン削除ボタンのクラス名 */
const BUTTON_REMOVE_CLASSNAME = 'message-remove'
/** バブル追加ボタンのクラス名 */
const BUTTON_ADD_BUBBLE_CLASSNAME = 'add-bubble'


/**
 * メッセージオブジェクト吹き出しクラス(1メッセージで横並びのやつのまとめ)
 */
export class Balloon extends BaseComponent {
    private _bubbleContainer: HTMLElement

    private _readOnly: boolean = false

    private _bubbles: Bubble[] = []

    private _onMoveUp: (b: Balloon) => void = this.defaultOnMoveUp
    private _onMoveDown: (b: Balloon) => void = this.defaultOnMoveDown
    private _onRemove: () => void = this.defaultOnRemove

    constructor(altText: string, readOnly: boolean) {
        super()

        // バルーン用DOM要素を作成
        const e = this.createBalloon()
        if (readOnly) {
            // 読み取り専用モードの場合は操作ボタンは削除しておく
            for (const b of e.getElementsByClassName('card-footer')) {
                e.removeChild(b)
            }
        }
        this._readOnly = readOnly

        // カルーセルアイテム格納用のコンテナを取得しておく
        const carouselContainer = cn1(e, BUBBLE_CONTAINER_CLASSNAME)
        if (!carouselContainer) {
            return
        }

        // ALTテキストを反映
        gpropInput(e, 'altText').value = altText

        this.setElement(e)
        this._bubbleContainer = carouselContainer

        show(e)
    }

    /**
     * メッセージオブジェクト吹き出しにバブルコンテナを追加
     * 
     * @param carousel 追加するバブル定義
     */
    addBubbleItem(bubble: BubbleItem): void {
        const obj = new Bubble(this._readOnly)

        // バブル内にブロックを反映
        bubble.blocks.forEach(b => obj.addBlock(b))

        // バブルが削除されたときの処理を登録
        obj.setOnBubbleRemoved(b => this.onBubbleRemoved(b))

        // コンテナに追加
        this._bubbles.push(obj)
        this._bubbleContainer.appendChild(obj.getElement())

        this.updateBubbleButtons()
    }

    /**
     * 上下ボタンの有効/無効を設定
     * 
     * @param up 上ボタンが有効かどうか
     * @param down 下ボタンが有効かどうか
     */
    setUpDownEnabled(up: boolean, down: boolean): void {
        if (up) {
            cn1(this.getElement(), 'message-move-up')?.classList.remove('disabled')
        } else {
            cn1(this.getElement(), 'message-move-up')?.classList.add('disabled')
        }
        if (down) {
            cn1(this.getElement(), 'message-move-down')?.classList.remove('disabled')
        } else {
            cn1(this.getElement(), 'message-move-down')?.classList.add('disabled')
        }
    }

    /**
     * 上ボタンを押したときの処理を登録
     */
    setOnMoveUp(f: (b: Balloon) => void): void {
        this._onMoveUp = f
    }

    /**
     * 下ボタンを押したときの処理を登録
     */
    setOnMoveDown(f: (b: Balloon) => void): void {
        this._onMoveDown = f
    }

    /**
     * バルーンが削除されるときの処理
     */
    setOnRemoved(f: () => void): void {
        this._onRemove = f
    }

    /**
     * JSON用オブジェクトを取得
     */
    buildFlexObject(): MessageObject {
        const obj: MessageObject = {
            altText: gpropInput(this.getElement(), 'altText').value,
            bubbles: this._bubbles.map(c => c.buildFlexObject())
        }
        return obj
    }

    /**
     * メッセージ定義のバリデーション
     * 
     * @returns エラーが無い場合は空文字、エラーがある場合はそのメッセージ
     */
    validateMessage(): string {
        // ALTテキストが入力されているか
        const altElem = gpropInput(this.getElement(), 'altText')
        if (!altElem.value) {
            altElem.classList.add('is-invalid')
            return "ALTテキストを入力してください。"
        } else {
            altElem.classList.remove('is-invalid')
        }

        return ""
    }

    /**
     * バルーンDOM生成
     */
    private createBalloon(): HTMLElement {
        const t = cloneNode(BALLOON_TEMPLATE_ID)
        if (!t) {
            // 取得できなかったのでこれはエラー
            throw 'Cannot create balloon element.'
        }

        // 上ボタンを押したときの処理
        const upBtn = cn1(t, BUTTON_MOVE_UP_CLASSNAME)
        if (upBtn) {
            upBtn.addEventListener('click', () => this._onMoveUp(this))
        }
        // 下ボタンを押したときの処理
        const downBtn = cn1(t, BUTTON_MOVE_DOWN_CLASSNAME)
        if (downBtn) {
            downBtn.addEventListener('click', () => this._onMoveDown(this))
        }

        // 削除ボタンを押したときの処理
        const removeBtn = cn1(t, BUTTON_REMOVE_CLASSNAME)
        if (removeBtn) {
            removeBtn.addEventListener('click', () => {
                if (confirm('バルーンを削除しますか？')) {
                    this._onRemove()
                }
            })
        }

        // バブル追加ボタンを押したときの処理
        const bubbleAddBtn = cn1(t, BUTTON_ADD_BUBBLE_CLASSNAME)
        if (bubbleAddBtn) {
            bubbleAddBtn.addEventListener('click', () => this.addBubbleItem({blocks: []}))
        }

        return t
    }

    /**
     * バブルが削除されたときの処理
     * 
     * @param bubble 削除されたバブルデータ
     */
    private onBubbleRemoved(bubble: Bubble): void {
        // 内部配列から削除
        this._bubbles = this._bubbles.filter(t => t !== bubble)
        // DOM要素からも削除
        this._bubbleContainer.removeChild(bubble.getElement())

        this.updateBubbleButtons()
    }

    /**
     * 各バブルのボタンの表示更新
     */
    private updateBubbleButtons() {
        if (this._bubbles.length == 1) {
            // 1個だけの場合は削除ボタンは非表示
            this._bubbles[0].setRemoveEnabled(false)
        } else {
            // 複数あるので、削除ボタンを全部表示
            this._bubbles.forEach(t => t.setRemoveEnabled(true))
        }
    }


    private defaultOnMoveUp(): void {
    }
    private defaultOnMoveDown(): void {
    }
    private defaultOnRemove(): void {
    }
}
