import { BaseComponent } from "../common/BaseComponent";
import instances from "../common/instances";
import { cn1, hide, show } from "../utils";
import { ImageSelectOptions, ImageSizeInfo, SelectedImage } from "./types";

/** 画像ドラッグアンドドロップを受け付ける画像選択パネルのクラス名 */
const PANEL_DND_CLASSNAME = 'image-upload-container'
/** 画像選択INPUTのクラス名 */
const INPUT_SELECT_IMAGE_CLASSNAME = 'select-image-input'
/** 選択した画像を表示するパネルのクラス名 */
const PANEL_SELECTED_VIEWER_CLASSNAME = 'image-selected-container'
/** 選択画像取消ボタンのクラス名 */
const BUTTON_CANCEL_IMAGE_CLASSNAME = 'delete-selected-image'

/** 選択した画像を表示するようIMGのクラス名 */
const IMAGE_SELECTED_IMAGE_CLASSNAME = 'image-selected-image'

/** デフォルトオプション */
const DEFAULT_SELECT_OPTIONS: ImageSelectOptions = {
    imgClassName: IMAGE_SELECTED_IMAGE_CLASSNAME,
    uploadContainerClassName: PANEL_DND_CLASSNAME,
    imageViewerClassName: PANEL_SELECTED_VIEWER_CLASSNAME,
    selectImageInputClassName: INPUT_SELECT_IMAGE_CLASSNAME,
    cancelButtonClassName: BUTTON_CANCEL_IMAGE_CLASSNAME,
}

/**
 * 画像選択パネル
 */
export class ImageSelector extends BaseComponent {
    private _panel: HTMLElement
    private _img: HTMLImageElement

    private _onImageLoadedListener: () => void

    private _selected: boolean = false
    private _options: ImageSelectOptions = {}

    constructor(panel: HTMLElement) {
        super()

        this._panel = panel
    }

    /**
     * 画像選択パネルの初期化
     */
    init(options?: ImageSelectOptions): void {
        // 選択オプション設定
        Object.assign(this._options, {
            ...DEFAULT_SELECT_OPTIONS,
            ...options,
        })

        // 画像表示用IMGを取得
        const img = cn1(this._panel, this._options.imgClassName ?? IMAGE_SELECTED_IMAGE_CLASSNAME) as HTMLImageElement
        if (!img) {
            return
        }
        img.addEventListener('load', () => this.onImageLoaded())
        this._img = img

        // D＆Dを受け付けるパネルを取得
        this.setupDragDropPanel()

        // 画像選択用INPUT取得
        this.setupImageInput()

        // 削除ボタン選択時の処理
        this.setupCancelImageButton()
    }

    /**
     * 画像のロードが完了した際のハンドラを登録
     */
    setOnImageLoaded(f: () => void) {
        this._onImageLoadedListener = f
    }

    /**
     * 画像データを画面に表示
     */
    setImageData(data: string): void {
        this._img.src = data
    }

    /**
     * 画像データをクリアしておく
     */
    clearImageData(): void {
        this._img.src = ''
        this._selected = false
        this.hideImage()
    }

    /**
     * 選択した画像データを取得
     */
    getImageData(): SelectedImage {
        let data: string | null = null
        if (this._selected) {
            data = this._img.src
        }
        return {
            width: this._img.naturalWidth,
            height: this._img.naturalHeight,
            data: data,
        }
    }

    /**
     * 画像のサイズを取得
     */
    getImageSizes(): ImageSizeInfo {
        return {
            width: this._img.naturalWidth,
            height: this._img.naturalHeight,
            scaledWidth: this._img.width,
            scaledHeight: this._img.height,
        }
    }

    /**
     * 画像選択パネルインスタンスを取得する
     * 
     * @param selectorPanelElement パネルDOM
     */
    static getOrCreateInstance(selectorPanelElement: HTMLElement): ImageSelector {
        let obj: BaseComponent | null  = instances.get(selectorPanelElement)
        if (!obj) {
            obj = new ImageSelector(selectorPanelElement)
            instances.set(selectorPanelElement, obj)
        }
        return obj as ImageSelector
    }

    /**
     * 画像選択用INPUTの初期化
     */
    private setupImageInput(): void {
        // INPUTのクラス名
        const className = this._options.selectImageInputClassName ?? INPUT_SELECT_IMAGE_CLASSNAME
        // 画像選択用INPUT取得
        const input = cn1(this._panel, className) as HTMLInputElement
        if (!input) {
            return
        }

        input.addEventListener('change', () => {
            if (input.files && input.files.length > 0 && this.isImage(input.files[0])) {
                if (!this.onImageSelected(input.files[0])) {
                    input.value = ''
                }
            } else {
                input.value = ''
            }
        })
    }

    /**
     * 画像ドラッグアンドドロップを受け付けるパネルの初期化
     */
    private setupDragDropPanel(): void {
        // パネルのクラス名
        const className = this._options.uploadContainerClassName ?? PANEL_DND_CLASSNAME
        const dropPanel = cn1(this._panel, className)
        if (!dropPanel) {
            return
        }

        dropPanel.addEventListener('dragover', evt => {
            // パネルにドラッグされた
            evt.preventDefault()
            dropPanel.classList.add('drag-over')
        })
        dropPanel.addEventListener('dragleave', evt => {
            // ドラッグ中パネルの外に出た
            evt.preventDefault()
            dropPanel.classList.remove('drag-over')
        })
        dropPanel.addEventListener('drop', evt => {
            // ドロップした
            evt.preventDefault()
            dropPanel.classList.remove('drag-over')
            // ドロップされたファイルを取得
            const files = evt.dataTransfer?.files ?? new FileList()
            if (files.length > 0 && this.isImage(files[0])) {
                // ちゃんと画像がドロップされた
                this.onImageSelected(files[0])
            }
        })
    }

    /**
     * 削除ボタン初期化
     */
    private setupCancelImageButton(): void {
        // ボタンのクラス名
        const className = this._options.cancelButtonClassName ?? BUTTON_CANCEL_IMAGE_CLASSNAME
        const btn = cn1(this._panel, className)
        if (!btn) {
            return
        }

        btn.addEventListener('click', () => {
            this.clearImageData()
        })
    }

    /**
     * 画像ファイルが選択された 
     */
    private onImageSelected(file: File): boolean {
        if (this._options.maxFileSize && (file.size > this._options.maxFileSize)) {
            // 画像ファイルのファイルサイズが規定値超える
            alert('ファイルサイズが許容サイズ超える画像はアップロードできません。')
            return false
        }

        const r = new FileReader()
        r.onload = () => {
            if (r.result && typeof r.result === 'string' ) {
                this._selected = true
                this._img.src = r.result
            }
        }
        r.readAsDataURL(file)

        return true
    }

    /**
     * 選択画像表示用IMGに画像が表示された時の処理
     */
    private onImageLoaded(): void {
        if (this._options.maxWidth && (this._img.naturalWidth > this._options.maxWidth)) {
            // 画像の幅が規定値を超えた
            this._img.src = ''
            alert(`画像の幅が${this._options.maxWidth}pxを超える画像はアップロードできません。`)
            return
        }
        if (this._options.maxHeight && (this._img.naturalHeight > this._options.maxHeight)) {
            // 画像の高さが規定値を超えた
            this._img.src = ''
            alert(`画像の幅が${this._options.maxHeight}pxを超える画像はアップロードできません。`)
            return
        }

        this.showImage()

        if (this._onImageLoadedListener) {
            this._onImageLoadedListener()
        }
    }

    private showImage(): void {
        // 選択しろパネルは非表示に
        const v = cn1(this._panel, this._options.uploadContainerClassName ?? PANEL_DND_CLASSNAME)
        if (v) {
            hide(v)
        }
        // 選択した画像を表示
        const w = cn1(this._panel, this._options.imageViewerClassName ?? PANEL_SELECTED_VIEWER_CLASSNAME)
        if (w) {
            show(w)
        }
    }

    private hideImage(): void {
        // 選択した画像は非表示
        const w = cn1(this._panel, this._options.imageViewerClassName ?? PANEL_SELECTED_VIEWER_CLASSNAME)
        if (w) {
            hide(w)
        }
        // 選択しろパネルは表示に
        const v = cn1(this._panel, this._options.uploadContainerClassName ?? PANEL_DND_CLASSNAME)
        if (v) {
            show(v)
        }
    }

    /**
     * ファイルが画像かどうかをContentTypeの「image/*」で判定
     * 
     * @param f 選択したファイル
     * @returns trueなら画像とする
     */
    private isImage(f: File): boolean {
        return f.type.startsWith('image/')
    }
}