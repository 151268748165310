/**
 * data-msg-type定義の要素を取得
 * 
 * @param t 検索元DOM要素
 * @param name プロパティタイプ
 * @returns 
 */
export function gprop(t: HTMLElement, name: string): HTMLElement {
    return t.querySelector(`[data-msg-property="${name}"]`) as HTMLElement
}

/**
 * data-msg-type定義の要素を<input>要素として取得
 * 
 * @param t 検索元DOM要素
 * @param name プロパティタイプ
 * @returns 
 */
export function gpropInput(t: HTMLElement, name: string): HTMLInputElement {
    return t.querySelector(`[data-msg-property="${name}"]`) as HTMLInputElement
}
