import { cloneNode, show } from "../utils";
import { TypedBaseCondition } from "./BaseCondition";
import { FollowDateGroup } from "./ConditionObject";
import { FollowDateSelectOptions, FollowDateSelector } from "./FollowDateSelector";

/** 登録日条件表示パネルのテンプレートのID */
const PANEL_FOLLOWS_TEMPLATE_ID = 'follows-condition-template'

/**
 * 友だち登録日時条件パネル
 */
export class FollowConditionPanel extends TypedBaseCondition<FollowDateGroup> {
    private _panel: HTMLElement

    constructor(readOnly: boolean) {
        super(readOnly)

        const p = this.createFollowDatePanel()
        this._body.appendChild(p)

        this._panel = p

        show(p)
    }

    /**
     * 友だち登録日条件の内容を画面に反映
     * 
     * @param follow 友だち登録日条件
     */
    apply(follow: FollowDateGroup): void {
        this._condition = follow

        // 一旦クリア
        this._panel.innerHTML = ''

        // 各条件を反映
        if (follow.mode === 'date') {
            this.applyByDate(follow)
        } else if (follow.mode === 'range') {
            this.applyByRange(follow)
        }
    }

    /**
     * 条件タイプ
     */
    getType(): string {
        return 'follow-date'
    }

    /**
     * 名称取得
     */
    getName(): string {
        return "友だち登録日"
    }

    /**
     * 設定ダイアログ表示
     */
    onEdit(): void {
        const opts: FollowDateSelectOptions = {
            follows: this._condition,
            confirmed: (f) => {
                this.apply(f)
                this.raiseOnEditConfirmed()
            },
            cancelled: () => {}
        }
        new FollowDateSelector(opts).show()
    }

    private applyByDate(follow: FollowDateGroup): void {
        const labelDiv = document.createElement('div')
        labelDiv.classList.add('mb-3')
        labelDiv.innerText = '次の日にちに登録した友だち'
        this._panel.appendChild(labelDiv)

        follow.conditions.forEach(t => {
            const div = document.createElement('div')

            const start = t.start?.replaceAll('-', '/') ?? '(未指定)'
            const end = t.end?.replaceAll('-', '/') ?? '(未指定)'
            div.innerText = `${start} ～ ${end}`

            this._panel.appendChild(div)
        })
    }

    private applyByRange(follow: FollowDateGroup): void { 
        const labelDiv = document.createElement('div')
        labelDiv.classList.add('mb-3')
        labelDiv.innerText = '次の日数の間に登録した友だち'
        this._panel.appendChild(labelDiv)

        follow.conditions.forEach(t => {
            const div = document.createElement('div')

            let start: string
            if (t.rangeStart !== undefined && t.rangeStart !== null) {
                if (t.rangeStart == 0) {
                    start = '当日'
                } else {
                    start = `${t.rangeStart}日前`
                }
            } else {
                start = '(未指定)'
            }
            let end: string
            if (t.rangeEnd !== undefined && t.rangeEnd !== null) {
                if (t.rangeEnd == 0) {
                    end = '当日'
                } else {
                    end = `${t.rangeEnd}日前`
                }
            } else {
                end = ''
            }
            div.innerText = `${start} ～ ${end}`

            this._panel.appendChild(div)
        })
    }

    private createFollowDatePanel(): HTMLElement {
        const p = cloneNode(PANEL_FOLLOWS_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot find follow dates viewer panel.'
        }

        return p
    }
}
