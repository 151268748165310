import { RICHMENU_ACTION_TYPES } from "../common/types";
import { cn1 } from "../utils";
import { BaseAction } from "./BaseAction";
import { RichMenuObject } from "./richMenuObject";

/**
 * リッチメニューアクション - リッチメニュー切り替え
 */
export class SwitchRichMenu extends BaseAction {
    private _selector: HTMLSelectElement

    constructor(parent: HTMLElement) {
        super(parent, 'richmenu-switch-tab-actions', RICHMENU_ACTION_TYPES.SWITCH_TAB)

        const s = this._container.getElementsByTagName('select')
        if (!s) {
            throw 'Cannot find RichMenu selector.'
        }
        this._selector = s[0]
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: RichMenuObject): void {
        this._selector.value = action.richMenuId ?? ''
    }

    /**
     * アクション固有のデータをアクション定義に反映
     */
    buildActionObject(obj: RichMenuObject): void {
        // リッチメニューを反映
        obj.richMenuId = this._selector.value
    }
}