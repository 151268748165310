import { Modal } from 'bootstrap'

/** 画像編集ダイアログのID */
const IMAGE_SELECTOR_MODAL_ID = 'image-select-modal'

/**
 * 画像選択時の各種オプション
 */
export interface ImageSelectorInfo {
    url: string
}

/**
 * 画像選択ダイアログ
 */
export class ImageSelector {
    private _modal: bootstrap.Modal

    constructor(data: ImageSelectorInfo) {
        if (!ImageSelector.editorElementInitialized) {
            ImageSelector.editorElementInitialized = true
            ImageSelector.setupImageSelector()
        }

        // 現在の選択をダイアログに反映
        this.applyImageInfo(data)

        // bootstrapモーダル生成
        this._modal = new Modal(ImageSelector.editorElement)

        // ダイアログが閉じられたら破棄するように
        ImageSelector.editorClosedHandler = () => {
            this._modal.dispose()
        }
    }

    /**
     * 画像選択ダイアログを表示
     * 
     * @param confirmed 画像選択を確定したときの処理
     */
    show(confirmed: (selected: ImageSelectorInfo) => void): void {
        // ダイアログを決定した際の処理を登録しておく
        ImageSelector.editorConfirmedHandler = (imageUrl: string) => {
            // ダイアログは閉じておく
            this._modal.hide()
            // 選択時の処理を実行
            confirmed({
                url: imageUrl
            })
        }
        // ダイアログ表示
        this._modal.show()
    }

    /**
     * ダイアログに設定値反映
     */
    private applyImageInfo(info: ImageSelectorInfo): void {
        const imageLinks = ImageSelector.editorElement.getElementsByClassName('message-image-link')
        for (let elem of imageLinks) {
            const imageUrl = elem.getAttribute('data-image-url')
            if (imageUrl == info.url) {
                // 選択されてる画像
                elem.classList.add('selected')
            } else {
                // 選択されてない画像
                elem.classList.remove('selected')
            }
        }
    }

    /**
     * 画像選択ダイアログ初期化
     */
    private static setupImageSelector(): void {
        // 画像選択クリック時の処理
        const imageLinks = this.editorElement.getElementsByClassName('message-image-link')
        for (let elem of imageLinks) {
            elem.addEventListener('click', evt => {
                const link = this.getMessageLink(evt.target as HTMLElement)
                const imageUrl = link?.getAttribute('data-image-url') ?? ''
                if (this.editorConfirmedHandler && imageUrl) {
                    this.editorConfirmedHandler(imageUrl)
                    this.editorConfirmedHandler = undefined
                }
            })
        }
        // ダイアログが閉じられたときの処理
        this.editorElement.addEventListener('hidden.bs.modal', () => {
            if (this.editorClosedHandler) {
                this.editorClosedHandler()
                this.editorClosedHandler = undefined
            }
        })
    }

    private static getMessageLink(src: HTMLElement): HTMLElement | null {
        if (!src.parentElement) {
            return null
        }
        const p = src.parentElement
        if (p.classList.contains('message-image-link')) {
            return p
        } else {
            return this.getMessageLink(p)
        }
    }

    private static editorConfirmedHandler?: (imageUrl: string) => void

    private static editorClosedHandler?: () => void

    /** ボタン編集ダイアログDOM */
    private static editorElement: HTMLElement = document.getElementById(IMAGE_SELECTOR_MODAL_ID)!!

    /** ボタン編集ダイアログ初期化済みか */
    private static editorElementInitialized: boolean = false
}