import { BaseComponent } from "../common/BaseComponent";
import { cloneNode, cn1, show } from "../utils";
import { AddTag } from "./addTag";
import { BaseAction } from "./baseAction";
import { POSTBACK_ACTION_TYPES, PostbackActionType, postbackActionTypeOf, PostbackObject } from "./postbackObjects";
import { RemoveTag } from "./removeTag";
import { SendMessage } from "./sendMessage";
import { SwitchRichMenu } from "./switchRichMenu";

/** アクション定義用テンプレートDOMのID */
const ACTION_ITEM_TEMPLATE_ID = 'postback-action-item-template'

/** アクションタイプ選択のクラス名 */
const INPUT_ACTION_TYPE_CLASSNAME = 'postback-action-type'
/** アクション削除ボタンのクラス名 */
const BUTTON_REMOVE_ACTION_CLASSNAME = 'btn-postback-remove-action'

/**
 * ポストバックアクション定義クラス
 */
export class Action extends BaseComponent {
    private _actions: { [key: string]: BaseAction } = {}
    private _selectedType: PostbackActionType = POSTBACK_ACTION_TYPES.NONE

    private _onRemovedHandler: () => void = this.defaultOnRemovedHandler

    constructor(action: PostbackObject) {
        super()

        // アクション定義用DOMを作成
        const panel = this.createItemElement()
        this._actions[POSTBACK_ACTION_TYPES.ADD_TAG] = new AddTag(panel)
        this._actions[POSTBACK_ACTION_TYPES.REMOVE_TAG] = new RemoveTag(panel)
        this._actions[POSTBACK_ACTION_TYPES.SEND_TEMPLATE_MESSAGE] = new SendMessage(panel)
        this._actions[POSTBACK_ACTION_TYPES.SWITCH_RICH_MENU] = new SwitchRichMenu(panel)

        // 保持
        this.setElement(panel)

        // 設定内容を画面に反映
        this.applyAction(action)
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): PostbackObject | null {
        if (this._selectedType == POSTBACK_ACTION_TYPES.NONE) {
            // 未定義なのでダメ
            return null
        }

        const obj = this._actions[this._selectedType].buildActionJson()
        if (!obj) {
            return null
        }

        // IDを設定しておく
        obj.id = this.getElement().getAttribute('data-action-id') ?? undefined

        return obj
    }

    /**
     * このアクションが削除されるときにコールされるハンドラを登録
     */
    setOnRemovedHandler(handler: () => void): void {
        this._onRemovedHandler = handler
    }

    /**
     * アクション定義を画面に反映
     */
    private applyAction(action: PostbackObject): void {
        const p = this.getElement()
        if (action.id) {
            p.setAttribute('data-action-id', action.id)
        }

        // アクションタイプ
        const typeSelect = cn1(p, INPUT_ACTION_TYPE_CLASSNAME) as HTMLSelectElement
        if (typeSelect) {
            typeSelect.value = action.type
        }
        this.onActionTypeChanged(action.type)

        // 各アクションの設定値を画面に反映
        this._actions[action.type].apply(action)
    }

    /**
     * アクションタイプが変更されたときの処理
     */
    private onActionTypeChanged(type: PostbackActionType): void {
        // 選択したアクションタイプによって表示を切り替える
        Object.keys(POSTBACK_ACTION_TYPES).forEach(k => this._actions[k]?.showIfTypeMatched(type))
        // 選択したアクションタイプを保持
        this._selectedType = type
    }

    /**
     * 削除ボタンが押された
     */
    private onActionRemove(): void {
        if (confirm('アクションを削除しますか？')) {
            // アクション削除確認OKなので、登録されたイベントハンドラをコール
            this._onRemovedHandler()
        }
    }

    /**
     * アクション定義用DOMを生成
     */
    private createItemElement(): HTMLElement {
        const p = cloneNode(ACTION_ITEM_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot create action item element.'
        }

        // アクションタイプが変更されたときの処理
        cn1(p, INPUT_ACTION_TYPE_CLASSNAME)?.addEventListener('change', evt => {
            const type = postbackActionTypeOf((evt.target as HTMLSelectElement).value)
            this.onActionTypeChanged(type)
        })

        // 削除ボタンが押されたときの処理
        cn1(p, BUTTON_REMOVE_ACTION_CLASSNAME)?.addEventListener('click', () => this.onActionRemove())

        // 表示しておく
        show(p)

        return p
    }


    /**
     * ブロック削除時のイベントハンドラが未登録時に実行される
     */
    private defaultOnRemovedHandler(): void {
        console.dir('action removed.')
    }
}