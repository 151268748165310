import { cn1 } from "../utils";
import { BaseAction } from "./baseAction";
import { POSTBACK_ACTION_TYPES, PostbackObject } from "./postbackObjects";

/** 送信メッセージ選択パネルのクラス名 */
const INPUT_SEND_MESSAGE_CLASSNAME = 'postback-action-send-message'

/**
 * ポストバックアクション - テンプレートメッセージ送信
 */
export class SendMessage extends BaseAction {
    private _selector: HTMLSelectElement

    constructor(parent: HTMLElement) {
        super(parent, INPUT_SEND_MESSAGE_CLASSNAME, POSTBACK_ACTION_TYPES.SEND_TEMPLATE_MESSAGE)

        const s = cn1(this._container, 'postback-message-select') as HTMLSelectElement
        if (!s) {
            throw 'Cannot find MessageTemplate selector.'
        }
        this._selector = s
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: PostbackObject): void {
        this._selector.value = action.messageId ?? ''
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): PostbackObject | null {
        const messageId = this._selector.value
        if (!messageId) {
            this._selector.classList.add('is-invalid')
            return null
        }

        this._selector.classList.remove('is-invalid')

        return {
            type: this._type,
            messageId: messageId,
        }
    }
}