import bootstrap from 'bootstrap'
import { cn1 } from '../utils'
import { gpropInput } from './misc'

/**
 * 文字列SPAN編集時の編集データ
 */
export interface TextEditorInfo {
    /** テキスト */
    text: string
    /** 文字サイズ */
    size: string
    /** 文字色 */
    color: string
    /** SPAN要素のID */
    spanId: string
}

/** テキスト編集ダイアログのID */
const TEXT_EDITOR_MODAL_ID = 'text-edit-modal'


/**
 * テキスト編集ダイアログ
 */
export class TextEditor {
    private _modal: bootstrap.Modal

    constructor(data: TextEditorInfo) {
        if (!TextEditor.editorElementInitialized) {
            TextEditor.setupTextEditor()
            TextEditor.editorElementInitialized = true
        }

        // ダイアログにテキストのデータを反映
        this.applyTextInfo(data)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(TextEditor.editorElement)

        // ダイアログが閉じられたら破棄するように
        TextEditor.editClosedHandler = () => {
            this._modal.dispose()
        }
    }

    /**
     * テキスト編集ダイアログを表示
     * 
     * @param confirmed 編集を決定した際に実行する処理
     */
    show(confirmed: (info: TextEditorInfo) => void) {
        // ダイアログを決定した際の処理を登録しておく
        TextEditor.editConfirmedHandler = () => {
            // 編集済みテキストデータ作成
            const info: TextEditorInfo = {
                spanId: gpropInput(TextEditor.editorElement, 'editTextSpan').value,
                text: gpropInput(TextEditor.editorElement, 'text').value,
                color: gpropInput(TextEditor.editorElement, 'color').value,
                size: gpropInput(TextEditor.editorElement, 'size').value,
            }
            // ダイアログは閉じておく
            this._modal.hide()
            // 決定イベントハンドラをコール
            confirmed(info)
        }
        // ダイアログ表示
        this._modal.show()
    }

    /**
     * テキスト編集ダイアログにテキストデータを反映
     * 
     * @param data テキスト編集データ
     */
    private applyTextInfo(data: TextEditorInfo) {
        gpropInput(TextEditor.editorElement, 'editTextSpan').value = data.spanId
        gpropInput(TextEditor.editorElement, 'text').value = data.text
        gpropInput(TextEditor.editorElement, 'color').value = data.color
        gpropInput(TextEditor.editorElement, 'size').value = data.size
    }

    /**
     * テキスト編集ダイアログセットアップ
     */
    private static setupTextEditor(): void {
        cn1(TextEditor.editorElement, 'confirm-edit-text')?.addEventListener('click', () => {
            if (TextEditor.editConfirmedHandler) {
                TextEditor.editConfirmedHandler()
                TextEditor.editConfirmedHandler = undefined
            }
        })
        TextEditor.editorElement.addEventListener('hidden.bs.modal', () => {
            if (TextEditor.editClosedHandler) {
                TextEditor.editClosedHandler()
                TextEditor.editClosedHandler = undefined
            }
        })
    }

    private static editConfirmedHandler?: () => void

    private static editClosedHandler?: () => void

    /** テキスト編集ダイアログDOM */
    private static editorElement: HTMLElement = document.getElementById(TEXT_EDITOR_MODAL_ID)!!

    /** テキスト編集ダイアログ初期化済みか */
    private static editorElementInitialized: boolean = false
}