import { BaseComponent } from "../common/BaseComponent";
import { cloneNode, cn1, hide, show, uuid } from "../utils";
import { FormObject } from "./formObject";

/** 設問DOMテンプレートのID */
const QUESTION_TEMPLATE_ID = 'form-question-template'

/** 1個上に移動ボタンのクラス名 */
const BUTTON_MOVE_UP_CLASSNAME = 'move-up'
/** 1個下に移動ボタンのクラス名 */
const BUTTON_MOVE_DOWN_CLASSNAME = 'move-down'
/** バルーン削除ボタンのクラス名 */
const BUTTON_REMOVE_CLASSNAME = 'move-remove'

function gprop(p: HTMLElement, name: string): HTMLInputElement {
    return p.querySelector(`[data-form-property="${name}"]`) as HTMLInputElement
}

export class Question extends BaseComponent {

    private _onMoveUp: (b: Question) => void = this.defaultOnMoveUp
    private _onMoveDown: (b: Question) => void = this.defaultOnMoveDown
    private _onRemove: () => void = this.defaultOnRemove

    constructor(f: FormObject) {
        super()

        // 設問要素DOM生成
        const e = this.createQuestionElement()

        // 保持
        this.setElement(e)

        // 画面に反映
        this.applyQuestion(f)

        // 表示
        show(e)
    }

    /**
     * 表示順表示を更新
     * 
     * @param n 順番
     */
    setOrderNumber(n: number): void {
        const numberElem = cn1(this.getElement(), 'question-number')
        if (numberElem) {
            numberElem.innerText = `Q${n}. `
        }
    }

    /**
     * 上下ボタンの有効/無効を設定
     * 
     * @param up 上ボタンが有効かどうか
     * @param down 下ボタンが有効かどうか
     */
    setUpDownEnabled(up: boolean, down: boolean): void {
        if (up) {
            cn1(this.getElement(), BUTTON_MOVE_UP_CLASSNAME)?.classList.remove('disabled')
        } else {
            cn1(this.getElement(), BUTTON_MOVE_UP_CLASSNAME)?.classList.add('disabled')
        }
        if (down) {
            cn1(this.getElement(), BUTTON_MOVE_DOWN_CLASSNAME)?.classList.remove('disabled')
        } else {
            cn1(this.getElement(), BUTTON_MOVE_DOWN_CLASSNAME)?.classList.add('disabled')
        }
    }

    /**
     * 上ボタンを押したときの処理を登録
     */
    setOnMoveUp(f: (b: Question) => void): void {
        this._onMoveUp = f
    }

    /**
     * 下ボタンを押したときの処理を登録
     */
    setOnMoveDown(f: (b: Question) => void): void {
        this._onMoveDown = f
    }

    /**
     * バルーンが削除されるときの処理
     */
    setOnRemoved(f: () => void): void {
        this._onRemove = f
    }

    /**
     * 入力データ検証
     */
    validate(): string | null {
        if (!gprop(this.getElement(), 'name').value) {
            // 設問名が入力されていない
            gprop(this.getElement(), 'name').classList.add('is-invalid')
            return '設問名を入力してください。'
        } else {
            gprop(this.getElement(), 'name').classList.remove('is-invalid')
        }

        const type = gprop(this.getElement(), 'type').value
        if (type === 'CHECKBOX' || type === 'RADIO') {
            // チェックボックス、またはラジオボタンなので、選択肢が入力されているか確認
            const selectList = gprop(this.getElement(), 'select-items-list')
            if (!selectList.value.trim()) {
                // 未入力じょん
                selectList.classList.add('is-invalid')
                return '選択肢リストをにゅうりょくしてください。'
            }
            selectList.classList.remove('is-invalid')
        }

        return null
    }

    /**
     * アンケートデータ生成
     * 
     * @returns 生成したアンケートデータ
     */
    buildFormJson(): FormObject | null {
        // 設問ID
        const id = this.getElement().getAttribute('data-question-id')
        // 設問名
        const name = gprop(this.getElement(), 'name').value
        // 設問の種別
        const type = gprop(this.getElement(), 'type').value
        // 必須チェック
        const required = gprop(this.getElement(), 'required').checked
        // 選択肢
        let values: string | null = null
        if (type === 'CHECKBOX' || type === 'RADIO') {
            values = gprop(this.getElement(), 'select-items-list').value
        }

        // 表示順は上位で設定するのでここでは設定しない
        return {
            id: id ?? '',
            name: name,
            type: type,
            required: required,
            values: values,
            order: null,
        }
    }

    /**
     * 設問データを画面に反映
     */
    private applyQuestion(f: FormObject) {
        const e = this.getElement()

        // 設問ID
        if (f.id) {
            e.setAttribute('data-question-id', f.id)
        } else {
            e.setAttribute('data-question-id', uuid())
        }

        // 必須かどうか
        if (f.required) {
            gprop(e, 'required').checked = true
        }

        // 設問名
        gprop(e, 'name').value = f.name
        this.onQuestionNameUpdated(f.name)

        // 設問の種別
        gprop(e, 'type').value = f.type
        this.onQuestionTypeUpdated(f.type)

        // ラジオ、チェックボックス時の選択肢
        gprop(e, 'select-items-list').value = f.values ?? ''
    }

    /**
     * 設問要素DOMを生成
     */
    private createQuestionElement(): HTMLElement {
        // 設問要素DOM生成
        const e = cloneNode(QUESTION_TEMPLATE_ID)
        if (!e) {
            // 要素が作成できない場合は例外にする
            throw 'Cannoe create question element.'
        }

        // 上ボタンを押したときの処理
        const upBtn = cn1(e, BUTTON_MOVE_UP_CLASSNAME)
        if (upBtn) {
            upBtn.addEventListener('click', () => this._onMoveUp(this))
        }
        // 下ボタンを押したときの処理
        const downBtn = cn1(e, BUTTON_MOVE_DOWN_CLASSNAME)
        if (downBtn) {
            downBtn.addEventListener('click', () => this._onMoveDown(this))
        }

        // 削除ボタンを押したときの処理
        const removeBtn = cn1(e, BUTTON_REMOVE_CLASSNAME)
        if (removeBtn) {
            removeBtn.addEventListener('click', () => {
                if (confirm('設問を削除しますか？')) {
                    this._onRemove()
                }
            })
        }

        const it = this
        gprop(e, 'name').addEventListener('change', function() { it.onQuestionNameUpdated(this.value) })
        gprop(e, 'type').addEventListener('change', function() { it.onQuestionTypeUpdated(this.value) })

        return e
    }

    /**
     * 設問名が更新されたときの処理
     */
    private onQuestionNameUpdated(name: string): void {
        const x = this.getElement().querySelector('header>span.question-name') as HTMLElement
        if (x) {
            if (name) {
                // 設問名をヘッダにも表示
                x.classList.remove('text-danger')
                x.innerText = name
            } else {
                // 設問名が未設定なので、入力してくれテキストを表示
                x.classList.add('text-danger')
                x.innerText = '※設問名を設定してください'
            }
        }
    }

    /**
     * 設問種別が変更されたときの処理
     */
    private onQuestionTypeUpdated(value: string): void {
        if (value === 'CHECKBOX' || value === 'RADIO') {
            // チェックボックス、もしくはラジオボタンの場合は選択肢を表示
            show(gprop(this.getElement(), 'selectable-items'))
        } else {
            // 上記以外は不要なので非表示
            hide(gprop(this.getElement(), 'selectable-items'))
        }
    }


    private defaultOnMoveUp(): void {
    }
    private defaultOnMoveDown(): void {
    }
    private defaultOnRemove(): void {
    }
}