import { ge } from "./ge"

/**
 * 要素のクローン
 *
 * @param id クローン元の要素のID
 */
export function cloneNode(id: string): HTMLElement | null {
    const orgElem = ge(id)
    if (!orgElem) {
        return null
    }
    const elem = orgElem.cloneNode(true) as HTMLElement
    elem.removeAttribute('id')
    elem.removeAttribute('tabindex')
    return elem
}
