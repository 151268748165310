/**
 * DOM要素を非表示に設定
 * 
 * @param element 非表示設定するDOM要素
 */
export function hide(element: HTMLElement | null): void {
    if (element) {
        element.classList.add('d-none')
        element.setAttribute('aria-hidden', 'true')
    }
}
