
export class BaseComponent {
    private _element: HTMLElement

    getElement(): HTMLElement {
        return this._element
    }

    protected setElement(element: HTMLElement): void {
        this._element = element
    }
}
