import { BaseComponent } from "../common/BaseComponent";
import instances from "../common/instances";
import { ImageSelector } from "../imageSelector";
import { ImageSelectOptions, SelectedImage } from "../imageSelector/types";
import { cloneNode, cn1, hide, show } from "../utils";
import { ActionArea } from "./ActionArea";
import { RichMenuObject } from "./richMenuObject";
import { getTemplateAction } from "./template";

/** リッチメニューエディタテンプレートDOMのID */
const RICH_MENU_EDITOR_TEMPLATE_ID = 'richmenu-editor-panel'
/** リッチメニューアクション設定リストコンテナのクラス名 */
const PANEL_ACTIONS_LIST_CLASSNAME = 'richmenu-actions-list-ul'

/**
 * リッチメニューエディタークラス
 */
export class RichMenuEditor extends BaseComponent {
    /** リッチメニューエディタ格納用のコンテナDOM */
    private _editor: HTMLElement
    /** リッチメニューアクション格納用のリストコンテナDOM */
    private _listContainer: HTMLElement

    /** 画像セレクタ */
    private _imageSelector: ImageSelector

    /** アクション領域 */
    private _areas: ActionArea[] = []


    private _areaData: RichMenuObject[] | null = null

    constructor(container: HTMLElement) {
        super()

        // エディタDOMをクローン
        const editor = cloneNode(RICH_MENU_EDITOR_TEMPLATE_ID)
        if (!editor) {
            return
        }
        // リッチメニューアクションリスト格納用コンテナを取得
        const ul = cn1(editor, PANEL_ACTIONS_LIST_CLASSNAME)
        if (!ul) {
            return
        }

        this._editor = editor
        this._listContainer = ul

        // 画像選択パネル初期化
        this.setupImageSelector(editor)
        // テンプレートが選択された時の処理を初期化
        this.setupTemplateSelected(editor)

        // エディタ用コンテナに追加
        container.appendChild(editor)
        // エディタ表示
        show(editor)
    }

    /**
     * リッチメニューエディタ初期化
     * 
     * @param imageData リッチメニュー画像データ
     * @param richMenuJson リッチメニューアクション定義JSON
     */
    init(imageData: string, richMenuJson: string): void {
        // リッチメニューJSONが指定されていれば、画像を読み込んだ後に反映するので保持しておく
        if (richMenuJson) {
            this._areaData = JSON.parse(richMenuJson)
        }

        // 画像があれば、エディタに反映
        if (imageData) {
            this._imageSelector.setImageData(imageData)
        } else {
            this._imageSelector.clearImageData()
        }
    }

    /**
     * リッチメニュー定義データ作成
     */
    buildActionAreasObject(): RichMenuObject[] {
        return this._areas.map(t => t.buildActionObject())
    }

    /**
     * 画像情報を取得
     */
    getImageData(): SelectedImage {
        return this._imageSelector.getImageData()
    }

    /**
     * リッチメニューエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     */
    static getOrCreateInstance(containerElement: HTMLElement): RichMenuEditor {
        let obj: BaseComponent | null  = instances.get(containerElement)
        if (!obj) {
            obj = new RichMenuEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as RichMenuEditor
    }

    /**
     * 画像選択パネルの初期化
     * 
     * @param editor 
     */
    private setupImageSelector(editor: HTMLElement): void {
        // 画像選択パネルを取得
        const imagePanel = cn1(editor, 'richmenu-image-container')
        if (!imagePanel) {
            return
        }

        // 画像セレクタのインスタンスを作成
        const selector = ImageSelector.getOrCreateInstance(imagePanel)
        // 初期化用オプション生成
        const opts: ImageSelectOptions = {
            imgClassName: 'richmenu-selected-image',
            uploadContainerClassName: 'richmenu-upload-container',
            imageViewerClassName: 'richmenu-selected-container',
        }
        // 初期化
        selector.init(opts)

        // 画像ロード完了時
        selector.setOnImageLoaded(() => this.onImageLoaded())

        // インスタンスを保持
        this._imageSelector = selector
    }

    /**
     * テンプレートから選択ボタン初期化
     */
    private setupTemplateSelected(editor: HTMLElement): void {
        // モーダルDOM取得
        const modal = document.getElementById('actionSelectModal')
        if (!modal) {
            return
        }

        // テンプレートリンクを取得して、クリック時のハンドラを登録
        modal.querySelectorAll('a.action-template-link').forEach(b => b.addEventListener('click', evt => this.onTemplateSelected(b)))
    }

    /**
     * リッチメニューアクション定義を画面に反映
     * 
     * @param actions アクション領域定義
     */
    private applyActionPanels(actions: RichMenuObject[]): void {
        // 画像サイズを取得しておく
        const imageSize = this._imageSelector.getImageSizes()

        // 画像ビューワを取得
        const viewer = cn1(this._editor, 'richmenu-image-viewer')

        for (let n = 0; n < actions.length; n++) {
            // 領域データインスタンス作成
            const area = new ActionArea()

            // 定義内容を反映
            area.apply(n + 1, actions[n], imageSize)

            // 内部配列に保持
            this._areas.push(area)
            // 領域定義設定パネルDOMも追加
            this._listContainer.appendChild(area.getActionPanel())
            // 領域表示枠も追加
            viewer?.appendChild(area.getBoundBox())
        }

        this.updateActionListVisible()
    }

    /**
     * 定義済みアクションをクリア
     */
    private clearActions(): void {
        // 画像ビューワを取得
        const viewer = cn1(this._editor, 'richmenu-image-viewer')
        for (const a of this._areas) {
            // 枠を削除
            viewer?.removeChild(a.getBoundBox())
            // アクション定義DOMを削除
            this._listContainer.removeChild(a.getActionPanel())
        }
        // 内部配列もクリア
        this._areas = []
    }

    /**
     * リッチメニューの画像がロード完了した
     */
    private onImageLoaded(): void {
        if (this._areaData) {
            // リッチメニュー画像のロードが完了したので、保持しておいたアクション定義を反映
            this.applyActionPanels(this._areaData)
            // データはクリアしておく
            this._areaData = null
        }
    }

    /**
     * テンプレート洗濯時の処理
     */
    private onTemplateSelected(link: Element): void {
        // テンプレートの種別を取得
        const type = link.getAttribute('data-template-type') ?? ''
        // 画像サイズを取得しておく
        const imageSize = this._imageSelector.getImageSizes()
        // 選択したテンプレートの情報をゲット
        const templateActions = getTemplateAction(type, imageSize)
        if (!templateActions) {
            return
        }

        // 定義済みアクションをクリア
        this.clearActions()

        // テンプレートのアクションを反映
        this.applyActionPanels(templateActions)
    }

    /**
     * アクションリスト表示更新
     */
    private updateActionListVisible(): void {
        if  (this._areas.length > 0) {
            hide(cn1(this._editor, 'richmenu-actions-empty'))
            show(cn1(this._editor, 'richmenu-actions-list'))
        } else {
            hide(cn1(this._editor, 'richmenu-actions-list'))
            show(cn1(this._editor, 'richmenu-actions-empty'))
        }
    }
}