import { BaseComponent } from "../common/BaseComponent"
import { cloneNode, cn1, hide, show } from "../utils"
import { Button } from "./button"
import { Image } from "./image"
import { BlockItem } from "./messageObject"
import { Text } from "./text"

/** ブロック要素DOMテンプレートのID */
const BLOCK_TEMPLATE_ID = 'message-block-template'
/** ブロックデータを格納するコンテナDOMクラス名 */
const BLOCK_BODY_CLASSNAME = 'line-block-body'
/** テキスト追加ボタンのクラス名 */
const BUTTON_ADD_TEXT_CLASSNAME = 'data-add-block-text'
/** 画像追加ボタンのクラス名 */
const BUTTON_ADD_IMAGE_CLASSNAME = 'data-add-block-image'
/** ボタン追加ボタンのクラス名 */
const BUTTON_ADD_BUTTON_CLASSNAME = 'data-add-block-button'
/** ブロック削除ボタンのクラス名 */
const BUTTON_REMOVE_CLASSNAME = 'data-add-block-remove'

/**
 * 吹き出し1つの中にあるメッセージブロック1つを定義
 */
export class Block extends BaseComponent {
    private _type: string = ''

    private _text: Text
    private _button: Button
    private _image: Image

    private _onRemovedHandler: () => void = this.defaultOnRemovedHandler

    constructor() {
        super()

        // ブロック要素DOM生成
        const e = this.createBlockElement()

        // 保持
        this.setElement(e)

        // ブロックデータ格納用のコンテナ要素を取得
        const c = cn1(e, BLOCK_BODY_CLASSNAME)
        if (!c) {
            // コンテナが取得できない場合はエラー
            throw 'Cannot find block container element.'
        }

        // テキストブロック保持
        this._text = new Text(c)
        this.setupText()

        // ボタンブロック保持
        this._button = new Button(c)
        this.setupButton()

        // 画像ブロック保持
        this._image = new Image(c)
        this.setupImage()

        // 表示
        show(e)
    }

    /**
     * ブロックをテキストとして設定
     */
    applyText(data: BlockItem): void {
        this._type = 'text'
        // テキストを反映
        this._text.apply(data)
    }

    /**
     * ブロックを画像として設定
     */
    applyImage(data: BlockItem): void {
        this._type = 'image'
        // 画像を反映
        this._image.apply(data)
    }

    /**
     * ブロックをボタンとして設定
     */
    applyButton(data: BlockItem): void {
        this._type = 'button'
        // ボタン情報を反映
        this._button.apply(data)
    }

    /**
     * このブロックが削除されるときにコールされるハンドラを登録
     */
    setOnRemovedHandler(handler: () => void): void {
        this._onRemovedHandler = handler
    }



    /**
     * 削除ボタンを非表示に
     */
    hideRemoveButton(): void {
        const btn = cn1(this.getElement(), BUTTON_REMOVE_CLASSNAME)
        if (btn) {
            hide(btn)
        }
    }

    /**
     * 削除ボタンを表示
     */
    showRemoveButton(): void {
        const btn = cn1(this.getElement(), BUTTON_REMOVE_CLASSNAME)
        if (btn) {
            show(btn)
        }
    }

    /**
     * 「～追加」のボタン表示更新
     */
    updateAddButtons(): void {
        if (this._type === 'text') {
            // テキスト設定済みなので、画像追加とボタン追加は非表示
            show(cn1(this.getElement(), BUTTON_ADD_TEXT_CLASSNAME)!!)
            hide(cn1(this.getElement(), BUTTON_ADD_IMAGE_CLASSNAME)!!)
            hide(cn1(this.getElement(), BUTTON_ADD_BUTTON_CLASSNAME)!!)
        } else if (this._type === 'image') {
            // 画像設定済みなので、何も表示しない
            hide(cn1(this.getElement(), BUTTON_ADD_TEXT_CLASSNAME)!!)
            hide(cn1(this.getElement(), BUTTON_ADD_IMAGE_CLASSNAME)!!)
            hide(cn1(this.getElement(), BUTTON_ADD_BUTTON_CLASSNAME)!!)
        } else if (this._type === 'button') {
            // ボタン設定済みなので、何も表示しない
            hide(cn1(this.getElement(), BUTTON_ADD_TEXT_CLASSNAME)!!)
            hide(cn1(this.getElement(), BUTTON_ADD_IMAGE_CLASSNAME)!!)
            hide(cn1(this.getElement(), BUTTON_ADD_BUTTON_CLASSNAME)!!)
        } else {
            // それ以外は未設定なので、全部表示
            show(cn1(this.getElement(), BUTTON_ADD_TEXT_CLASSNAME)!!)
            show(cn1(this.getElement(), BUTTON_ADD_IMAGE_CLASSNAME)!!)
            show(cn1(this.getElement(), BUTTON_ADD_BUTTON_CLASSNAME)!!)
        }
    }
    

    /**
     * JSON用オブジェクトを取得
     */
    buildFlexObject(): BlockItem {
        if (this._type === 'text') {
            return this._text.buildFlexObject()
        } else if (this._type === 'button') {
            return this._button.buildFlexObject()
        } else if (this._type === 'image') {
            return this._image.buildFlexObject()
        } else {
            return {
                type: this._type
            }
        }
    }

    /**
     * ブロックDOM要素を作成
     */
    private createBlockElement(): HTMLElement {
        // ブロック要素DOM生成
        const e = cloneNode(BLOCK_TEMPLATE_ID)
        if (!e) {
            // BLOCK要素が作成できない場合は例外にする
            throw 'Cannot create BLOCK element.'
        }

        // ランダムIDを設定
        e.id = 'block-' + crypto.randomUUID()
        // テキスト追加ボタンを押されたときの処理
        cn1(e, BUTTON_ADD_TEXT_CLASSNAME)?.addEventListener('click', () => this._text.addNewText())
        // 画像追加ボタンを押されたときの処理
        cn1(e, BUTTON_ADD_IMAGE_CLASSNAME)?.addEventListener('click', () => this._image.addNewImage())
        // ボタン追加ボタンを押されたときの処理
        cn1(e, BUTTON_ADD_BUTTON_CLASSNAME)?.addEventListener('click', () => this._button.addNewButton())
        // 削除ボタンが押されたときの処理
        cn1(e, BUTTON_REMOVE_CLASSNAME)?.addEventListener('click', () => this.onBlockRemove())

        return e
    }

    /**
     * テキストブロックのセットアップ
     */
    private setupText(): void {
        // テキストが変更された場合の処理
        this._text.setOnTextChanged(() => {
            if (this._text.isEmpty()) {
                // テキストが空欄になったので、typeをクリア
                this._type = ''
            } else {
                this._type = 'text'
            }
            this.updateAddButtons()
        })
    }

    /**
     * 画像ブロックのためのセットアップ
     */
    private setupImage(): void {
        // 画像が変更されたときの処理を東郎
        this._image.setOnImageChanged(() => {
            this._type = 'image'
            this.updateAddButtons()
        })
    }

    /**
     * ボタンブロックのためのセットアップ
     */
    private setupButton(): void {
        // ボタンが変更されたときの処理を登録
        this._button.setOnButtonChanged(() => {
            this._type = 'button'
            this.updateAddButtons()
        })
    }

    /**
     * ブロック削除ボタンが押されたときの処理
     */
    private onBlockRemove() {
        if (confirm('ブロックを削除しますか？')) {
            // ブロック削除確認OKなので、登録されたイベントハンドラをコール
            this._onRemovedHandler()
        }
    }


    /**
     * ブロック削除時のイベントハンドラが未登録時に実行される
     */
    private defaultOnRemovedHandler(): void {
        console.dir('block removed.')
    }
}
