import { DateTime, TempusDominus } from "@eonasdan/tempus-dominus"
import locale from '../locale'

/**
 * 日付入力INPUT
 */
export class DateInput {
    private _input: HTMLInputElement
    private _date: TempusDominus

    constructor(input: HTMLInputElement) {
        this._input = input

        // tempus-dominusのDatePickerを適用
        this.applyDatePicker()

        input.addEventListener('change', () => this.onDateChanged())
    }

    setDate(date: Date | string | null | undefined): void {
        if (date instanceof Date) {
            this._date.dates.setValue(new DateTime(date))
        } else if (typeof date === 'string') {
            if (date.length > 0) {
                this._date.dates.setValue(new DateTime(date))
            } else {
                this._date.clear()
            }
        } else {
            this._date.clear()
        }
    }
    
    clear(): void {
        this._date.clear()
    }

    private applyDatePicker() {
        this._date = new TempusDominus(this._input, {
            display: {
                buttons: {
                    today: true
                },
                components: {
                    clock: false
                }
            },
            localization: locale.dateTimePicker,
        })
    }

    private onDateChanged() {
        const v = this._input.value
        if (v.length == 0) {
            return
        }
        const d = this._date.dates.parseInput(v)
        if (!d) {
            this._date.clear()
        }
    }
}