import bootstrap from 'bootstrap'
import { TagCondition } from './ConditionObject'
import { cn1, ge, hide, show } from '../utils'

const TAG_SELECTOR_MODAL_ID = 'select-tag-modal'

/**
 * タグ選択ダイアログ
 */
export class TagSelector {
    private _modal: bootstrap.Modal

    constructor(tag: TagCondition) {
        if (!TagSelector.editorElementInitialized) {
            TagSelector.editorElementInitialized = true
            TagSelector.setupTagSelector()
        }

        // 現在の選択をダイアログに反映
        this.applyTag(tag)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(TagSelector.editorElement)

        // ダイアログが閉じられたら破棄するように
        TagSelector.editorClosedHandler = () => {
            this._modal.dispose()
        }
    }

    /**
     * タグ選択ダイアログを表示
     * 
     * @param confirmed タグ選択を確定したときの処理
     */
    show(confirmed: (tag: TagCondition) => void): void {
        // ダイアログを決定した際の処理を登録しておく
        TagSelector.editorConfirmedHandler = () => {
            // タグ条件情報生成
            const obj = this.buildTagCondition()
            // ダイアログは閉じておく
            this._modal.hide()
            // 選択時の処理を実行
            confirmed(obj)
        }
        // ダイアログ表示
        this._modal.show()
    }

    /**
     * タグ設定をダイアログに反映
     */
    private applyTag(tag: TagCondition): void {
        // 一旦クリア
        this.clear()

        // 条件を反映
        const form = TagSelector.editorElement.getElementsByTagName('form')[0]
        form['tag-condition-match'].value = tag.match

        // タグ選択を反映
        tag.tags.forEach(t => {
            const x = ge('tag-' + t) as HTMLInputElement
            if (x) {
                x.checked = true
            }
        })
    }

    /**
     * ダイアログの設定内容からタグ情報を生成
     */
    private buildTagCondition(): TagCondition {
        const form = TagSelector.editorElement.getElementsByTagName('form')[0]

        const tagChecks = form.getElementsByClassName('tag-check')
        const tags: string[] = []
        for (const c of tagChecks) {
            if (c instanceof HTMLInputElement && c.checked) {
                tags.push(c.value)
            }
        }

        return {
            match: form['tag-condition-match'].value,
            tags: tags,
        }
    }

    /**
     * ダイアログに反映しているタグ等をクリア
     */
    private clear(): void {
        const tagChecks = TagSelector.editorElement.getElementsByClassName('tag-check')
        for (const t of tagChecks) {
            if (t instanceof HTMLInputElement) {
                t.checked = false
            }
        }
    }

    /**
     * タグが選択されているか確認
     */
    private static checkTagsSelected(dialog: HTMLElement): boolean {
        const form = dialog.getElementsByTagName('form')[0]
        let checked = false
        for (let c of form.getElementsByClassName('tag-check')) {
            if (c instanceof HTMLInputElement && c.checked) {
                checked = true
                break
            }
        }

        const m = cn1(dialog, 'tag-select-message')
        if (checked) {
            if (m) { hide(m) }
        } else {
            if (m) { show(m) }
        }

        return checked
    }

    /**
     * タグ選択ダイアログ初期化
     */
    private static setupTagSelector(): void {
        // 確定ボタンが押された時の処理
        cn1(this.editorElement, 'btn-tag-select-confirm')?.addEventListener('click', () => {
            if (!this.checkTagsSelected(this.editorElement)) {
                return
            }
            if (this.editorConfirmedHandler) {
                this.editorConfirmedHandler()
                this.editorConfirmedHandler = undefined
            }
        })
        // ダイアログが閉じられたときの処理
        this.editorElement.addEventListener('hidden.bs.modal', () => {
            if (this.editorClosedHandler) {
                this.editorClosedHandler()
                this.editorClosedHandler = undefined
            }
        })
    }

    private static editorConfirmedHandler?: () => void

    private static editorClosedHandler?: () => void

    /** ボタン編集ダイアログDOM */
    private static editorElement: HTMLElement = document.getElementById(TAG_SELECTOR_MODAL_ID)!!

    /** ボタン編集ダイアログ初期化済みか */
    private static editorElementInitialized: boolean = false
}