import { RICHMENU_ACTION_TYPES } from "../common/types";
import { BaseAction } from "./BaseAction";
import { RichMenuObject } from "./richMenuObject";

/**
 * リッチメニューアクション - LINEリンク
 */
export class BuiltinLink extends BaseAction {
    private _selector: HTMLSelectElement

    constructor(parent: HTMLElement) {
        super(parent, 'richmenu-builtin-link-actions', RICHMENU_ACTION_TYPES.BUILTIN_LINK)

        const s = this._container.getElementsByTagName('select')
        if (!s) {
            throw 'Cannot find postback selector.'
        }
        this._selector = s[0]
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: RichMenuObject): void {
        this._selector.value = action.builtinLinkType ?? 'NONE'
    }

    /**
     * アクション固有のデータをアクション定義に反映
     */
    buildActionObject(obj: RichMenuObject): void {
        // LINEリンクの種別を反映
        obj.builtinLinkType = this._selector.value
    }
}