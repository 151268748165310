import { BaseComponent } from "../common/BaseComponent";
import { cloneNode, cn1, hide, show } from "../utils";
import { BaseCondition, TypedBaseCondition } from "./BaseCondition";
import { BirthdayConditionPanel } from "./BirthdayConditionPanel";
import { ConditionObject } from "./ConditionObject";
import { FollowConditionPanel } from "./FollowConditionPanel";
import { GenderConditionPanel } from "./GenderConditionPanel";
import { PrefectureConditionPanel } from "./PrefectureConditionPanel";
import { RouteConditionPanel } from "./RouteConditionPanel";
import { TagConditionPanel } from "./TagConditionPanel";

/** グループ条件パネルテンプレートのID */
const GROUP_EDITOR_TEMPLATE_ID = "condition-group-template"
/** 条件リスト格納パネルのクラス名 */
const PANEL_CONDITIONS_CLASS_NAME = "conditions-panel"
/** 条件未作成メッセージのクラス名 */
const PANEL_EMPTY_CLASS_NAME = "empty-condition-panel"
/** タグを追加ボタンのクラス名 */
const BUTTON_ADD_TAG_CLASS_NAME = "add-tag"
/** 友だち登録日追加ボタンのクラス名 */
const BUTTON_ADD_FOLLOW_DATE_CLASS_NAME = "add-follow-date"
/** 登録経路追加ボタンのクラス名 */
const BUTTON_ADD_ROUTE_CLASS_NAME = "add-route"
/** 居住地追加ボタンのクラス名 */
const BUTTON_ADD_PREFS_CLASS_NAME = "add-prefecture"
/** 誕生日追加ボタンのクラス名 */
const BUTTON_ADD_BIRTHDAY_CLASS_NAME = 'add-birthday'
/** 性別追加ボタンのクラス名 */
const BUTTON_ADD_GENDER_CLASS_NAME = 'add-gender'

/**
 * グループ条件
 */
export class GroupCondition extends BaseComponent {
    private _conditionsPanel: HTMLElement
    private _emptyPanel: HTMLElement

    private _conditions: BaseCondition[] = []

    private _readOnly: boolean

    private _onGroupRemoved: () => void = this.defaultOnGroupRemoved

    constructor(readOnly: boolean) {
        super()

        // グループ条件を格納するDOMを生成
        const panel = this.createElement(readOnly)

        // 各種条件を入れるコンテナ取得
        const conditions = cn1(panel, PANEL_CONDITIONS_CLASS_NAME)
        if (!conditions) {
            return
        }

        // 条件なしメッセージDOM
        const empty = cn1(panel, PANEL_EMPTY_CLASS_NAME)
        if (!empty) {
            return
        }

        this.setElement(panel)
        this._conditionsPanel = conditions
        this._emptyPanel = empty
        this._readOnly = readOnly

        show(panel)
    }

    /**
     * 
     * @param cond 
     */
    apply(cond: ConditionObject): void {
        if (cond.tags) {
            this.applyCondition(cond.tags, new TagConditionPanel(this._readOnly))
        }
        if (cond.follows) {
            this.applyCondition(cond.follows, new FollowConditionPanel(this._readOnly))
        }
        if (cond.routes) {
            this.applyCondition(cond.routes, new RouteConditionPanel(this._readOnly))
        }
        if (cond.prefectures) {
            this.applyCondition(cond.prefectures, new PrefectureConditionPanel(this._readOnly))
        }
        if (cond.birthday) {
            this.applyCondition(cond.birthday, new BirthdayConditionPanel(this._readOnly))
        }
        if (cond.genders) {
            this.applyCondition(cond.genders, new GenderConditionPanel(this._readOnly))
        }
        this.updateConditionsVisible()
    }

    /**
     * 条件データ生成
     */
    buildConditionJson(): ConditionObject {
        const r: ConditionObject = {}

        for (const c of this._conditions) {
            if (c instanceof TagConditionPanel) {
                r.tags = c.buildConditionJson()
            } else if (c instanceof FollowConditionPanel) {
                r.follows = c.buildConditionJson()
            } else if (c instanceof RouteConditionPanel) {
                r.routes = c.buildConditionJson()
            } else if (c instanceof PrefectureConditionPanel) {
                r.prefectures = c.buildConditionJson()
            } else if (c instanceof BirthdayConditionPanel) {
                r.birthday = c.buildConditionJson()
            } else if (c instanceof GenderConditionPanel) {
                r.genders = c.buildConditionJson()
            }
        }

        return r
    }

    /**
     * 条件番号のラベルを設定
     * 
     * @param n 番号
     */
    setNumberLabel(n: number): void {
        const span = cn1(this.getElement(), 'number')
        if (span) {
            span.innerText = n.toString()
        }
    }

    /**
     * グループ条件削除時のハンドラを登録
     * 
     * @param f 削除されたときに実行する処理
     */
    setOnGroupRemoved(f: () => void): void {
        this._onGroupRemoved = f
    }

    /**
     * 各条件を画面に反映
     * 
     * @param condition 条件データ
     * @param p 条件パネル
     */
    private applyCondition<T>(condition: T, p: TypedBaseCondition<T>): void {
        p.apply(condition)

        p.setOnRemoved(() => this.onConditionRemoved(p))
        p.setOnEditConfirmed(() => this.onConditionEditoConfirmed(p))

        // 内部配列に追加
        this._conditions.push(p)
        // 画面にも追加
        this._conditionsPanel.appendChild(p.getConditionPanel())
    }

    /**
     * 新規条件作成
     * 
     * @param condition 新規条件データ
     * @param p 条件パネル
     */
    private addNewCondition<T>(condition: T, p: TypedBaseCondition<T>): void {
        p.apply(condition)

        p.setOnRemoved(() => this.onConditionRemoved(p))
        p.setOnEditConfirmed(() => this.onConditionEditoConfirmed(p))

        p.onEdit()
    }

    /**
     * 条件編集がOKの時の処理
     */
    private onConditionEditoConfirmed(editor: BaseCondition): void {
        if (this._conditions.find(x => x == editor)) {
            // すでに保持している条件情報なので何もしない
            return
        }

        // 内部配列に追加
        this._conditions.push(editor)
        // 画面にも追加
        this._conditionsPanel.appendChild(editor.getConditionPanel())

        // 表示更新
        this.updateConditionsVisible()
    }

    /**
     * 配信グループ条件が削除された時の処理
     * 
     * @param condition 削除された条件情報
     */
    private onConditionRemoved(editor: BaseCondition): void {
        this._conditions = this._conditions.filter(t => t !== editor)
        this._conditionsPanel.removeChild(editor.getConditionPanel())

        this.updateConditionsVisible()
    }

    /**
     * 条件表示更新
     */
    private updateConditionsVisible(): void {
        // 条件グループパネルの表示非表示
        if (this._conditions.length > 0) {
            hide(this._emptyPanel)
            show(this._conditionsPanel)
        } else {
            hide(this._conditionsPanel)
            show(this._emptyPanel)
        }

        // 条件を追加ドロップダウンメニューの表示更新
        const buttons = this.getElement().querySelectorAll('.form-buttons .dropdown-item')
        const types = this._conditions.map(t => t.getType())
        buttons.forEach(a => {
            const type = a.getAttribute('data-condition-type')
            if (types.find(t => t === type)) {
                a.classList.add('disabled')
            } else {
                a.classList.remove('disabled')
            }
        })
    }

    /**
     * グループ条件の削除ボタンが押されたとき
     */
    private onGroupRemove(): void {
        if (confirm('グループ条件を削除しますか？')) {
            this._onGroupRemoved()
        }
    }

    private createElement(readOnly: boolean): HTMLElement {
        const panel = cloneNode(GROUP_EDITOR_TEMPLATE_ID)
        if (!panel) {
            throw 'Cannot create GroupEditor panel.'
        }

        if (readOnly) {
            // 読み取り専用モードなので、各ボタンは削除
            this.deleteButtons(panel)
        } else {
            // 通常モードなので、各ボタンのハンドラを登録
            this.setupButtons(panel)
        }

        return panel
    }

    /**
     * 各ボタンのイベントハンドラ等登録
     */
    private setupButtons(panel: HTMLElement): void {
        // タグを追加ボタン
        const addTag = cn1(panel, BUTTON_ADD_TAG_CLASS_NAME)
        if (addTag) {
            addTag.addEventListener(
                'click',
                () => this.addNewCondition({conditions: []}, new TagConditionPanel(this._readOnly))
            )
        }
        // 友だち登録日追加ボタン
        const addFollowDate = cn1(panel, BUTTON_ADD_FOLLOW_DATE_CLASS_NAME)
        if (addFollowDate) {
            addFollowDate.addEventListener(
                'click',
                () => this.addNewCondition({mode: "date", conditions: []}, new FollowConditionPanel(this._readOnly))
            )
        }
        // 登録経路追加ボタン
        const addRoute = cn1(panel, BUTTON_ADD_ROUTE_CLASS_NAME)
        if (addRoute) {
            addRoute.addEventListener(
                'click',
                 () => this.addNewCondition({conditions: []}, new RouteConditionPanel(this._readOnly))
            )
        }
        // 居住地追加ボタン
        const addPrefecture = cn1(panel, BUTTON_ADD_PREFS_CLASS_NAME)
        if (addPrefecture) {
            addPrefecture.addEventListener(
                'click',
                () => this.addNewCondition({conditions: []}, new PrefectureConditionPanel(this._readOnly))
            )
        }
        // 誕生日追加ボタン
        const addBirthday = cn1(panel, BUTTON_ADD_BIRTHDAY_CLASS_NAME)
        if (addBirthday) {
            addBirthday.addEventListener(
                'click',
                () => this.addNewCondition({conditions: []}, new BirthdayConditionPanel(this._readOnly))
            )
        }
        // 性別追加ボタン
        const addGender = cn1(panel, BUTTON_ADD_GENDER_CLASS_NAME)
        if (addGender) {
            addGender.addEventListener(
                'click',
                () => this.addNewCondition({conditions: []}, new GenderConditionPanel(this._readOnly))
            )
        }

        // 削除ボタン
        const remove = cn1(panel, 'delete')
        if (remove) {
            remove.addEventListener('click', () => this.onGroupRemove())
        }
    }

    /**
     * 読み取りモードのためボタン削除
     */
    private deleteButtons(panel: HTMLElement): void {
        // 条件を追加ボタンを削除
        const addBtn = cn1(panel, "form-buttons")
        if (addBtn) {
            addBtn.parentElement?.removeChild(addBtn)
        }

        // 削除ボタンを削除
        const remove = cn1(panel, 'delete')
        if (remove) {
            remove.parentElement?.removeChild(remove)
        }
    }

    private defaultOnGroupRemoved(): void {
    }
}