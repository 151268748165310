import { RICHMENU_ACTION_TYPES } from "../common/types";
import { ImageSizeInfo } from "../imageSelector";
import { RichMenuObject } from "./richMenuObject";

/**
 * 全面1個だけの領域を作成
 */
function generate1x1Object(imageSize: ImageSizeInfo): RichMenuObject[] {
    return [{ top: 0, left: 0, width: imageSize.width, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE }]
}

/**
 * 3x2で均等に分けた領域を作成
 */
function generate3x2Object(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x2分割の左に2x2、右に1x1を2つの計3つの領域を作成
 */
function generate3x2FObject(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: sx*2, height: sy*2, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x2分割の左に1x2、右に1x1を4つの計5つの領域を作成
 */
function generate3x2AObject(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: sx, height: sy*2, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x2分割の左に1x1を2つ、中央の1x2を1つ、右に1x1を4つの計5つの領域を作成
 */
function generate3x2BObject(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x2分割の上に1x1を3つ、下に3x1の横長を1つの計4つの領域を作成
 */
function generate3x2DObject(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: 0, width: imageSize.width, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x2分割の上に3x1の横長を1つ、下に1x1を3つの計4つの領域を作成
 */
function generate3x2EObject(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: imageSize.width, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx*2, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 2x2で均等に分けた領域を作成
 */
function generate2x2Object(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 2
    const sy = imageSize.height / 2
    return [
        {top: 0, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: 0, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: sy, left: sx, width: sx, height: sy, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x1で均等に分けた横並びな計3つの領域を作成
 */
function generate3x1Object(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    return [
        {top: 0, left: 0, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 2x1で均等に分けた横並びな計2つの領域を作成
 */
function generate2x1Object(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 2
    return [
        {top: 0, left: 0, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * 3x1分割で、左から2:1なサイズの計2つの領域を作成
 */
function generate3x1AObject(imageSize: ImageSizeInfo): RichMenuObject[] {
    const sx = imageSize.width / 3
    return [
        {top: 0, left: 0, width: sx*2, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
        {top: 0, left: sx*2, width: sx, height: imageSize.height, actionType: RICHMENU_ACTION_TYPES.NONE},
    ]
}

/**
 * テンプレートの領域を取得
 * 
 * @param type 選択したテンプレートのタイプ
 * @param imageSize 画像サイズ
 */
export function getTemplateAction(type: string, imageSize: ImageSizeInfo): RichMenuObject[] {
    if (type === '1x1') {
        return generate1x1Object(imageSize)
    } else if (type === '3x2') {
        return generate3x2Object(imageSize)
    } else if (type === '3x2-F') {
        return generate3x2FObject(imageSize)
    } else if (type === '3x2-A') {
        return generate3x2AObject(imageSize)
    } else if (type === '3x2-B') {
        return generate3x2BObject(imageSize)
    } else if (type === '3x2-D') {
        return generate3x2DObject(imageSize)
    } else if (type === '3x2-E') {
        return generate3x2EObject(imageSize)
    } else if (type === '2x2') {
        return generate2x2Object(imageSize)
    } else if (type === '3x1') {
        return generate3x1Object(imageSize)
    } else if (type === '2x1') {
        return generate2x1Object(imageSize)
    } else if (type === '3x1-A') {
        return generate3x1AObject(imageSize)
    }

    return []
}