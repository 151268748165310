import { cloneNode, cn1, show } from "../utils"

/** 条件パネルのID */
const PANEL_CONDITION_CONTAINER_ID = 'condition-panel'

/**
 * 配信グループ条件ベース
 */
export abstract class BaseCondition {
    private _container: HTMLElement
    protected _body: HTMLElement

    private _onRemoveHandler = this.defaultOnRemove
    private _onEditConfirmed: () => void = this.defaultOnEditConfirmed

    constructor() {
        this._container = this.createPanelElement()

        const b = cn1(this._container, 'card-body')
        if (!b) {
            throw 'Cannot find condition body'
        }

        this._body = b

        show(this._container)
    }

    /**
     * 条件名称
     */
    abstract getName(): string

    /**
     * 編集
     */
    abstract onEdit(): void

    /**
     * 条件設定パネルのDOMを取得
     */
    getConditionPanel(): HTMLElement {
        return this._container
    }

    /**
     * 条件編集確定時のハンドラを登録
     * 
     * @param f 編集OKのときに実行する処理
     */
    setOnEditConfirmed(f: () => void): void {
        this._onEditConfirmed = f
    }

    /**
     * 条件削除時のハンドラを登録
     * 
     * @param f 削除されたときに実行する処理
     */
    setOnRemoved(f: () => void): void {
        this._onRemoveHandler = f
    }

    protected raiseOnEditConfirmed() {
        this._onEditConfirmed()
    }

    private onRemove(): void {
        if (confirm(`条件[${this.getName()}]を削除しますか？`)) {
            this._onRemoveHandler()
        }
    }

    private createPanelElement(): HTMLElement {
        const p = cloneNode(PANEL_CONDITION_CONTAINER_ID)
        if (!p) {
            throw 'Cannot create condition container.'
        }

        // ヘッダのテキスト
        const h = cn1(p, 'condition-name')
        if (h) {
            h.innerText = this.getName()
        }

        // 編集ボタン
        const editBtn = cn1(p, 'edit')
        if (editBtn) {
            editBtn.addEventListener('click', () => this.onEdit())
        }

        // 削除ボタン
        const deleteBtn = cn1(p, 'delete')
        if (deleteBtn) {
            deleteBtn.addEventListener('click', () => this.onRemove())
        }

        return p
    }


    private defaultOnRemove(): void {
    }

    private defaultOnEditConfirmed(): void {
    }
}

/**
 * 配信グループ条件ベース
 */
export abstract class TypedBaseCondition<C> extends BaseCondition {
    constructor() {
        super()
    }

    /**
     * 検索条件を画面に反映
     * 
     * @param condition 条件情報
     */
    abstract apply(condition: C): void
}