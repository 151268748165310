import { Locale } from "./types";

export const ja: Locale = {
    dateTimePicker: {
        today: '今日',
        selectMonth: '月を選択',
        previousMonth: '前の月へ',
        nextMonth: '次の月へ',
        selectYear: '年を選択',
        previousYear: '前の年へ',
        nextYear: '次の年へ',
        selectDecade: '10年単位で選択',
        previousDecade: '前の10年へ',
        nextDecade: '次の10年へ',
        previousCentury: '前の100年へ',
        nextCentury: '次の100年へ',
        locale: 'ja',
        dayViewHeaderFormat: {
            month: 'long',
            year: 'numeric'
        },
        dateFormats: {
            L: 'yyyy/MM/dd'
        },
        format: 'L'
    }
}