function validHour(h: string): Number {
    if (h.length == 0 || h.length > 2) {
        return NaN
    }
    const n = Number.parseInt(h, 10)
    if (Number.isNaN(n)) {
        return NaN
    }
    if (n >= 0 && n <= 23) {
        return n
    } else {
        return NaN
    }
}

function validMinute(m: string): Number {
    if (m.length == 0 || m.length > 2) {
        return NaN
    }
    const n = Number.parseInt(m, 10)
    if (Number.isNaN(n)) {
        return NaN
    }
    if (n >= 0 && n <= 59) {
        return n
    } else {
        return NaN
    }
}

export class TimeInput {
    private _input: HTMLInputElement

    constructor(input: HTMLInputElement) {
        this._input = input

        input.addEventListener('change', () => this.onChange())
    }

    clear(): void {
        this._input.value = ''
    }

    private onChange(): void {
        const v = this._input.value
        const hhmm = this.parse(this._input.value)
        if (hhmm) {
            const hh = ('00' + hhmm.h).slice(-2)
            const mm = ('00' + hhmm.m).slice(-2)
            this._input.value = `${hh}:${mm}`
        } else {
            this._input.value = ''
        }
    }

    private parse(v: string): {h: Number, m: Number} | null {
        let h: Number = NaN
        let m: Number = NaN
        if (v.indexOf(":") >= 0) {
            const ps = v.split(":", 2)
            h = validHour(ps[0])
            m = validMinute(ps[1])
        } else if (v.length == 4) {
            h = validHour(v.substring(0, 2))
            m = validMinute(v.substring(2))
        }

        if (Number.isNaN(h) || Number.isNaN(m)) {
            return null
        } else {
            return { h: h, m: m }
        }
    }
}