import { RICHMENU_ACTION_TYPES, RichMenuActionType, richMenuActionTypeOf } from "../common/types";
import { ImageSizeInfo } from "../imageSelector";
import { cloneNode, cn1, show } from "../utils";
import { BaseAction } from "./BaseAction";
import { BuiltinLink } from "./BuiltinLink";
import { FlexLink } from "./FlexLink";
import { Form } from "./Form";
import { Keyword } from "./Keyword";
import { Link } from "./Link";
import { Postback } from "./Postback";
import { RichMenuObject } from "./richMenuObject";
import { SwitchRichMenu } from "./SwitchRichMenu";

/** アクション設定パネルテンプレートDOMのID */
const PANEL_ACTION_TEMPLATE_ID = 'richmenu-action-template'
const BOX_VIEWER_TEMPLATE_ID = 'richmenu-area-preview-template'

/** タップエリアタイトルのクラス名 */
const TEXT_AXIS_TITLE_CLASSNAME = 'axis-title'
/** アクションSELECTのクラス名 */
const SELECT_ACTION_SELECTOR_CLASSNAME = 'richmenu-action-selector'

/**
 * リッチメニューアクション領域
 */
export class ActionArea {
    private _actions: { [key: string]: BaseAction } = {}

    private _actionPanel: HTMLElement
    private _boundBox: HTMLElement

    private _selectedType: RichMenuActionType

    private _area: RichMenuObject

    constructor() {
        // アクション定義用DOMを作成
        const panel = this.createActionPanelElement()
        this._actions[RICHMENU_ACTION_TYPES.LINK] = new Link(panel)
        this._actions[RICHMENU_ACTION_TYPES.BUILTIN_LINK] = new BuiltinLink(panel)
        this._actions[RICHMENU_ACTION_TYPES.FLEX_LINK] = new FlexLink(panel)
        this._actions[RICHMENU_ACTION_TYPES.KEYWORD] = new Keyword(panel)
        this._actions[RICHMENU_ACTION_TYPES.SWITCH_TAB] = new SwitchRichMenu(panel)
        this._actions[RICHMENU_ACTION_TYPES.FORM] = new Form(panel)
        this._actions[RICHMENU_ACTION_TYPES.POSTBACK] = new Postback(panel)

        this._actionPanel = panel
        this._boundBox = this.createBoundBoxElement()

        // 設定パネルとバウンドボックスを関連付け
        this.combineBoundBox()
    }

    /**
     * 設定内容を画面に反映
     */
    apply(index: number, action: RichMenuObject, imageSize: ImageSizeInfo): void {
        const p = this._actionPanel
        if (action.id) {
            p.setAttribute('data-area-id', action.id)
        } else {
            p.setAttribute('data-area-id', '')
        }

        // タップエリアタイトル
        const axisTitle = cn1(p, TEXT_AXIS_TITLE_CLASSNAME)
        if (axisTitle) {
            axisTitle.innerText = `タップエリア${index}`
        }

        // アクション定義を反映
        const select = cn1(p, SELECT_ACTION_SELECTOR_CLASSNAME) as HTMLSelectElement
        if (select) {
            select.value = action.actionType
            this.onActionSelectChanged(action.actionType)
        }
        this._actions[action.actionType]?.apply(action)

        // タップ領域を表示
        this.applyBoundBox(index, action, imageSize)

        // データ保持
        this._area = action
    }

    /**
     * アクション定義データを作成
     */
    buildActionObject(): RichMenuObject {
        // 一旦共通的なデータを作成しておく
        const obj: RichMenuObject = {
            id: this._area.id,
            actionType: this._selectedType,
            top: this._area.top,
            left: this._area.left,
            width: this._area.width,
            height: this._area.height,
        }

        const act = this._actions[this._selectedType]
        if (act) {
            // 選択したアクション固有のデータを反映
            act.buildActionObject(obj)
        }

        return obj
    }

    getActionPanel(): HTMLElement {
        return this._actionPanel
    }

    getBoundBox(): HTMLElement {
        return this._boundBox
    }

    /**
     * タップ領域表示
     */
    private applyBoundBox(index: number, action: RichMenuObject, imageSize: ImageSizeInfo): void {
        const sx = imageSize.scaledWidth / imageSize.width
        const sy = imageSize.scaledHeight / imageSize.height

        const t = cn1(this._boundBox, TEXT_AXIS_TITLE_CLASSNAME)
        if (t) {
            t.innerText = `${index}`
        }

        this._boundBox.style.top = `${action.top * sy}px`
        this._boundBox.style.left = `${action.left * sx}px`
        this._boundBox.style.width = `${action.width * sx}px`
        this._boundBox.style.height = `${action.height * sy}px`

        show(this._boundBox)
    }

    /**
     * アクション種別を変更したときの処理
     */
    private onActionSelectChanged(type: RichMenuActionType): void {
        // 選択したアクションタイプによって表示を切り替える
        Object.keys(RICHMENU_ACTION_TYPES).forEach(k => this._actions[k]?.showIfTypeMatched(type))
        // 選択したアクションタイプを保持
        this._selectedType = type
    }

    /**
     * アクション定義設定パネル生成
     */
    private createActionPanelElement(): HTMLElement {
        const c = cloneNode(PANEL_ACTION_TEMPLATE_ID)
        if (!c) {
            throw 'Cannot create action panel element.'
        }

        // アクション種別リストボックス
        cn1(c, SELECT_ACTION_SELECTOR_CLASSNAME)?.addEventListener('change', evt => {
            const type = richMenuActionTypeOf((evt.target as HTMLSelectElement).value)
            this.onActionSelectChanged(type)
        })

        // 表示
        show(c)

        return c
    }

    /**
     * タップ領域表示用DOMを生成
     */
    private createBoundBoxElement(): HTMLElement {
        const c = cloneNode(BOX_VIEWER_TEMPLATE_ID)
        if (!c) {
            throw 'Cannot create bound box viewer.'
        }

        return c
    }

    private combineBoundBox(): void {
        this._actionPanel.addEventListener('mouseover', () => this._boundBox.classList.add('hovered'))
        this._actionPanel.addEventListener('mouseleave', () => this._boundBox.classList.remove('hovered'))
    }
}