import instances from '../common/instances'
import { BaseComponent } from "../common/BaseComponent"
import { cloneNode, cn1, hide, show, uuid } from '../utils'
import { FormObject } from './formObject'
import { Question } from './question'

/** アンケートエディタテンプレートのID */
const FORM_EDITOR_TEMPLATE_ID = 'form-editor-template'

/** 設問リストコンテナのクラス名 */
const QUESTIONS_CONTAINER_CLASSNAME = 'question-list'
/** 設問がからメッセージのクラス名 */
const PANEL_EMPTY_CLASSNAME = 'empty-question'
/** 設問追加ボタンのクラス名 */
const BUTTON_ADD_QUESTION_CLASSNAME = 'add-question'


/**
 * アンケートエディタークラス
 */
export class FormEditor extends BaseComponent {
    /** アンケートエディタ格納用のコンテナDOM */
    private _container: HTMLElement
    /** アンケートエディタ本体DOM */
    private _editor: HTMLElement
    /** 設問リストDOM格納用 */
    private _questionsContainer: HTMLElement
    /** 設問が無いメッセージDOM格納用 */
    private _emptyMessage: HTMLElement

    /** 設問一覧 */
    private _questions: Question[] = []

    constructor(container: HTMLElement) {
        super()
        this._container = container
    }

    /**
     * アンケートエディタの初期化
     * 
     * @param formJson アンケートJSONデータ
     */
    init(formJson: string): void {
        // エディタDOMをクローン
        const editor = cloneNode(FORM_EDITOR_TEMPLATE_ID)
        if (!editor) {
            return
        }
        // 設問リストDOM格納用コンテナ取得
        const c = cn1(editor, QUESTIONS_CONTAINER_CLASSNAME)
        if (!c) {
            return
        }
        // 設問が無いメッセージDOM取得
        const ep = cn1(editor, PANEL_EMPTY_CLASSNAME)
        if (!ep) {
            return
        }
        // 設問追加ボタンを取得(利用可状態のときはボタンがないので、取得できなくても進む)
        const btn = cn1(editor, BUTTON_ADD_QUESTION_CLASSNAME)

        this._editor = editor
        this._questionsContainer = c
        this._emptyMessage = ep

        // 設問追加ボタンをクリックしたときの処理
        if (btn) {
            btn.addEventListener('click', () => {
                // 設問追加
                this.addQuestion({
                    id: uuid(),
                    name: '',
                    type: 'TEXT',
                    values: null,
                    required: false,
                    order: -1,
                })
                // 設問表示更新
                this.updateQuestionsVisible()
                // 表示順ボタン更新
                this.updateOrderButtons()
            })
        }

        // アンケートJSONが指定されていれば、エディタに反映する
        if (formJson) {
            this.applyFlexJson(JSON.parse(formJson))
        }

        // エディタ用コンテナに追加
        this._container.appendChild(editor)
        // エディタ表示
        show(editor)
    }

    /**
     * 作成したアンケートデータをJSON文字列に変換して取得
     * 
     * @returns 生成したアンケートデータのJSON文字列
     */
    buildFormJson(): string {
        const formObjects: FormObject[] = []
        for (let n = 0; n < this._questions.length; n++) {
            const v = this._questions[n].buildFormJson()
            if (v) {
                v.order = n + 1
                formObjects.push(v)
            }
        }
        return JSON.stringify(formObjects)
    }

    /**
     * 入力データ検証
     */
    validate(): string | null {
        if (this._questions.length == 0) {
            // 設問が作成されていない
            return '設問が作成されていません。'
        }

        for (const q of this._questions) {
            const v = q.validate()
            if (v) {
                return v
            }
        }

        // 検証OK
        return null
    }

    /**
     * アンケートエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     * @returns 
     */
    static getOrCreateInstance(containerElement: HTMLElement): FormEditor {
        let obj: BaseComponent | null  = instances.get(containerElement)
        if (!obj) {
            obj = new FormEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as FormEditor
    }


    /**
     * アンケート定義をエディタに反映
     * 
     * @param messages アンケート定義
     */
    private applyFlexJson(questions: FormObject[]): void {
        // アンケートオブジェクトでループして
        questions.forEach(m => this.addQuestion(m))
        // 設問表示更新
        this.updateQuestionsVisible()
        // 表示順更新ボタンの表示更新
        this.updateOrderButtons()
    }

    /**
     * アンケート設問追加
     * 
     * @param q アンケート定義データ
     */
    private addQuestion(f: FormObject): void {
        // 設問データ作成
        const question = new Question(f)

        // 設問が上に移動するときの処理を登録
        question.setOnMoveUp(q => this.moveToUp(q))
        // 設問が下に移動するときの処理を登録
        question.setOnMoveDown(q => this.moveToDown(q))
        // 設問が削除されたときの処理を登録
        question.setOnRemoved(() => this.onQuestionRemoved(question))

        // 内部リストに追加
        this._questions.push(question)
        // DOMも追加
        this._questionsContainer.appendChild(question.getElement())
    }

    /**
     * 設問を1個上に移動
     * 
     * @param question 移動する設問
     */
    private moveToUp(question: Question): void {
        // 現在のインデックスを取得
        const index = this._questions.findIndex(t => t === question)
        // 移動先のインデックスを設定(一番上の場合はこのメソッドは実行されない想定)
        const movedIndex = index - 1

        // 移動先の設問情報を退避
        const movedQuestion = this._questions[movedIndex]
        // 移動先インデックスに対象設問を設定
        this._questions[movedIndex] = question
        // 移動元インデックスに、退避していた設問情報を設定
        this._questions[index] = movedQuestion

        // DOM要素入れ替え
        this._questionsContainer.insertBefore(question.getElement(), movedQuestion.getElement())

        // ボタン表示更新
        this.updateOrderButtons()
    }

    /**
     * 設問を1個下に移動
     * 
     * @param question 移動する設問
     */
    private moveToDown(question: Question): void {
        // 現在のインデックスを取得
        const index = this._questions.findIndex(t => t === question)
        // 移動先のインデックスを設定(一番下の場合はこのメソッドは実行されない想定)
        const movedIndex = index + 1

        // 移動先の設問情報を退避
        const movedQuestion = this._questions[movedIndex]
        // 移動先インデックスに対象設問を設定
        this._questions[movedIndex] = question
        // 移動元インデックスに、退避していた設問情報を設定
        this._questions[index] = movedQuestion

        // DOM要素入れ替え
        this._questionsContainer.removeChild(question.getElement())
        if (this._questionsContainer.children.length == (index + 1)) {
            // 下に移動しようとした要素が、もう最後に移動することになるのでappendChildで追加
            this._questionsContainer.appendChild(question.getElement())
        } else {
            // まだ下がある状態なのでinsertBefore()でいい感じに入れる
            // 追加しようとしてる位置の要素を取得
            const e = this._questionsContainer.children[index + 1]
            this._questionsContainer.insertBefore(question.getElement(), e)
        }

        // ボタン表示更新
        this.updateOrderButtons()
    }

    /**
     * 設問が削除された
     * 
     * @param balloon 削除された設問
     */
    private onQuestionRemoved(question: Question): void {
        // 内部配列から削除
        this._questions = this._questions.filter(t => t != question)
        // DOM要素からも取り除く
        this._questionsContainer.removeChild(question.getElement())

        // 設問表示更新
        this.updateQuestionsVisible()
        // ボタン表示更新
        this.updateOrderButtons()
    }

    /**
     * 設問リストの表示更新
     */
    private updateQuestionsVisible(): void {
        if (this._questions.length > 0) {
            hide(this._emptyMessage)
            show(this._questionsContainer)
        } else {
            hide(this._questionsContainer)
            show(this._emptyMessage)
        }
    }

    /**
     * 表示順更新ボタンの表示更新
     */
    private updateOrderButtons(): void {
        if (this._questions.length > 0) {
            if (this._questions.length == 1) {
                // 設問数が1個なので、上下無効
                this._questions[0].setUpDownEnabled(false, false)
                this._questions[0].setOrderNumber(1)
            } else {
                // 設問が複数ある場合は、最初の1つは上ボタンは無効でしたボタンが有効
                this._questions[0].setUpDownEnabled(false, true)
                this._questions[0].setOrderNumber(1)
                // 間のボタンは上下有効
                for (let x = 1; x < this._questions.length; x++) {
                    this._questions[x].setUpDownEnabled(true, true)
                    this._questions[x].setOrderNumber(x + 1)
                }
                // 最後の1つは上ボタンは有効、下ボタンは無効
                const x = this._questions.slice(-1)[0]
                x.setUpDownEnabled(true, false)
                x.setOrderNumber(this._questions.length)
            }
        }
    }
}