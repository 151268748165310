import * as bootstrap from 'bootstrap'
import { cloneNode, cn1, hide, show, uuid } from '../utils'
import { RouteCondition, RouteGroup } from './ConditionObject'

const ROUTE_SELECTOR_MODAL_ID = 'select-route-modal'

export interface RouteSelectOptions {
    routes: RouteGroup
    confirmed?: (route: RouteGroup) => void
    cancelled?: () => void
}

/**
 * 登録経路選択ダイアログ
 */
export class RouteSelector {
    private _element: HTMLElement
    private _body: HTMLElement
    private _modal: bootstrap.Modal

    private _options: RouteSelectOptions
    private _rules: RouteRule[] = []

    private _confirmed: boolean = false

    private _onConfirmBind
    private _onModalClosedBind

    constructor(options: RouteSelectOptions) {
        this._element = this.createDialogElement()
        this._options = options

        const body = this._element.getElementsByClassName('routes-list')
        if (!body || body.length == 0) {
            throw 'Cannot find tag condition body.'
        }
        this._body = body[0] as HTMLElement

        // 現在の選択をダイアログに反映
        if (!options.routes.conditions || options.routes.conditions.length == 0) {
            options.routes.conditions = [{
                match: 'or',
                routes: []
            }]
        }
        this.apply(options.routes)

        // bootstrapモーダル生成
        this._modal = new bootstrap.Modal(this._element)
    }

    /**
     * 登録経路選択ダイアログ表示
     */
    show(): void {
        this._modal.show()
    }

    /**
     * 設定内容を画面に反映
     */
    private apply(routes: RouteGroup): void {
        // 一旦クリア
        this.clear()

        for (const r of routes.conditions) {
            this.applyRoute(r)
        }
    }

    /**
     * 登録経路設定を画面に反映
     */
    private applyRoute(route: RouteCondition): void {
        const rule = new RouteRule(route)

        this._rules.push(rule)
        this._body.appendChild(rule.getPanel())
    }

    /**
     * 設定内容クリア
     */
    private clear(): void {
        this._rules = []
        this._body.innerHTML = ''
    }

    private buildRouteCondition(): RouteGroup {
        return {
            conditions: this._rules.map(t => t.buildConditionObject())
        }
    }

    private checkRoutesSelected(): boolean {
        let checked = true
        for (const t of this._rules) {
            if (!t.checkRoutesSelected()) {
                checked = false
            }
        }
        return checked
    }

    private onModalConfirm(): void {
        if (!this.checkRoutesSelected()) {
            return
        }
        // 経路設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    private onModalClosed(): void {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                // 経路も選択されていたので、経路情報を生成
                const t = this.buildRouteCondition()
                this._options.confirmed(t)
            }
        } else {
            // キャンセルしたのでキャンセル時のハンドラを実行
            if (this._options.cancelled) {
                this._options.cancelled()
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    private createDialogElement(): HTMLElement {
        const p = document.getElementById(ROUTE_SELECTOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find route selector modal'
        }

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(p, 'btn-route-select-confirm')?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private dispose(): void {
        cn1(this._element, 'btn-route-select-confirm')?.removeEventListener('click', this._onConfirmBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}

class RouteRule {
    private _panel: HTMLElement
    private _id: string
    private _form: HTMLFormElement

    private _onRuleRemoved: () => void = this.defaultOnRuleRemoved

    constructor(condition: RouteCondition) {
        this._id = uuid()
        this._panel = this.createElement(this._id)

        this.apply(condition)
    }

    getPanel(): HTMLElement {
        return this._panel
    }

    setOnRemoved(f: () => void) {
        this._onRuleRemoved = f
    }

    /**
     * 何らかの経路が選択されているかチェック
     */
    checkRoutesSelected(): boolean {
        const checks = this._form.querySelectorAll('div.route input[type="checkbox"]')
        let checked = false
        for (const c of checks) {
            if ((c as HTMLInputElement).checked) {
                checked = true
                break
            }
        }

        const m = cn1(this._panel, 'alert')
        if (checked) {
            if (m) { hide(m) }
        } else {
            if (m) { show(m) }
        }

        return checked
    }
    /**
     * 登録経路条件情報生成
     */
    buildConditionObject(): RouteCondition {
        // チェックされた経路IDを保持しておく配列
        const checkedRoutes: string[] = []
        // チェックボックス取得
        const checks = this._form.querySelectorAll('div.route input[type="checkbox"]')
        for (const c of checks) {
            if (c instanceof HTMLInputElement && c.checked) {
                // チェックONなのでIDを保持
                checkedRoutes.push(c.value)
            }
        }

        return {
            match: "or",
            routes: checkedRoutes
        }
    }

    private apply(c: RouteCondition): void {
        c.routes.forEach(t => {
            const e = this._form.querySelector(`input[value="${t}"]`) as HTMLInputElement
            if (e) {
                e.checked = true
            }
        })
    }

    private onRuleRemove(): void {
        this._onRuleRemoved()
    }

    private createElement(id: string): HTMLElement {
        const p = cloneNode('route-rule-template')
        if (!p) {
            throw 'Cannot create route rule container'
        }

        const form = p.getElementsByTagName('form')[0]
        form.id = id

        const routeChecks = form.getElementsByClassName('route')
        for (const r of routeChecks) {
            const check = r.getElementsByTagName('input')[0]
            const label = r.getElementsByTagName('label')[0]
            check.id = `route-${id}-${check.value}`
            check.name = `route-${id}`
            label.htmlFor = check.id
        }

        this._form = form

        show(p)

        return p
    }

    private defaultOnRuleRemoved(): void {
    }
}