import { BaseTagAction } from "./baseTagAction";
import { POSTBACK_ACTION_TYPES } from "./postbackObjects";

/** 削除タグ選択パネルのクラス名 */
const INPUT_REMOVE_TAGS_CLASSNAME = 'postback-action-remove-tag'

/**
 * ポストバックアクション - タグ削除クラス
 */
export class RemoveTag extends BaseTagAction {
    constructor(parent: HTMLElement) {
        super(parent, INPUT_REMOVE_TAGS_CLASSNAME, POSTBACK_ACTION_TYPES.REMOVE_TAG)
    }
}