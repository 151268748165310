import { BaseComponent } from "../common/BaseComponent";
import { cloneNode, cn1, show } from "../utils";
import { Block } from "./block";
import { BlockItem, BubbleItem } from "./messageObject";

/** バブルDOM要素テンプレートのID */
const BUBBLE_TEMPLATE_ID = 'message-bubble-template'
/** ブロック格納用コンテナのクラス名 */
const BLOCKS_CONTAIENR_CLASSNAME = 'blocks-container'
/** ブロック追加ボタンのクラス名 */
const BUTTON_ADD_BLOCK_CLASSNAME = 'add-block'
/** バブル削除ボタンのクラス名 */
const BUTTON_REMOVE_BUBBLE_CLASSNAME = 'remove-bubble'

/**
 * 横並び吹き出し内の1つの項目(バブルコンテナ)を定義
 */
export class Bubble extends BaseComponent {
    private _blocks: Block[] = []
    private _blocksContainer: HTMLElement

    private _onRemoved: (b: Bubble) => void = this.defaultOnRemoved

    constructor(readOnly: boolean) {
        super()

        // バブルコンテナ表示用DOM要素を作成
        const e = this.createBubbleElement()
        if (readOnly) {
            // 読み取り専用モードの時は操作用ボタンを削除しておく
            for (const b of e.getElementsByTagName('footer')) {
                e.removeChild(b)
            }
        }

        // メッセージブロック格納用のコンテナDOMを取得
        const blocksContainer = cn1(e, BLOCKS_CONTAIENR_CLASSNAME)
        if (!blocksContainer) {
            return
        }

        // 各要素保持
        this._blocksContainer = blocksContainer
        this.setElement(e)

        // 表示
        show(e)
    }

    /**
     * メッセージブロック追加
     * 
     * @param block 追加するブロック定義
     */
    addBlock(block: BlockItem): void {
        // ブロックオブジェクト生成
        const obj = new Block()

        if (block.type === 'text') {
            obj.applyText(block)
        } else if (block.type === 'image') {
            obj.applyImage(block)
        } else if (block.type === 'button') {
            obj.applyButton(block)
        }

        // コンテナに追加
        this._blocks.push(obj)
        this._blocksContainer.appendChild(obj.getElement())

        // ブロック削除時の処理を登録
        obj.setOnRemovedHandler(() => this.onBlockRemoved(obj))

        // 各ブロックのボタン表示を更新
        this.updateBlockButtons()

        // 「～追加」ボタンの表示更新
        obj.updateAddButtons()
    }

    /**
     * バブルが削除されたときの処理を登録
     */
    setOnBubbleRemoved(f: (b: Bubble) => void): void {
        this._onRemoved = f
    }

    /**
     * JSON用オブジェクトを取得
     */
    buildFlexObject(): BubbleItem {
        return {
            blocks: this._blocks.map(b => b.buildFlexObject())
        }
    }

    /**
     * バブル削除ボタンの有効/無効を設定する
     */
    setRemoveEnabled(b: boolean): void {
        const removeBtn = cn1(this.getElement(), BUTTON_REMOVE_BUBBLE_CLASSNAME)
        if (removeBtn) {
            if (b) {
                removeBtn.classList.remove('d-none')
            } else {
                removeBtn.classList.add('d-none')
            }
        }
    }

    /**
     * バブル用DOM要素を作成
     */
    private createBubbleElement(): HTMLElement {
        const t = cloneNode(BUBBLE_TEMPLATE_ID)
        if (!t) {
            // テンプレートからクローン出来ない場合はエラー
            throw 'Cannot create bubble element.'
        }

        // ブロック追加ボタンを押したときの処理
        const addBtn = cn1(t, BUTTON_ADD_BLOCK_CLASSNAME)
        if (addBtn) {
            addBtn.addEventListener('click', () => this.addBlock({type: ''}))
        }

        // バブル削除ボタンが押されたときの処理
        const removeBtn = cn1(t, BUTTON_REMOVE_BUBBLE_CLASSNAME)
        if (removeBtn) {
            removeBtn.addEventListener('click', () => {
                if (confirm('バブルを削除しますか？')) {
                    this._onRemoved(this)
                }
            })
        }

        return t
    }

    /**
     * ブロックが削除されたときの処理
     * 
     * @param obj 削除されたブロック
     */
    private onBlockRemoved(obj: Block) {
        // ブロックリストから削除されたブロックも削除
        this._blocks = this._blocks.filter(t => t != obj)
        // DOM要素からも削除
        this._blocksContainer.removeChild(obj.getElement())

        // 各ブロックのボタン表示を更新
        this.updateBlockButtons()
    }

    /**
     * 各ブロックのボタン表示を更新
     */
    private updateBlockButtons(): void {
        if (this._blocks.length == 1) {
            // ブロックが1個しかないので、削除ボタンは非表示にする
            this._blocks[0].hideRemoveButton()
        } else if (this._blocks.length >= 2) {
            // ブロックが2個以上なので、それぞれに削除ボタンを表示
            this._blocks.forEach(b => b.showRemoveButton())
        }
    }


    private defaultOnRemoved(b: Bubble) {
    }
}