/**
 * ボタンを「読み込み中」表示に設定
 */
export function loadingButton(button: HTMLButtonElement): void {
    const prev = button.innerHTML
    let loadingText = button.getAttribute('data-loading')
    if (!loadingText) {
        loadingText = '<i class="fa-solid fa-fw fa-spin fa-spinner"></i>Loading...'
    }
    button.innerHTML = loadingText
    button.disabled = true
    setTimeout(() => {
        button.innerHTML = prev
        button.disabled = false
    }, 5000)
}